import * as React from "react";
import { memo, useState } from "react";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { formatTimeSecond } from "utils/date";
import { ReactComponent as SendedIcon } from "assets/svg/icon/sended.svg";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import defaultAvatar from "assets/images/default/default_avatar.png";
import styles from "assets/styles/pages/Chat/component/MessageImage";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

function MessageImage({ value, isOwner, state, handleOpenPopper, idMessage }) {
  const [isOpenPreview, setOpenPreview] = useState(false);
  const [position, setPosition] = useState(0);

  const handleClosePreview = () => setOpenPreview(false);
  const handleOpenPreview = (id) => {
    setPosition(id);
    setOpenPreview(true);
  };

  return (
    <Box className={`id-message-${idMessage}`} sx={styles.container}>
      <Lightbox
        plugins={[Thumbnails]}
        open={isOpenPreview}
        close={handleClosePreview}
        carousel={{ finite: true, preload: value?.image?.length }}
        animation={{ navigation: 300 }}
        index={position}
        slides={value?.image?.map((v) => ({ src: v.url }))}
        thumbnails={{ width: 120, height: 80, padding: 0 }}
      />
      <Box sx={() => styles.section({ isOwner })}>
        {!isOwner && <Avatar src={defaultAvatar} srcSet={value?.user?.avatar} sx={styles.avatar} />}
        <Box
          onContextMenu={(e) => handleOpenPopper(e, isOwner)}
          sx={styles.messageSection}
          data-id={idMessage}
        >
          <Box sx={(e) => styles.imageContainer(e, { isOwner })}>
            {value?.group?.type === "group" && !isOwner && (
              <Box sx={styles.nameContainer}>
                <Typography color="initial" variant="body1" sx={styles.username}>
                  {value?.user?.username} -
                </Typography>
                <Typography color="initial" variant="body1" sx={styles.userID}>
                  {value?.user?.id}
                </Typography>
              </Box>
            )}
            <Box
              sx={(e) =>
                styles.imageSection(e, { isOwner, isDisplayUsername: value?.user?.username })
              }
            >
              <Grid container spacing={0.5}>
                {value?.image
                  ?.sort((a, b) => a.index - b.index)
                  .slice(0, 5)
                  .map((v, index) => (
                    <Grid
                      key={v.index}
                      item
                      style={{ position: "relative" }}
                      xs={
                        value?.image?.length === 1 ||
                        (index % 2 === 0 && index === value.image.length - 1) ||
                        (value?.image?.length > 5 && index === 4)
                          ? 12
                          : 6
                      }
                      onClick={() =>
                        handleOpenPreview(value?.image?.length > 5 && index === 4 ? 0 : v.index)
                      }
                    >
                      <Box className="imageUrl" component="img" src={v.url} sx={styles.image} />
                      <Box
                        sx={(e) =>
                          styles.overlay(e, { isDisplay: value?.image?.length > 5 && index === 4 })
                        }
                      >
                        <Typography variant="subtitle2" color="white.main">
                          +{value.image.length - 5}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </Box>

            <Box sx={styles.imageTimeContainer}>
              <Typography
                sx={(e) => styles.imageTime(e, { isMore: value?.image?.length > 5 })}
                variant="body2"
              >
                {formatTimeSecond(value?.createdAt)}
              </Typography>
              {isOwner && state && <SendedIcon style={styles.sendedIcon} />}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default memo(MessageImage);
