import { Avatar, Box, Grow, Typography } from "@mui/material";
import { memo } from "react";
import { ReactComponent as MuteIcon } from "assets/svg/icon/mute.svg";
import defaultAvatar from "assets/images/default/default_avatar.png";
import styles from "assets/styles/pages/Chat/component/UserItem";
import PropTypes from "prop-types";
import { emptyFunction } from "utils/utils";
import { isEmpty } from "utils/validate";

function UserItem({ information, mute, onSelect, isSelected }) {
  return (
    <Box onClick={onSelect} sx={(e) => styles.container(e, { isSelected })}>
      <Box sx={styles.informationContainer}>
        <Box sx={styles.avatarContainer}>
          <Avatar
            srcSet={information?.detail?.image || information?.group?.image}
            sx={styles.avatar}
            src={defaultAvatar}
          />
        </Box>
        <Box sx={styles.information}>
          <Box sx={styles.nameContainer}>
            <Typography variant="body1" color="initial" sx={styles.name}>
              {information?.detail?.name || information?.group?.name}
            </Typography>
            <Grow in={!isEmpty(mute) && Number(mute) === 1} unmountOnExit>
              <Box style={styles.muteIcon}>
                <MuteIcon />
              </Box>
            </Grow>
          </Box>
          <Typography
            variant="body2"
            sx={(e) => styles.message(e, { isUnread: information?.last_message?.isUnread })}
          >
            {information?.last_message?.text ? information.last_message.text : "..."}
          </Typography>
        </Box>
      </Box>
      <Typography variant="body2" sx={styles.time}>
        {information?.time}
      </Typography>
    </Box>
  );
}

UserItem.defaultProps = {
  information: {
    avatar: "",
    name: "",
    message: "",
    time: "",
  },
  mute: null,
  onSelect: emptyFunction,
  isSelected: false,
};

UserItem.propTypes = {
  information: PropTypes.shape({
    avatar: PropTypes.any,
    name: PropTypes.string,
    message: PropTypes.string,
    time: PropTypes.string,
  }),
  mute: PropTypes.string,
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool,
};

export default memo(UserItem);
