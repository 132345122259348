import axiosInstance from "middleware/axios";

const searchVGA = (params) => axiosInstance.get(`/api/account/search_by_vga`, { params });

const searchGolfer = (params, data) =>
  axiosInstance.post(`https://api-cms-prod.vcallid.com/other_service/search_golfer`, data, {
    params,
  });

const websitePreview = (data) =>
  axiosInstance
    .post("https://api-cms-prod.vcallid.com/other_service/web_thumbnail", data)
    .then((res) => {
      if (res.error_code === 0) return res.data;
      return undefined;
    });

export { searchVGA, websitePreview, searchGolfer };
