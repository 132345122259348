import * as React from "react";
import { memo } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { formatTimeSecond } from "utils/date";
import { ReactComponent as SendedIcon } from "assets/svg/icon/sended.svg";
import defaultAvatar from "assets/images/default/default_avatar.png";
import styles from "assets/styles/pages/Chat/component/MessageVideo";

function MessageVideo({ value, isOwner, state, handleOpenPopper, idMessage }) {
  return (
    <Box className={`id-message-${idMessage}`} sx={styles.container}>
      <Box sx={() => styles.section({ isOwner })}>
        {!isOwner && <Avatar src={defaultAvatar} srcSet={value?.user?.avatar} sx={styles.avatar} />}
        <Box
          onContextMenu={(e) => handleOpenPopper(e, isOwner)}
          sx={styles.messageSection}
          data-id={idMessage}
        >
          <Box sx={(e) => styles.videoContainer(e, { isOwner })}>
            {value?.group?.type === "group" && !isOwner && (
              <Box sx={styles.nameContainer}>
                <Typography color="initial" variant="body1" sx={styles.username}>
                  {value?.user?.username} -
                </Typography>
                <Typography color="initial" variant="body1" sx={styles.userID}>
                  {value?.user?.id}
                </Typography>
              </Box>
            )}
            <Box
              sx={(e) =>
                styles.videoSection(e, { isOwner, isDisplayUsername: value?.user?.username })
              }
            >
              <Box component="video" sx={styles.video} controls>
                <Box className="videoUrl" component="source" src={value.video.url} />
              </Box>
            </Box>

            <Box sx={styles.videoTimeContainer}>
              <Typography sx={styles.videoTime} variant="body2">
                {formatTimeSecond(value?.createdAt)}
              </Typography>
              {isOwner && state && <SendedIcon style={styles.sendedIcon} />}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default memo(MessageVideo);
