import axiosInstance from "middleware/axios";

const getListGroupChat = (params) => axiosInstance.get("/api/group/list", { params });
const groupChatDetail = (id) => axiosInstance.get(`/api/group/detail/${id}`);
const messageOffline = () => axiosInstance.get(`/api/group_messenger/list_offline`);

const AddMember = ({ user_ids, permission, group_id }) =>
  axiosInstance.post(`/api/group/add_user`, { user_ids, permission, group_id });
const ChangePermission = ({ user_id, permission, group_id }) =>
  axiosInstance.post(`/api/group/change_permission`, { user_id, permission, group_id });
const RemoveMember = ({ user_ids, group_id }) =>
  axiosInstance.post(`/api/group/remove_user`, { user_ids, group_id });

const uploadResource = (data) => axiosInstance.post(`/api/public/post`, data);

const addGroupChat = (data) => axiosInstance.post(`/api/group/create`, data);

const removeMessageChat = (id) => axiosInstance.get(`/api/group_messenger/delete/${id}`);

export {
  getListGroupChat,
  groupChatDetail,
  messageOffline,
  AddMember,
  uploadResource,
  ChangePermission,
  RemoveMember,
  addGroupChat,
  removeMessageChat,
};
