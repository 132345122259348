import { memo } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { formatTimeSecond } from "utils/date";
import defaultAvatar from "assets/images/default/default_avatar.png";
import { ReactComponent as SendedIcon } from "assets/svg/icon/sended.svg";
// import { ReactComponent as DownloadIcon } from "assets/svg/icon/download_message.svg";
import { ReactComponent as XLSXIcon } from "assets/svg/icon/xlsx_message.svg";
import { ReactComponent as PDFIcon } from "assets/svg/icon/pdf_message.svg";
import { ReactComponent as DOCIcon } from "assets/svg/icon/doc_message.svg";
import { ReactComponent as PPTIcon } from "assets/svg/icon/ppt_message.svg";
import { ReactComponent as ZIPIcon } from "assets/svg/icon/zip_message.svg";
import { ReactComponent as TXTIcon } from "assets/svg/icon/txt_message.svg";
import { ReactComponent as FILEIcon } from "assets/svg/icon/file_message.svg";
import styles from "assets/styles/pages/Chat/component/MessageFile";
import { numberWithCommas } from "utils/commas";
import { saveAs } from "file-saver";
import { FILE_TYPE } from "constant/chat";

function MessageFile({ avatar, isOwner, isSended, sendedTime, handleOpenPopper, value }) {
  const handleDownloadFile = () => {
    if (value?.file?.url) saveAs(value.file.url, value.file.name);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={(e) => styles.section(e, { isOwner })}>
        {!isOwner && <Avatar src={defaultAvatar} srcSet={avatar} sx={styles.avatar} />}
        <Box onContextMenu={(e) => handleOpenPopper(e, isOwner)} sx={styles.messageSection}>
          <Box sx={(e) => styles.fileContainer(e, { isOwner })}>
            <Box sx={styles.downloadContainer} onClick={handleDownloadFile}>
              {/* <Box sx={styles.downloadIcon}>
                <DownloadIcon />
              </Box> */}
              <Box sx={styles.downloadIcon}>
                {FILE_TYPE.XLSX.includes(value.file.typeFile) && <XLSXIcon style={styles.icon} />}
                {FILE_TYPE.PDF.includes(value.file.typeFile) && <PDFIcon style={styles.icon} />}
                {FILE_TYPE.DOC.includes(value.file.typeFile) && <DOCIcon style={styles.icon} />}
                {FILE_TYPE.PPT.includes(value.file.typeFile) && <PPTIcon style={styles.icon} />}
                {FILE_TYPE.TXT.includes(value.file.typeFile) && <TXTIcon style={styles.icon} />}
                {(FILE_TYPE.ZIP.includes(value.file.typeFile) ||
                  value.file.name.includes(".rar")) && <ZIPIcon style={styles.icon} />}
                {!FILE_TYPE.XLSX.includes(value.file.typeFile) &&
                  !FILE_TYPE.PDF.includes(value.file.typeFile) &&
                  !FILE_TYPE.DOC.includes(value.file.typeFile) &&
                  !FILE_TYPE.PPT.includes(value.file.typeFile) &&
                  !FILE_TYPE.TXT.includes(value.file.typeFile) &&
                  !(
                    FILE_TYPE.ZIP.includes(value.file.typeFile) || value.file.name.includes(".rar")
                  ) && <FILEIcon style={styles.icon} />}
              </Box>
              <Box sx={styles.fileInformationContainer}>
                <Box sx={styles.nameContainer}>
                  <Typography sx={(e) => styles.nameLeft(e, { isOwner })} variant="caption">
                    {value.file.name.slice(0, Math.round(value.file.name.length * 0.75))}
                  </Typography>
                  <Typography sx={(e) => styles.nameRight(e, { isOwner })} variant="caption">
                    {value.file.name.slice(Math.round(value.file.name.length * 0.75))}
                  </Typography>
                </Box>
                <Typography
                  color="initial"
                  variant="body1"
                  sx={(e) => styles.fileTextSize(e, { isOwner })}
                >
                  {numberWithCommas(value.file.size || 0)} KB
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.timeContainer}>
              <Typography sx={(e) => styles.time(e, { isOwner })} variant="body2">
                {formatTimeSecond(sendedTime)}
              </Typography>
              {isOwner && isSended && <SendedIcon style={styles.sendedIcon} />}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default memo(MessageFile);
