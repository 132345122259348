export default {
  container: ({ palette, borders }, { isSelected }) => ({
    backgroundColor: isSelected
      ? `${palette.background.message} !important`
      : palette.transparent.main,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mb: 2,
    p: 1,
    borderRadius: borders.borderRadius.md,
    cursor: "pointer",
    "&:hover": {
      // "& .overlay": {
      //   display: "flex",
      // },
      backgroundColor: "#F2F2F2",
    },
    overflowY: "hidden",
  }),
  informationContainer: {
    display: "flex",
    alignItems: "center",
    overflowY: "hidden",
  },
  avatarContainer: {
    position: "relative",
    width: "60px",
    height: "60px",
  },
  avatar: {
    width: "60px",
    height: "60px",
  },
  overlay: ({ borders, palette, functions }) => ({
    position: "absolute",
    backgroundColor: functions.rgba(palette.black.main, 0.1),
    display: "none",
    borderRadius: borders.borderRadius.xxxl,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  }),
  information: {
    pl: 1.5,
    overflowY: "hidden",
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  name: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    pr: 2,
    overflow: "hidden",
    lineHeight: "18px",
    height: "18px",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
  }),
  muteIcon: {
    width: "19px",
    height: "18px",
  },
  message: ({ palette, typography }, { isUnread }) => ({
    color: isUnread ? palette.grey[900] : palette.grey[650],
    mt: 1,
    lineHeight: "16px",
    overflow: "hidden",
    height: "16px",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
    fontWeight: isUnread ? typography.fontWeightBold : typography.fontWeightRegular,
  }),
  time: ({ palette }) => ({
    color: palette.grey[650],
    ml: 1.5,
    alignSelf: "flex-start",
    flexShrink: 0,
  }),
};
