export default {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    px: 3,
    py: 1,
    borderTop: "0.1px solid #D5D5D5",
    borderBottom: "0.1px solid #D5D5D5",
  },
  content: { flexGrow: 1 },
  replyName: ({ typography }) => ({
    fontFamily: typography.fontFamily3,
    fontSize: typography.size.xs,
    color: "#090710",
    fontWeight: typography.fontWeightBold,
    background: "-webkit-linear-gradient(#26BBFE, #1BC88B)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  }),
  contentImageOrFile: {
    flexGrow: 1,
    display: "flex",
    alignItems: "flex-end",
  },
  image: ({ borders }) => ({
    flexShrink: 0,
    border: "1px solid #D5D5D5",
    width: "40px",
    objectFit: "cover",
    height: "40px",
    borderRadius: borders.borderRadius.md,
    mr: 1.5,
  }),
  fileIcon: { flexShrink: 0, mr: 1.5 },
  fillColorFileIcon: {
    background: "linear-gradient(124.77deg, #26BBFE 13.59%, #1BC88B 91.04%);",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },

  boxContentImage: { flexGrow: 1 },
  text: ({ typography }) => ({
    fontFamily: typography.fontFamily3,
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.5)",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    width: "90%",
  }),
  textImage: ({ typography }) => ({
    fontStyle: "italic",
    fontFamily: typography.fontFamily3,
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.5)",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    width: "90%",
  }),
  IconClose: { flexShrink: 0 },
};
