import { Avatar, Box, Typography } from "@mui/material";
import { memo } from "react";
import defaultAvatar from "assets/images/default/default_avatar.png";
import styles from "assets/styles/pages/Chat/component/MessageNotification";

function MessageNotification({ content, avatar }) {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.section}>
        {avatar && <Avatar src={defaultAvatar} srcSet={avatar} sx={styles.avatar} />}
        <Box sx={() => styles.contentContainer({ avatar })}>
          <Typography variant="body2" color="white.main" sx={styles.message}>
            {content}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default memo(MessageNotification);
