import {
  informationWidth,
  lgInformationWidth,
  xlInformationWidth,
  xxlInformationWidth,
} from "constant/chat";

export default {
  drawer: ({ transitions, breakpoints }, { isOpenListMemberDialog }) => ({
    ...(!isOpenListMemberDialog && {
      width: 0,
      "& .MuiDrawer-paper": {
        width: 0,
      },
      transition: transitions.create("width", {
        easing: transitions.easing.easeOut,
        duration: transitions.duration.enteringScreen,
      }),
    }),

    ...(isOpenListMemberDialog && {
      width: informationWidth,
      "& .MuiDrawer-paper": {
        width: informationWidth,
      },
      transition: transitions.create(["margin", "width"], {
        easing: transitions.easing.easeOut,
        duration: transitions.duration.enteringScreen,
      }),
      [breakpoints.down("xxl")]: {
        width: xxlInformationWidth,
        "& .MuiDrawer-paper": {
          width: xxlInformationWidth,
        },
      },
      [breakpoints.down("xl")]: {
        width: xlInformationWidth,
        "& .MuiDrawer-paper": {
          width: xlInformationWidth,
        },
      },
      [breakpoints.down("lg")]: {
        width: lgInformationWidth,
        "& .MuiDrawer-paper": {
          width: lgInformationWidth,
        },
      },
      [breakpoints.down("md")]: {
        width: "100%",
        "& .MuiDrawer-paper": {
          width: "100%",
        },
      },
    }),
  }),
  dialog: ({ breakpoints, borders }) => ({
    my: 2,
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: borders.borderRadius.lg,
        width: "100%",
        height: "100%",
        // maxHeight: "600px",
        maxWidth: "450px",
        [breakpoints.down("lg")]: {
          maxWidth: "90vw",
        },
      },
    },
  }),
  buttonGroup: ({ borders }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: borders.borderRadius.round,
    mb: 3,
  }),
  buttonGroupChild: (active, id) => ({
    background: active === id ? "linear-gradient(90deg, #26BBFE 0%, #1BC88B 100%);" : "none",
    borderRadius: "100px",
    color: active === id ? "#fff" : "linear-gradient(90deg, #26BBFE 0%, #1BC88B 100%)",
    width: "120px",
    height: "32px",
    fontSize: "14px",
    p: "2px 12px !important",
  }),
  dFlexNotJust: {
    display: "flex",
    alignItems: "center",
  },
  boxUser: {
    // justifyContent: "space-between",
    "&:hover": {
      background: "#F3F3F3",
      borderRadius: "10px",
    },
  },
  boxUserSelect: {
    // justifyContent: "space-between",
    background: "#F3F3F3",
    borderRadius: "10px",
  },
  fLexCol: { display: "flex", flexDirection: "column" },
  arrowIcon: { fill: "#090710", height: "14px" },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    m: 2,
  },
  title: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
  }),
  subTitle: ({ typography }) => ({
    color: "#B4B4B4",
    py: 1,
    ml: 2,
    fontWeight: typography.fontWeightBold,
  }),
  overflowY: ({ breakpoints }) => ({
    overflowY: "auto",
    pl: 0.5,
    ml: 2,
    [breakpoints.between("md", "lg")]: {
      ml: 0,
    },
    [breakpoints.down("sm")]: {
      ml: 0,
    },
  }),
  avatarImage: {
    width: "44px",
    height: "44px",
    borderRadius: "50%",
    objectFit: "cover",
    "&>img": {
      height: "44px",
    },
  },
  labelLeft: ({ breakpoints }) => ({
    flexGrow: 1,
    fontWeight: "600",
    pl: 1.25,
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flex: "1",
    [breakpoints.between("md", "lg")]: {
      fontSize: "12px",
      pl: 1,
    },
    [breakpoints.down("md")]: {
      fontSize: "12px",
      pl: 1,
    },
  }),
  labelRight: ({ breakpoints }) => ({
    flexGrow: "0",
    flexShrink: "0",
    fontWeight: "600",
    [breakpoints.between("md", "lg")]: {
      fontSize: "12px",
    },
    [breakpoints.down("md")]: {
      fontSize: "12px",
    },
  }),
  labelDefault: ({ breakpoints }) => ({
    flexGrow: 1,
    fontWeight: "600",
    pl: 1.25,
    // pr: 0.5,
    [breakpoints.between("md", "lg")]: {
      fontSize: "12px",
    },
  }),
  labelActive: ({ breakpoints }) => ({
    flexGrow: 1,
    px: 0.5,
    background: "-webkit-linear-gradient(#26BBFE 3.27%, #1BC88B 97.45%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: "550",
    [breakpoints.between("md", "lg")]: {
      fontSize: "12px",
    },
  }),
  divider: {
    my: 0,
    backgroundColor: "#707070",
  },
  contentContainer: {
    height: "500px",
    maxHeight: "80%",
    minHeight: "20%",
    flexGrow: 1,
    display: "flex",
    borderRadius: 0,
    // mb: 2,
    // ml: 2,
    mt: 1,
    flexDirection: "column",
  },
  tag: ({ palette, borders }) => ({
    flexGrow: "0",
    flexShrink: "0",
    borderRadius: borders.borderRadius.round,
    background: palette.grey[150],
    padding: "4px 10px",
  }),
  tagLabel: ({ palette }) => ({
    fontSize: "12px",
    color: palette.grey[600],
    //  minWidth: "90px"
  }),
};
