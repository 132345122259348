import { useNavigate } from "react-router-dom";
import QR from "./section/QR";

export default function LoginQR() {
  const navigate = useNavigate();

  const handleNavigateLogin = () => {
    navigate("/login");
  };

  return <QR handleNavigateLogin={handleNavigateLogin} />;
}
