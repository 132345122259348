import { Box, Typography, Fade, IconButton } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/svg/icon/close_#00000.svg";
import { ReactComponent as AttachFileIcon } from "assets/svg/icon/attach_icon_#ffff.svg";
import styles from "assets/styles/pages/Chat/component/ReplyMessage";
import { isEmpty } from "utils/validate";
import { memo } from "react";

function ReplyMessage({ openReplyMessage, handleCloseReplyMessage, reply }) {
  const viewNormal = (
    <Box sx={styles.content}>
      <Typography sx={styles.replyName}>
        {!isEmpty(reply.replyName) ? reply.replyName : ""}
      </Typography>
      <Typography sx={styles.text}>{!isEmpty(reply.message) ? reply.message : ""}</Typography>
    </Box>
  );
  const viewImageOrFile = (
    <Box sx={styles.contentImageOrFile}>
      {!isEmpty(reply.imageUrl) ? (
        <Box component="img" src={reply.imageUrl} sx={styles.image} />
      ) : (
        <Box sx={styles.fileIcon}>
          <IconButton disabled sx={styles.fillColorFileIcon}>
            <AttachFileIcon />
          </IconButton>
        </Box>
      )}
      <Box sx={styles.boxContentImage}>
        <Typography sx={styles.replyName}>
          {!isEmpty(reply.replyName) ? reply.replyName : ""}
        </Typography>
        <Typography sx={styles.textImage}>
          {!isEmpty(reply.imageUrl) ? "Photo Message" : reply.fileName}
        </Typography>
      </Box>
    </Box>
  );
  if (!openReplyMessage) return null;
  return (
    <Fade in={openReplyMessage}>
      <Box sx={styles.container}>
        {!isEmpty(reply.imageUrl) || !isEmpty(reply.fileName) ? viewImageOrFile : viewNormal}
        <Box sx={styles.IconClose}>
          <IconButton onClick={handleCloseReplyMessage}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </Fade>
  );
}
export default memo(ReplyMessage);
