import { memo } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { ReactComponent as UnreadIcon } from "assets/svg/icon/unread.svg";
import { ReactComponent as PinIcon } from "assets/svg/icon/pin.svg";
import { ReactComponent as MuteIcon } from "assets/svg/icon/chat_mute.svg";
import { ReactComponent as TrashIcon } from "assets/svg/icon/chat_trash.svg";
import { Button, Divider, Typography } from "@mui/material";
import styles from "assets/styles/pages/Chat/component/ChatActionSheet";
import { useTranslation } from "react-i18next";

function ChatActionSheet({ openSetting, anchor, handleClose, handleRemoveGroupChat }) {
  const { t } = useTranslation();
  return (
    <Popper
      placement="bottom-end"
      sx={styles.popper}
      open={openSetting}
      anchorEl={anchor}
      transition
      disablePortal
      popperOptions={{ modifiers: [{ name: "offset", options: { offset: [0, 42] } }] }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper sx={styles.paper}>
            <ClickAwayListener disableReactTree onClickAway={handleClose}>
              <Box>
                {/* Unread */}
                {/* ---DISABLE--- */}
                <Button onClick={handleClose} sx={styles.topItemContainer} disabled>
                  <Typography variant="body1" sx={styles.item}>
                    {t("chat.header.chat_action_sheet.mark_as_unread")}
                  </Typography>
                  <UnreadIcon />
                </Button>
                <Divider sx={styles.divider} />
                {/* Pin */}
                {/* ---DISABLE--- */}
                <Button onClick={handleClose} sx={styles.itemContainer} disabled>
                  <Typography variant="body1" sx={styles.item}>
                    {t("chat.header.chat_action_sheet.pin")}
                  </Typography>
                  <PinIcon />
                </Button>
                <Divider sx={styles.divider} />
                {/* Mute */}
                {/* ---DISABLE--- */}
                <Button onClick={handleClose} sx={styles.itemContainer} disabled>
                  <Typography variant="body1" sx={styles.item}>
                    {t("chat.header.chat_action_sheet.mute")}
                  </Typography>
                  <MuteIcon />
                </Button>
                <Divider sx={styles.divider} />
                {/* Delete */}
                {/* ---DISABLE--- */}
                <Button onClick={handleRemoveGroupChat} sx={styles.bottomItemContainer}>
                  <Typography variant="body1" sx={styles.itemErrorColor}>
                    {t("chat.header.chat_action_sheet.delete")}
                  </Typography>
                  <TrashIcon />
                </Button>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

ChatActionSheet.defaultProps = {
  openSetting: false,
  anchor: "",
};

ChatActionSheet.propTypes = {
  openSetting: PropTypes.bool,
  anchor: PropTypes.any,
  handleClose: PropTypes.func.isRequired,
};

export default memo(ChatActionSheet);
