export default {
  container: {
    mt: 2.5,
    p: 1,
  },
  section: ({ isOwner }) => ({
    display: "flex",
    alignSelf: isOwner ? "flex-end" : "flex-start",
    flexDirection: isOwner ? "row-reverse" : "row",
  }),
  avatar: {
    mr: 2,
    width: "50px",
    height: "50px",
  },
  messageSection: ({ breakpoints }) => ({
    maxWidth: "30%",
    [breakpoints.down("xl")]: {
      maxWidth: "50%",
    },
  }),
  // local
  videoContainer: ({ borders, palette }, { isOwner }) => ({
    cursor: "pointer",
    boxShadow: "0px 0px 7.75px rgba(0, 0, 0, 0.25)",
    borderTopRightRadius: !isOwner ? borders.borderRadius.xl : 0,
    borderTopLeftRadius: isOwner ? borders.borderRadius.xl : 0,
    borderBottomRightRadius: borders.borderRadius.xl,
    borderBottomLeftRadius: borders.borderRadius.xl,
    background: palette.white.main,
    p: 0.2,
    position: "relative",
    overflow: "hidden",
  }),
  nameContainer: {
    px: 2,
    my: 0.5,
  },
  username: ({ typography, palette }) => ({
    fontWeight: typography.fontWeightBold,
    color: palette.inherit.main,
    display: "inline",
  }),
  userID: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    background: "-webkit-linear-gradient(#26BBFE, #1BC88B)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    pl: 0.7,
    display: "inline",
  }),
  videoSection: ({ borders, palette }, { isOwner, isDisplayUsername }) => ({
    minWidth: "120px",
    minHeight: "60px",
    overflow: "hidden",
    borderTopRightRadius: !isOwner && !isDisplayUsername ? borders.borderRadius.xl : 0,
    borderTopLeftRadius: isOwner ? borders.borderRadius.xl : 0,
    borderBottomRightRadius: borders.borderRadius.xl,
    borderBottomLeftRadius: borders.borderRadius.xl,
    backgroundColor: palette.white.main,
    lineHeight: 0,
  }),
  video: () => ({
    width: "100%",
    height: "100%",
    objectFit: "cover",
    maxHeight: "45vh",
  }),
  videoTimeContainer: {
    position: "absolute",
    bottom: "8px",
    right: "16px",
    display: "flex",
  },
  videoTime: ({ palette }) => ({
    color: palette.white.main,
    textShadow: "0px 0px 3.9px rgba(0, 0, 0, 0.75)",
    zIndex: 2,
  }),
  sendedIcon: {
    width: "22px",
    marginLeft: "8px",
  },
};
