import { Box, Typography, Fade, IconButton } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/svg/icon/close_#00000.svg";
import styles from "assets/styles/pages/Chat/component/EditMessage";
import { useTranslation } from "react-i18next";

export default function EditMessage({ openEditMessage, handleCloseEditMessage }) {
  const { t } = useTranslation();
  if (!openEditMessage) return null;
  return (
    <Fade in={openEditMessage}>
      <Box sx={styles.container}>
        <Typography sx={styles.title}>{t("chat.content_chat.message.edit")}</Typography>
        <Box sx={styles.IconClose}>
          <IconButton onClick={handleCloseEditMessage}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </Fade>
  );
}
