import { CHAT_DATABASE, GROUP_TABLE, MESSAGE_TABLE } from "config/keys";
import { openDB } from "idb";

class IDB {
  constructor(database, table) {
    this.database = database;
    this.table = table;
    this.initialized = false;
  }

  async init() {
    this.idb = await openDB(this.database);
    this.initialized = true;
  }

  async getAll(query, index, count) {
    if (!this.initialized) await this.init();
    let db;
    if (!index) db = this.idb.transaction(this.table, "readwrite").objectStore(this.table);
    else db = this.idb.transaction(this.table, "readwrite").objectStore(this.table).index(index);
    const record = await db.getAll(query, count);
    return record;
  }

  async get(query, index) {
    if (!this.initialized) await this.init();
    let db;
    if (!index) db = this.idb.transaction(this.table, "readwrite").objectStore(this.table);
    else db = this.idb.transaction(this.table, "readwrite").objectStore(this.table).index(index);
    const record = await db.get(query);
    return record;
  }

  async add(value, key) {
    if (!this.initialized) await this.init();
    const db = this.idb.transaction(this.table, "readwrite").objectStore(this.table);
    const record = await db.add(value, key);
    return record;
  }

  async addMany(array, key) {
    if (!this.initialized) await this.init();
    const db = this.idb.transaction(this.table, "readwrite").objectStore(this.table);
    const record = await Promise.all(array.map((value) => db.add(value, key)));
    return record;
  }

  async remove(key) {
    if (!this.initialized) await this.init();
    const db = this.idb.transaction(this.table, "readwrite").objectStore(this.table);
    const record = await db.delete(key);
    return record;
  }

  async put(value, key) {
    if (!this.initialized) await this.init();
    const db = this.idb.transaction(this.table, "readwrite").objectStore(this.table);
    const record = await db.put(value, key);
    return record;
  }

  async count(key) {
    if (!this.initialized) await this.init();
    const db = this.idb.transaction(this.table, "readwrite").objectStore(this.table);
    const record = await db.count(key);
    return record;
  }

  async clear(key) {
    if (!this.initialized) await this.init();
    const db = this.idb.transaction(this.table, "readwrite").objectStore(this.table);
    const record = await db.clear(key);
    return record;
  }

  async getAllKeys(query, index, count) {
    if (!this.initialized) await this.init();
    const db = this.idb.transaction(this.table, "readwrite").objectStore(this.table).index(index);
    const record = await db.getAllKeys(query, count);
    return record;
  }

  async getLimit(query, index, option = { limit: 10 }) {
    if (!this.initialized) await this.init();
    let array = [];
    const db = this.idb.transaction(this.table, "readwrite").objectStore(this.table).index(index);
    const dbLength = await db.count(query);
    if (dbLength < option.limit) {
      array = await db.getAll(query);
      return array;
    }
    const cursors = await db.openCursor(query, "prev");
    if (cursors) {
      for (let i = 0; i < option.limit; i += 1) {
        array.push(cursors.value);
        // eslint-disable-next-line no-await-in-loop
        await cursors.continue();
      }
    }
    return array.reverse();
  }
}

(async function idb() {
  await openDB(CHAT_DATABASE, 1, {
    upgrade(db) {
      const group = db.createObjectStore(GROUP_TABLE, {
        keyPath: "id",
        autoIncrement: true,
      });
      const message = db.createObjectStore(MESSAGE_TABLE, {
        keyPath: "id",
        autoIncrement: true,
      });
      group.createIndex("_id", "_id");
      group.createIndex("user_id", "user_id");
      group.createIndex("index", "index");
      message.createIndex("_id", "_id");
      message.createIndex("group_id", "group_id");
      message.createIndex("date", "date");
      message.createIndex("state", "state");
    },
  });
})();

export default IDB;

