import { Box, Button, Typography } from "@mui/material";
import qr from "assets/images/qr.png";
import styles from "assets/styles/pages/LoginQR/QR";
import { useTranslation } from "react-i18next";

export default function QR({ handleNavigateLogin }) {
  const { t } = useTranslation();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.qrImage} component="img" src={qr} />
      <Typography variant="d2" sx={styles.title} color="initial">
        {t("login_qr.login_by_qr")}
      </Typography>

      <Box sx={styles.subtitleContainer}>
        <Typography variant="subtitle2" color="initial">
          {t("login_qr.option_1")}
        </Typography>
        <Box sx={styles.inlineContainer}>
          <Typography sx={styles.inline} variant="subtitle2" color="initial">
            {t("login_qr.option_2")}
          </Typography>
          <Typography sx={styles.inlineBold} variant="subtitle2" color="initial">
            {" "}
            {t("login_qr.option_2_bold")}
          </Typography>
        </Box>
        <Typography variant="subtitle2" color="initial">
          {t("login_qr.option_3")}
        </Typography>
      </Box>

      <Button
        variant="outlined"
        color="primary"
        sx={styles.loginButton}
        onClick={handleNavigateLogin}
      >
        <Typography variant="body1" color="primary" sx={styles.login}>
          {t("login_qr.login_button")}
        </Typography>
      </Button>
    </Box>
  );
}
