import { isEmpty } from "utils/validate";

export default {
  container: {
    mt: 2.5,
    p: 1,
    wordBreak: "break-word",
  },
  section: ({ isOwner }) => ({
    display: "flex",
    alignSelf: isOwner ? "flex-end" : "flex-start",
    flexDirection: isOwner ? "row-reverse" : "row",
  }),
  avatarContainer: {
    width: "50px",
    mr: 2,
  },
  avatar: ({ isShowAvatar }) => ({
    display: isShowAvatar ? "block" : "none",
    width: "50px",
    height: "50px",
  }),
  messageSection: ({ breakpoints }, { isLink }) => ({
    maxWidth: isLink ? "25%" : "40%",
    [breakpoints.down("xxl")]: {
      maxWidth: isLink ? "45%" : "60%",
    },
    [breakpoints.down("xl")]: {
      maxWidth: isLink ? "45%" : "70%",
    },
    [breakpoints.down("sm")]: {
      maxWidth: isLink ? "55%" : "70%",
    },
  }),
  timeContainer: {
    display: "flex",
    alignSelf: "flex-end",
    alignItems: "center",
  },
  time: ({ palette, functions }, { isOwner }) => ({
    color: isOwner ? functions.rgba(palette.white.main, 0.5) : palette.grey[300],
  }),
  sendedIcon: {
    width: "22px",
    marginLeft: "8px",
  },
  messageContainer: ({ borders }, { isOwner, isReply, isDisplayUsername }) => ({
    background: isOwner ? "linear-gradient(90deg, #1BC88B 0%, #26BBFE 100%)" : "#F0F0F0",
    pt: isReply || (isDisplayUsername && !isOwner) ? 1.5 : 0,
    pb: 1.5,
    borderTopRightRadius: !isOwner ? borders.borderRadius.xl : 0,
    borderTopLeftRadius: isOwner ? borders.borderRadius.xl : 0,
    borderBottomRightRadius: borders.borderRadius.xl,
    borderBottomLeftRadius: borders.borderRadius.xl,
    display: "flex",
    flexDirection: "column",
  }),
  nameContainer: {
    px: 2,
    mb: 0.5,
  },
  username: ({ typography, palette }) => ({
    fontWeight: typography.fontWeightBold,
    color: palette.inherit.main,
    display: "inline",
  }),
  userID: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    background: "-webkit-linear-gradient(#26BBFE, #1BC88B)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    pl: 0.7,
    display: "inline",
  }),
  replyContainer: ({ borders }, { isOwner, isDisplayUsername }) => ({
    background: isOwner ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 0.1)",
    px: 2,
    py: 1.5,
    display: "flex",
    borderTopRightRadius: !isOwner && !isDisplayUsername ? borders.borderRadius.xl : 0,
    borderTopLeftRadius: isOwner || !isDisplayUsername ? borders.borderRadius.xl : 0,
  }),
  replyIconContainer: ({ palette }, { isOwner }) => ({
    path: {
      fill: isOwner ? palette.white.main : "#454545",
    },
    height: "10px !important",
    width: "12px !important",
  }),
  replyIcon: {
    marginTop: "4px",
    height: "10px !important",
    width: "12px !important",
  },
  replyInformationContainer: {
    pl: 1,
  },
  replyName: ({ typography, palette }, { isOwner }) => ({
    color: isOwner ? palette.white.main : palette.black.main,
    fontWeight: typography.fontWeightBold,
    fontSize: typography.size.xs,
    mb: 0.5,
  }),
  replyMessage: ({ typography, palette }, { isOwner }) => ({
    color: isOwner ? palette.white.main : palette.black.main,
    fontSize: typography.size.xs,
  }),
  message: ({ palette, typography }, { replyName, replyMessage, isOwner }) => ({
    color: isOwner ? palette.white.main : palette.black.main,
    px: 2,
    mt: !isEmpty(replyName) && !isEmpty(replyMessage) ? 1 : 0,
    fontSize: typography.size.sm,
    a: {
      color: isOwner ? palette.white.main : palette.black.main,
      textDecoration: "underline",
    },
  }),
  linkContainer: ({ isOwner }) => ({
    width: "100%",
    overflow: "hidden",
    mt: isOwner ? -1.5 : 0,
  }),
  imageContainer: () => ({
    width: "100%",
  }),
  image: ({ borders }, { isOwner }) => ({
    width: "100%",
    objectFit: "contain",
    borderTopRightRadius: !isOwner ? borders.borderRadius.xl : 0,
    borderTopLeftRadius: isOwner ? borders.borderRadius.xl : 0,
    maxHeight: "25vh",
  }),
  linkContentContainer: {
    px: 2,
    maxWidth: "100%",
  },
  linkTitle: ({ typography, palette }, { isOwner }) => ({
    pt: 0.5,
    fontWeight: typography.fontWeightBold,
    color: isOwner ? palette.white.main : palette.black.main,
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  }),
  link: ({ palette, typography }, { isOwner }) => ({
    pt: 0.5,
    color: isOwner ? palette.white.main : palette.black.main,
    a: {
      color: isOwner ? palette.white.main : palette.black.main,
      textDecoration: "underline",
    },
    fontSize: typography.size.xs,
  }),
  messageTimeContainer: {
    display: "flex",
    alignSelf: "flex-end",
    alignItems: "center",
    cursor: "default",
    px: 2,
    mt: 1,
  },
  emotionContainer: ({ palette, borders, functions }, { isOwner }) => ({
    display: "flex",
    border: `1.5px solid ${isOwner ? functions.rgba(palette.white.main, 0.6) : palette.white.main}`,
    borderRadius: borders.borderRadius.round,
    p: 0.4,
    mx: 0.5,
    alignItems: "center",
    cursor: "pointer",
  }),
  emotionIcon: {
    height: "16px",
    width: "100%",
    maxWidth: "20px",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
  emotionCount: ({ palette, functions }, { isOwner }) => ({
    paddingLeft: "3px",
    paddingRight: "4px",
    color: isOwner ? functions.rgba(palette.white.main, 0.6) : "#898989",
    fontSize: "12px",
  }),
  messageTime: ({ palette, functions }, { isOwner }) => ({
    color: isOwner ? functions.rgba(palette.white.main, 0.5) : palette.grey[300],
  }),
  messageSendedIcon: {
    height: "14px",
    marginLeft: "8px",
  },
  messagePendingIcon: {
    height: "14px",
    width: "14px",
    marginLeft: "8px",
  },
  actionContainer: ({ isOwner }) => ({
    alignSelf: "center",
    mr: 0,
    display: "flex",
    flexDirection: isOwner ? "row-reverse" : "row",
  }),
  actionSection: {
    display: "flex",
    flexDirection: "column",
  },
};
