import { chatWidth, lgChatWidth, xlChatWidth, xxlChatWidth } from "constant/chat";

export default {
  drawer: ({ palette, transitions, breakpoints }) => ({
    width: chatWidth,
    transition: transitions.create("width", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
    "& .MuiDrawer-paper": {
      overflow: "visible",
      width: chatWidth,
      borderRight: `1px solid ${palette.grey[200]}`,
      transition: transitions.create("width", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
    [breakpoints.down("xxl")]: {
      width: xxlChatWidth,
      "& .MuiDrawer-paper": {
        width: xxlChatWidth,
      },
    },
    [breakpoints.down("xl")]: {
      width: xlChatWidth,
      "& .MuiDrawer-paper": {
        width: xlChatWidth,
      },
    },
    [breakpoints.down("lg")]: {
      width: lgChatWidth,
      "& .MuiDrawer-paper": {
        width: lgChatWidth,
      },
    },
    [breakpoints.down("md")]: {
      width: "100%",
      "& .MuiDrawer-paper": {
        width: "100%",
      },
    },
  }),
  boxHeader: {
    px: 2,
    pt: 2,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "60px",
    minHeight: "60px",
    mb: 2,
  },
  personalContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatarContainer: ({ palette }) => ({
    cursor: "pointer",
    "& .MuiBadge-badge": {
      backgroundColor: palette.success.light,
      bottom: 9,
      right: 9,
      height: 15,
      minWidth: 14,
      border: `2px solid ${palette.white.main}`,
    },
    mr: 1.5,
  }),
  avatar: {
    width: "60px",
    height: "60px",
  },
  name: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    lineHeight: "18px",
    overflow: "hidden",
    height: "18px",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
  }),
  vga: ({ palette }) => ({
    color: palette.grey[650],
    lineHeight: "16px",
    overflow: "hidden",
    height: "16px",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
    mt: 0.5,
  }),
  settingButton: {
    px: 0,
  },
  settingIcon: {
    width: "25px",
    height: "21px",
  },
  searchIcon: {
    mx: 0.5,
    cursor: "default",
  },
  search: ({ palette }) => ({
    width: "100%",
    borderRadius: "100px",
    backgroundColor: palette.background.search,
    height: "44px",
    fieldset: {
      borderRadius: "100px",
      border: "none",
      height: "44px",
    },
    input: {
      py: 1.5,
      "&::placeholder": {
        fontWeight: 550,
      },
    },
  }),
  userList: {
    overflow: "scroll",
    pl: 2,
    pr: 1,
    mt: 2,
  },
  userListAdd: {
    px: 2,
    mt: 2,
  },
  userContainer: ({ breakpoints }) => ({
    overflowY: "hidden",
    maxWidth: chatWidth,
    [breakpoints.down("xxl")]: {
      maxWidth: xxlChatWidth,
      "& .MuiDrawer-paper": {
        maxWidth: xxlChatWidth,
      },
    },
    [breakpoints.down("xl")]: {
      maxWidth: xlChatWidth,
      "& .MuiDrawer-paper": {
        maxWidth: xlChatWidth,
      },
    },
    [breakpoints.down("lg")]: {
      maxWidth: lgChatWidth,
      "& .MuiDrawer-paper": {
        maxWidth: lgChatWidth,
      },
    },
    [breakpoints.down("md")]: {
      maxWidth: "100%",
      "& .MuiDrawer-paper": {
        maxWidth: "100%",
      },
    },
  }),

  //* * Style Loading */
  loadingContainer: {
    display: "flex",
    mb: 2,
    justifyContent: "space-between",
  },
  loadSearch: {
    borderRadius: "100px",
  },
  loadInformation: {
    display: "flex",
    alignItems: "center",
  },
  loadAvatar: {
    width: "60px",
    height: "60px",
    mr: 1.5,
  },
  loadName: {
    mb: 1,
  },
  loadTime: {
    mt: 1,
  },
  settingSkeleton: {
    margin: "5px",
    width: "25px",
    height: "25px",
    borderRadius: "4px",
  },

  backIconContainer: {
    display: "flex",
    p: 2,
    alignItems: "center",
  },
  backButton: {
    px: 0,
    mr: 2,
    my: 0,
  },
  backIcon: {
    width: "24px",
    height: "24px",
  },
  username: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    display: "inline",
  }),
  vid: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    display: "inline",
    background: "-webkit-linear-gradient(#26BBFE, #1BC88B)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  }),
  newGroupContainer: ({ palette, typography }) => ({
    display: "flex",
    mb: 2,
    ml: 1,
    alignItems: "center",
    cursor: "pointer",
    p: {
      fontWeight: typography.fontWeightBold,
    },
    "&:hover": {
      p: {
        background: "-webkit-linear-gradient(#26BBFE, #1BC88B)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      },
      "& .add": {
        backgroundColor: palette.grey[50],
      },
    },
  }),
  addContainer: ({ palette, borders }) => ({
    backgroundColor: palette.grey[100],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pt: 1.1,
    pl: 1.1,
    pr: 0.9,
    pb: 0.9,
    borderRadius: borders.borderRadius.round,
  }),
  addIcon: {
    width: "24px",
    height: "24px",
    marginTop: "2px",
    marginLeft: "2px",
  },
  addTitle: ({ typography }) => ({
    ml: 1.5,
    fontWeight: typography.fontWeightMedium,
  }),
  searchType: {
    ml: 1,
    mb: 0.5,
  },
  listUserContainer: {
    pb: 2,
  },
  singleContainer: ({ borders }) => ({
    py: 1,
    px: 2,
    display: "flex",
    borderRadius: borders.borderRadius.md,
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F3F3F3",
    },
  }),
  singleAvatar: {
    width: "44px",
    height: "44px",
  },
  singleNameContainer: {
    ml: 1.5,
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexGrow: 1,
  },
  groupContainer: {
    display: "flex",
    alignItems: "center",
    mb: 2,
  },
  groupCropImage: ({ palette }) => ({
    width: "50px",
    height: "50px",
    border: `1px dashed ${palette.primary.main}`,
    backgroundColor: "#F9F9F9",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
  }),
  cameraContainer: {
    width: "30px",
    mt: 0.5,
  },
  groupNameContainer: {
    width: "100%",
    ml: 2,
  },
  groupName: {
    width: "100%",
  },
  addGroupChatContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    ml: 1,
  },
  addGroupIconContainer: {
    width: "28px",
    height: "28px",
  },
  addGroupChatIcon: {
    width: "100%",
    height: "100%",
  },
  memberContainer: {
    display: "flex",
    overflowY: "scroll",
    ml: 2,
    mb: 0.5,
    height: "65px",
  },
  memberSection: {
    mr: 2,
  },
  memberAvatarContainer: {
    position: "relative",
  },
  memberAvatar: {
    width: "50px",
    height: "50px",
  },
  removeMemberIconContainer: {
    position: "absolute",
    top: "0px",
    right: "-8px",
    backgroundColor: "#8D929F !important",
    width: "20px",
    height: "20px",
    "&:hover": {
      opacity: 0.9,
    },
  },
  removeMemberIcon: {
    width: "12px",
    height: "12px",
    fill: "#fff",
  },
  userGroupContainer: {
    pb: 2,
  },
  userGroupItem: ({ borders }) => ({
    px: 2,
    display: "flex",
    borderRadius: borders.borderRadius.md,
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
  }),
  checkContainer: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    mr: 0,
    label: {
      width: "100%",
    },
    svg: {
      width: "18px !important",
      height: "18px !important",
    },
  },
  labelContainer: {
    py: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
  },
  groupAvatar: {
    width: "44px",
    height: "44px",
  },
  groupUsernameContainer: {
    display: "flex",
    userSelect: "none",
    ml: 1,
  },
  cropAvatarContainer: ({ borders, palette }) => ({
    cursor: "pointer",
    position: "relative",
    borderRadius: borders.borderRadius.round,
    border: `1px solid ${palette.grey[100]}`,
    "&:hover": {
      "& .avatar-overlay": {
        display: "flex",
      },
    },
  }),
  overlay: ({ palette, functions, borders }) => ({
    position: "absolute",
    display: "none",
    width: "100%",
    height: "100%",
    left: 0,
    bottom: 0,
    backgroundColor: functions.rgba(palette.black.main, 0.3),
    zIndex: 1,
    flexGrow: 1,
    borderRadius: borders.borderRadius.round,
    justifyContent: "center",
    alignItems: "center",
  }),
  updateAvatarIcon: {
    fill: "white",
  },
  cropAvatar: {
    width: "50px",
    height: "50px",
  },
};
