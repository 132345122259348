import colors from "assets/theme/base/colors";
import { chatWidth, lgChatWidth, xlChatWidth, xxlChatWidth } from "constant/chat";

const { primary, initial } = colors;
export default {
  paper: {
    boxShadow: "none",
  },
  dFlex: {
    display: "flex",
  },
  selectMulti: { padding: "0px 17px 10px", display: "flex", alignItems: "center" },
  flexGrow: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },

  fillColorIconLeft: {
    fill: "#00ABA7",
    width: "24px",
    height: "24px",
  },

  popperPoll: {
    height: "470px",
    boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.15)",
  },

  popper: ({ breakpoints }) => ({
    zIndex: "9999",
    width: "100%",
    [breakpoints.up("md")]: {
      maxWidth: `calc(100% - ${lgChatWidth})`,
    },
    [breakpoints.up("sm")]: {
      width: "100%",
    },
    [breakpoints.up("xxl")]: {
      maxWidth: `calc(100% - ${chatWidth})`,
    },
    [breakpoints.up("xl")]: {
      maxWidth: `calc(100% - ${xxlChatWidth})`,
    },
    [breakpoints.up("lg")]: {
      maxWidth: `calc(100% - ${xlChatWidth})`,
    },
  }),

  boxHeader: {
    width: "100%",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #C7C7C7;",
  },
  boxTitle: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  textTitle: {
    flexGrow: 1,
    py: 2.5,
    fontFamily: "'SF Pro Text', sans-serif",
    fontWeight: "700",
    textAlign: "center",
  },

  icon: { width: "24px", height: "24px" },

  textQuestion: {
    padding: "20px 17px 0 ",
    fontSize: "16px",
    width: "100%",
    input: {
      fontFamily: "'SF Pro Text', sans-serif !important",
      fontSize: "16px",
      marginBottom: "20px",
    },
    "& .MuiInput-underline:after": {
      borderBottom: `1.5px solid ${primary.main}`,
    },
  },

  InputAdornment: ({ typography }) => ({
    marginBottom: "20px",
    fontSize: "16px",
    fontFamily: typography.fontFamily2,
  }),

  boxAnswer: {
    paddingLeft: "17px",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    justifyContent: "start",
    maxHeight: "230px",
  },

  inputAnswer: {
    pt: 2,
    flexGrow: 1,
    fontSize: "16px",
    textAlign: "center",
    input: {
      fontFamily: "'SF Pro Text', sans-serif",
      mb: 2,
    },
    "&:focus": {
      outline: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: `1.5px solid ${primary.main}`,
    },
  },

  dragIcon: {
    justifyContent: "end",
    pt: "20px",
    position: "relative",
    top: "-18px",
    marginRight: "17px",
  },

  addAnswerIcon: { justifyContent: "start", pt: "20px" },

  dFlexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  buttonCreatePollDefault: {
    mb: "10px",
    minWidth: "200px",
    height: "50px",
    background: "#F3F3F3",
    borderRadius: "10px",
    border: "none",
    pointerEvents: "none",
  },

  buttonCreatePollActive: {
    border: "none",
    mb: "10px",
    minWidth: "200px",
    height: "50px",
    background: "linear-gradient(273.66deg, #6788FC 3.01%, #28EAA5 96.99%)",
    borderRadius: "10px",
    "&:hover": {
      boxShadow: "none",
      opacity: 0.9,
      border: "none",
    },
  },

  textButtonDefault: ({ palette }) => ({
    fontFamily: "'SVN-Gotham', sans-serif !important",
    color: palette.grey[600],
  }),

  textButtonActive: ({ palette }) => ({
    fontFamily: "'SVN-Gotham', sans-serif !important",
    color: palette.white.main,
  }),

  textColorBlack: { color: initial.main },
};
