import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import styles from "assets/styles/pages/Chat/component/PollPopper";
import { Button, InputAdornment, Switch, TextField, Typography } from "@mui/material";
import { ReactComponent as AddOptionIcon } from "assets/svg/icon/add_option.svg";
import { ReactComponent as CloseVoteIcon } from "assets/svg/icon/close_vote.svg";
import { ReactComponent as DragIcon } from "assets/svg/icon/drag_icon.svg";
import { ReactSortable } from "react-sortablejs";
import { useTranslation } from "react-i18next";

function PollPopper({
  openVote,
  handleCloseVote,
  textQuestion,
  handleTextQuestion,
  inputQuestionRef,
  boxRef,
  listVote,
  setListVote,
  handleChange,
  elRefs,
  handleAddVote,
  isMaxOption,
  isActive,
  setSelectMulti,
  handleCreatePoll,
}) {
  const { t } = useTranslation();
  return (
    <Popper
      sx={styles.popper}
      placement="right-end"
      open={openVote}
      anchorEl={undefined}
      role={undefined}
      transition
      disablePortal
      style={{ position: "fixed", bottom: 0, right: 0, top: "unset", left: "unset" }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper sx={styles.paper}>
            <ClickAwayListener
              mouseEvent="onMouseDown"
              disableReactTree
              onClickAway={handleCloseVote}
            >
              <Box sx={styles.popperPoll}>
                <Box sx={styles.boxHeader}>
                  <Box sx={styles.boxTitle}>
                    <Typography variant="body1" color="initial" sx={styles.textTitle}>
                      {t("chat.footer.poll.title")}
                    </Typography>
                    <Box sx={{ mr: "17px" }}>
                      <IconButton onClick={handleCloseVote}>
                        <CloseVoteIcon sx={styles.icon} />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box sx={styles.selectMulti}>
                    <Box sx={styles.dFlex}>
                      <Typography variant="body1" sx={{ mr: 0.5 }}>
                        {t("chat.footer.poll.multiple_options")}
                      </Typography>
                    </Box>
                    <Switch onChange={(e) => setSelectMulti(e.target.checked)} />
                  </Box>
                </Box>
                <Box>
                  <TextField
                    placeholder=""
                    sx={styles.textQuestion}
                    value={textQuestion}
                    onChange={handleTextQuestion}
                    inputRef={inputQuestionRef}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" sx={styles.InputAdornment}>
                          <Typography sx={styles.textColorBlack}>
                            {t("chat.footer.poll.question")}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />

                  <Box id="items" ref={boxRef} sx={styles.boxAnswer}>
                    <ReactSortable
                      filter=".add_option .create_poll"
                      list={listVote}
                      setList={setListVote}
                      dragClass="sortableDrag"
                      animation="200"
                      easing="ease-out"
                      handle="#dragIcon"
                    >
                      {listVote.map((vote, i) => (
                        <Box key={i} sx={styles.flexGrow}>
                          <TextField
                            value={vote.value}
                            sx={styles.inputAnswer}
                            onChange={(e) => handleChange(e, i)}
                            ref={elRefs[i]}
                            placeholder="Add option"
                            variant="standard"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton id="dragIcon" sx={styles.dragIcon}>
                                    <DragIcon sx={styles.icon} />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      ))}
                      <Box>
                        <IconButton
                          id="add_option"
                          onClick={handleAddVote}
                          sx={styles.addAnswerIcon}
                        >
                          {!isMaxOption && <AddOptionIcon sx={styles.fillColorIconLeft} />}
                        </IconButton>
                      </Box>
                    </ReactSortable>
                  </Box>
                  <Box sx={styles.dFlexCenter}>
                    <Button
                      id="create_poll"
                      variant="outlined"
                      color="primary"
                      onClick={handleCreatePoll}
                      sx={isActive ? styles.buttonCreatePollActive : styles.buttonCreatePollDefault}
                    >
                      <Typography
                        variant="body1"
                        sx={isActive ? styles.textButtonActive : styles.textButtonDefault}
                      >
                        {t("chat.footer.poll.create")}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

export default PollPopper;
