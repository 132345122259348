const chatBarHeight = "80px";
const chatWidth = "452px"; // 552px
const xxlChatWidth = "402px";
const xlChatWidth = "402px";
const lgChatWidth = "352px";

const informationWidth = "402px"; // 448px
const xxlInformationWidth = "402px";
const xlInformationWidth = "402px";
const lgInformationWidth = "302px";

const AdminNames = ["World golf handicap network", "vBank"];

const ACTION_TYPE = {
  DELETE_GROUP: 699,
  LEAVE_GROUP: 6999,

  UPDATE_GROUP: 7000,
};

const MESSAGE_TYPE = {
  TEXT: "text",
  IMAGE: "image",
  FILE: "file",
  VIDEO: "video",
};

const FILE_TYPE = {
  XLSX: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/csv"],
  DOC: [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
  ],
  PDF: ["application/pdf"],
  TXT: ["text/plain"],
  PPT: [
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.ms-powerpoint",
  ],
  ZIP: ["application/x-zip-compressed"],
};

const SPECIAL_FILE_TYPE = {
  IMAGE: "image/",
  VIDEO: "video/",
};

const MESSAGE_STATE = {
  PENDING: 1,
  SENDING: 2,
  SENDED: 3,
  RESEND: 4,
  FAIL: 999,
};

export {
  chatBarHeight,
  chatWidth,
  xxlChatWidth,
  xlChatWidth,
  lgChatWidth,
  informationWidth,
  xxlInformationWidth,
  xlInformationWidth,
  lgInformationWidth,
  AdminNames,
  ACTION_TYPE,
  MESSAGE_TYPE,
  FILE_TYPE,
  MESSAGE_STATE,
  SPECIAL_FILE_TYPE,
};
