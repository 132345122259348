import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Fade from "@mui/material/Fade";
import defaultAvatar from "assets/images/default/default_avatar.png";
import { ReactComponent as AddConversationIcon } from "assets/svg/icon/add_conversation.svg";
import { ReactComponent as SearchIcon } from "assets/svg/icon/search.svg";
import { ReactComponent as CameraIcon } from "assets/svg/icon/camera.svg";
import { ReactComponent as AddGroupChatIcon } from "assets/svg/icon/chat_add_group.svg";
import { ReactComponent as BackIcon } from "assets/svg/icon/user_list_back.svg";
import CloseIcon from "@mui/icons-material/Close";
import styles from "assets/styles/pages/Chat/section/UserList";
import { emptyFunction } from "utils/utils";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { decodeID } from "utils/hex";
import LoadingUserList from "pages/Chat/loading/UserList";
import { AdminNames } from "constant/chat";
import { ReactComponent as AddGroupIcon } from "assets/svg/icon/add_group.svg";
import { useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { isEmpty } from "jssip/lib/Utils";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { encrypt } from "utils/cipher";
import GroupIDB from "IndexedDB/GroupIDB";
import { groupChatDetail } from "api/chat";
import { useMutation } from "react-query";
import UserItem from "../component/UserItem";
import PersonalPopper from "../component/PersonalPopper";
import CropGroupAvatar from "../component/CropGroupAvatar";

function UserList({
  searchOptions,
  selectOptions,
  personalOptions,
  dialogOptions,
  listGroupChat,
  isLoading,
  userInformation,
  conversationOptions,
  listGroupAdd,
  addGroupChat,
}) {
  const { t } = useTranslation();
  const { vID } = useParams();
  const [groupName, setGroupName] = useState("");
  const [isOpenCrop, setOpenCrop] = useState(false);
  const [members, setMember] = useState([]);
  const [avatar, setAvatar] = useState(null);

  const handleResetGroup = () => {
    setAvatar(null);
    setGroupName("");
    setMember([]);
  };

  useEffect(() => {
    const slider = document.querySelector(".container");
    let isDown = false;
    let startX;
    let scrollLeft;

    if (slider) {
      slider.addEventListener("mousedown", (e) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 2;
        slider.scrollLeft = scrollLeft - walk;
      });
    }
  }, [members]);

  const handleOpenCropImage = () => setOpenCrop(true);
  const handleCloseCropImage = () => setOpenCrop(false);
  const groupDetailMutate = useMutation("detail", (id) => groupChatDetail(id), {
    onSuccess: async (res) => {
      if (res.error !== 0) return;
      const idbGroup = await GroupIDB.get(res?.group?.id, "_id");
      await GroupIDB.put({
        ...(idbGroup || {}),
        _id: res?.group?.id,
        user_id: userInformation?.id,
        detail: encrypt(res?.group),
      });
    },
  });

  const handleModifyMember = async (user, state) => {
    let member = {};
    if (state) {
      if (isEmpty(user.id)) {
        const group = (await groupDetailMutate.mutateAsync(user.group_id))?.group?.users?.filter(
          (v) => v.id !== userInformation.id
        )[0];
        addGroupChat.refetchRecent();
        if (members.map((v) => v.id).includes(group.id)) member = null;
        else
          member = {
            id: group.id,
            avatar: group.avatar,
            username: group.username,
            group_id: user.group_id,
          };
      } else {
        listGroupChat.forEach(async (v) => {
          if (v.group.name === user.username && isEmpty(v.detail)) {
            await groupDetailMutate.mutateAsync(v._id);
            addGroupChat.refetchRecent();
          }
        });
        member = {
          id: user.id,
          avatar: user.avatar,
          username: user.username,
        };
      }
      if (!isEmpty(member)) setMember((prev) => [...prev, member]);
    } else {
      const removeMember = members.filter((v) => v.id !== user.id);
      setMember(removeMember);
    }
  };

  if (isLoading) return <LoadingUserList />;
  return (
    <Box>
      <CropGroupAvatar
        setting={{ isOpen: isOpenCrop, onClose: handleCloseCropImage, setAvatar, avatar }}
      />
      <PersonalPopper
        userInformation={userInformation}
        anchor={personalOptions.personalAnchor}
        handleClose={personalOptions.handleClosePersonalPopper}
        isOpenPopper={personalOptions.isPersonalPopperOpen}
        handleLogout={personalOptions.handleLogout}
        handleOpenSettingDialog={dialogOptions.handleOpenSettingDialog}
      />
      <Drawer sx={styles.drawer} variant="permanent">
        {!conversationOptions.isAddConversation && (
          <>
            <Box sx={styles.boxHeader}>
              <Box sx={styles.headerContainer}>
                {/* Header */}
                <Box sx={styles.personalContainer}>
                  <Badge
                    onClick={personalOptions.handleOpenPersonalPopper}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent=""
                    color="success"
                    sx={styles.avatarContainer}
                  >
                    <Avatar
                      src={defaultAvatar}
                      srcSet={userInformation.avatar}
                      sx={styles.avatar}
                    />
                  </Badge>

                  <Box>
                    <Typography variant="body1" color="initial" sx={styles.name}>
                      {userInformation.username}
                    </Typography>
                    <Typography variant="body2" sx={styles.vga}>
                      vID: {userInformation.id}
                    </Typography>
                  </Box>
                </Box>
                <IconButton
                  sx={styles.settingButton}
                  onClick={conversationOptions.handleOpenConversation}
                >
                  <AddConversationIcon style={styles.settingIcon} />
                </IconButton>
              </Box>
              {/* Search */}
              <TextField
                placeholder={t("chat.user_list.search")}
                onChange={searchOptions.handleSearchUserList}
                InputProps={{
                  startAdornment: (
                    <InputAdornment sx={styles.searchIcon} position="start">
                      <SearchIcon style={styles.searchIcon} />
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
                sx={styles.search}
              />
            </Box>
            {/* Users */}
            <Box sx={styles.userList}>
              {listGroupChat?.map((element) => (
                <Box sx={styles.userContainer} key={element.id}>
                  <UserItem
                    onSelect={() => selectOptions.handleSelect(element?.group)}
                    isSelected={+decodeID(vID) === element?.group?.id}
                    mute={element?.group?.is_mute}
                    information={{
                      ...element,
                      time:
                        element?.group?.type === "group" ||
                        AdminNames.includes(element?.group?.name)
                          ? ""
                          : element?.detail?.users?.find((e) => +e.id !== +userInformation.id)
                              ?.last_state || t("dates.ago", { date: element?.group?.updatedAt }),
                    }}
                  />
                </Box>
              ))}
            </Box>
          </>
        )}
        {/* Add Conversation */}
        <Fade in={conversationOptions.isAddConversation} unmountOnExit>
          <Box>
            <Box sx={styles.backIconContainer}>
              <IconButton
                sx={styles.backButton}
                onClick={
                  addGroupChat.isAddGroup
                    ? () => addGroupChat.handleCloseAddGroup(handleResetGroup)
                    : conversationOptions.handleCloseConversation
                }
              >
                <BackIcon style={styles.backIcon} />
              </IconButton>
              <TextField
                value={addGroupChat.searchGolferKey}
                placeholder={t("chat.user_list.search")}
                onChange={addGroupChat.handleSearchUser}
                InputProps={{
                  startAdornment: (
                    <InputAdornment sx={styles.searchIcon} position="start">
                      <SearchIcon style={styles.searchIcon} />
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
                sx={styles.search}
              />
            </Box>

            <Box sx={styles.userListAdd}>
              {!addGroupChat.isAddGroup ? (
                <Box>
                  <Box sx={styles.newGroupContainer} onClick={addGroupChat.handleOpenAddGroup}>
                    <Box className="add" sx={styles.addContainer}>
                      <AddGroupIcon style={styles.addIcon} />
                    </Box>
                    <Typography sx={styles.addTitle} color="initial.main" variant="body1">
                      {t("chat.user_list.new_group")}
                    </Typography>
                  </Box>
                  <Typography sx={styles.searchType} color="initial.main" variant="body1">
                    {addGroupChat.searchGolferKey
                      ? t("chat.user_list.search")
                      : t("chat.user_list.suggest")}
                  </Typography>
                  <Box sx={styles.listUserContainer}>
                    {listGroupAdd?.map((item, index) => (
                      <Box
                        key={index}
                        sx={styles.singleContainer}
                        onClick={() => addGroupChat.handleAddSingleChat(item)}
                      >
                        <Avatar sx={styles.singleAvatar} src={defaultAvatar} srcSet={item.avatar} />
                        <Box sx={styles.singleNameContainer}>
                          <Typography variant="body2" color="initial" sx={styles.username}>
                            {item?.username}
                            {item?.id && " - "}
                          </Typography>
                          {item?.id && (
                            <Typography variant="body2" color="initial" sx={styles.vid}>
                              {item?.id}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : (
                <>
                  <Box sx={styles.groupContainer}>
                    {!avatar ? (
                      <Button sx={styles.groupCropImage} onClick={handleOpenCropImage}>
                        <Box sx={styles.cameraContainer}>
                          <CameraIcon />
                        </Box>
                      </Button>
                    ) : (
                      <Box onClick={handleOpenCropImage} sx={styles.cropAvatarContainer}>
                        <Box className="avatar-overlay" sx={styles.overlay}>
                          <CameraIcon style={styles.updateAvatarIcon} />
                        </Box>
                        <Avatar sx={styles.cropAvatar} src={avatar} />
                      </Box>
                    )}
                    <Box sx={styles.groupNameContainer}>
                      <Typography>{t("chat.user_list.group_name")}</Typography>
                      <TextField
                        sx={styles.groupName}
                        variant="standard"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                        InputProps={{ autoComplete: "off" }}
                      />
                    </Box>
                    <IconButton
                      sx={styles.addGroupChatContainer}
                      disabled={isEmpty(groupName) || isEmpty(members) || isEmpty(avatar)}
                      onClick={() => {
                        addGroupChat.handleAddGroupChat(
                          {
                            name: groupName,
                            members: members.map((v) => v.id),
                            image: avatar,
                          },
                          handleResetGroup
                        );
                      }}
                    >
                      <Box sx={styles.addGroupIconContainer}>
                        <AddGroupChatIcon style={styles.addGroupChatIcon} />
                      </Box>
                    </IconButton>
                  </Box>
                  <Fade in={!isEmpty(members)} unmountOnExit>
                    <Box sx={styles.memberContainer} className="container">
                      {members?.map((item, index) => (
                        <Box key={index} sx={styles.memberSection}>
                          <Box sx={styles.memberAvatarContainer}>
                            <Avatar
                              sx={styles.memberAvatar}
                              src={defaultAvatar}
                              srcSet={item.avatar}
                            />
                            <IconButton
                              onClick={() => handleModifyMember(item, false)}
                              sx={styles.removeMemberIconContainer}
                            >
                              <CloseIcon sx={styles.removeMemberIcon} />
                            </IconButton>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Fade>

                  <Typography sx={styles.searchType} color="initial.main" variant="body1">
                    {addGroupChat.searchGolferKey
                      ? t("chat.user_list.search")
                      : t("chat.user_list.suggest")}
                  </Typography>
                  <Box sx={styles.userGroupContainer}>
                    {members
                      .concat(
                        listGroupAdd?.filter((v) => !members.map((m) => m?.id).includes(v?.id))
                      )
                      .map((item, index) => (
                        <Box key={index} sx={styles.userGroupItem}>
                          <FormControlLabel
                            onChange={(e) => handleModifyMember(item, e.target.checked)}
                            sx={styles.checkContainer}
                            label={
                              <Box sx={styles.labelContainer}>
                                <Avatar
                                  srcSet={item?.avatar}
                                  src={defaultAvatar}
                                  sx={styles.groupAvatar}
                                />
                                <Box sx={styles.groupUsernameContainer}>
                                  <Typography variant="body2" color="initial" sx={styles.username}>
                                    {item?.username}
                                    {item?.id && " - "}
                                  </Typography>
                                  {item?.id && (
                                    <Typography
                                      ml={0.5}
                                      variant="body2"
                                      color="initial"
                                      sx={styles.vid}
                                    >
                                      {item?.id}
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                            }
                            checked={members.map((v) => v?.id).includes(item?.id)}
                            control={
                              <Checkbox
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<CheckCircleIcon />}
                              />
                            }
                          />
                        </Box>
                      ))}
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Fade>
      </Drawer>
    </Box>
  );
}

UserList.defaultProps = {
  selectOptions: {
    handleSelect: emptyFunction,
    selectId: null,
  },
  personalOptions: {
    isPersonalPopperOpen: false,
    handleOpenPersonalPopper: emptyFunction,
    handleClosePersonalPopper: emptyFunction,
  },
  searchOptions: {
    handleSearchUserList: emptyFunction,
  },
  dialogOptions: {
    handleOpenSettingDialog: emptyFunction,
  },
  conversationOptions: {
    isAddConversation: false,
    handleOpenConversation: emptyFunction,
    handleCloseConversation: emptyFunction,
  },
  addGroupChat: {
    handleSearchUser: emptyFunction,
  },
};

UserList.propTypes = {
  selectOptions: PropTypes.shape({
    handleSelect: PropTypes.func,
    selectId: PropTypes.any,
  }),
  searchOptions: PropTypes.shape({
    handleSearchUserList: PropTypes.func,
  }),
  personalOptions: PropTypes.shape({
    isPersonalPopperOpen: PropTypes.bool,
    handleOpenPersonalPopper: PropTypes.func,
    personalAnchor: PropTypes.any,
    handleClosePersonalPopper: PropTypes.func,
    handleLogout: PropTypes.func,
  }),
  dialogOptions: PropTypes.shape({
    handleOpenSettingDialog: PropTypes.func,
  }),
  conversationOptions: PropTypes.shape({
    isAddConversation: PropTypes.bool,
    handleOpenConversation: PropTypes.func,
    handleCloseConversation: PropTypes.func,
  }),
  addGroupChat: PropTypes.shape({
    handleSearchUser: PropTypes.func,
  }),
};

export default UserList;
