import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import styles from "assets/styles/pages/Chat/component/ForwardDialog";
import { InputAdornment, TextField, Stack, Avatar, Tabs, Tab } from "@mui/material";
import { ReactComponent as SearchIcon } from "assets/svg/icon/search.svg";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import defaultAvatar from "assets/images/default/default_avatar.png";
import avatar from "assets/images/avatar.png";
import avatar1 from "assets/images/avatar1.png";
import avatar2 from "assets/images/avatar2.png";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import TabPanel from "components/TabPanel";
import Checkbox from "./CheckBox";

const list = [
  {
    id: 1,
    avatar: avatar1,
    name: "Hoàng Phương",
  },
  {
    id: 4,
    avatar: avatar2,
    name: "Thúy Giang",
  },

  {
    id: 6,
    avatar: avatar1,
    name: "Hồng Nguyễn",
  },
  {
    id: 7,
    avatar,
    name: " Cường Nguyễn",
  },
  {
    id: 8,
    avatar: avatar1,
    name: "Anh Đông",
  },
  {
    id: 9,
    avatar: avatar1,
    name: "Hoàng Phương",
  },
  {
    id: 11,
    avatar: avatar1,
    name: "Hoàng Phương",
  },
  {
    id: 14,
    avatar: avatar2,
    name: "Thúy Giang",
  },

  {
    id: 15,
    avatar: avatar1,
    name: "Hồng Nguyễn",
  },
  {
    id: 16,
    avatar,
    name: " Cường Nguyễn",
  },
  {
    id: 18,
    avatar: avatar1,
    name: "Anh Đông",
  },
  {
    id: 19,
    avatar: avatar1,
    name: "Hoàng Phương",
  },
];

const tabList = [
  { id: 1, label: "Gần đây" },
  { id: 2, label: "Câu lạc bộ" },
  { id: 3, label: "Nhóm" },
];
export default function ForwardDialog({
  onClose,
  isOpen,
  selectForwardIndex,
  setSelectForwardIndex,
  handleDoneForwardDialog,
}) {
  // state
  const [value, setValue] = useState(0);
  const [isEmpty, setIsEmpty] = useState(true);

  // hook
  useEffect(() => {
    if (selectForwardIndex.length > 0) setIsEmpty(false);
    else setIsEmpty(true);
  }, [selectForwardIndex]);

  // function
  const handleCheckBox = (id) => {
    const cpList = [...selectForwardIndex];
    if (cpList.includes(id)) {
      const newList = cpList.filter((item) => item !== id);
      setSelectForwardIndex(newList);
    } else {
      cpList.push(id);
      setSelectForwardIndex(cpList);
    }
  };
  const handleClose = (id) => {
    const cpList = [...selectForwardIndex];
    if (cpList.includes(id)) {
      const newList = cpList.filter((item) => item !== id);
      setSelectForwardIndex(newList);
    } else {
      cpList.push(id);
      setSelectForwardIndex(cpList);
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog sx={styles.dialog} onClose={onClose} open={isOpen}>
      <DialogTitle sx={styles.dFlexColumn}>
        <Box />
        <Box sx={styles.dialogTitle}>
          <IconButton onClick={onClose} sx={styles.paddingReset}>
            <Typography variant="body2" sx={styles.doneActive}>
              Hủy
            </Typography>
          </IconButton>
          <Box sx={styles.textCenter}>
            <Typography variant="body1" color="initial" sx={styles.title}>
              Chuyển tiếp đến
            </Typography>
            <Typography
              variant="caption"
              sx={styles.textDefault}
            >{`${selectForwardIndex.length}`}</Typography>
          </Box>
          <IconButton disabled={isEmpty} onClick={handleDoneForwardDialog} sx={styles.paddingReset}>
            <Typography variant="body2" sx={isEmpty ? styles.doneDefault : styles.doneActive}>
              Xong
            </Typography>
          </IconButton>
        </Box>
        <Box>
          <Tabs
            value={value}
            variant="fullWidth"
            onChange={handleChange}
            TabIndicatorProps={{
              style: { transition: "none", boxShadow: "none" },
            }}
            sx={styles.tabList}
          >
            {tabList.map((tab, i) => (
              <Tab
                key={tab.id}
                label={
                  <Typography
                    variant="body2"
                    sx={value === i ? styles.textActive : styles.textDefault}
                  >
                    {tab.label}
                  </Typography>
                }
                sx={() => styles.tab(value, i)}
              />
            ))}
          </Tabs>
        </Box>
        <TextField
          placeholder="Tìm kiếm"
          InputProps={{
            startAdornment: (
              <InputAdornment sx={styles.searchIcon} position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            autoComplete: "off",
          }}
          sx={styles.search}
        />
      </DialogTitle>
      <Divider sx={styles.divider} />
      <Box sx={styles.contentContainer}>
        {selectForwardIndex.length !== 0 && (
          <Box sx={styles.boxShowSelected}>
            <Box sx={styles.overflowX}>
              {list.map((item) => (
                <Box key={item.id}>
                  {selectForwardIndex.includes(item.id) && (
                    <Box key={item.id} sx={styles.selectedImage}>
                      <Box
                        key={item.id}
                        sx={{ ...styles.avatarImageSelected, mr: 1 }}
                        component="img"
                        src={item.avatar}
                        alt="item.name"
                      />
                      <IconButton onClick={() => handleClose(item.id)} sx={styles.closeImageIcon}>
                        <CloseIcon sx={styles.iconW18} />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        )}
        <Box sx={styles.countSelectedBox}>
          <Typography variant="caption" color="initial" sx={styles.subTitle}>
            Gợi ý
          </Typography>
        </Box>
        <TabPanel value={value} sx={styles.tabPanel} index={0}>
          <Stack sx={styles.overflowY}>
            {list.map((item) => (
              <Box key={item.id} sx={styles.boxCheckBox} onChange={() => handleCheckBox(item.id)}>
                <Checkbox
                  label={
                    <Box sx={styles.checkBox}>
                      <Avatar src={defaultAvatar} srcSet={item.avatar} sx={styles.avatarImage} />
                      <Typography variant="caption" color="initial" sx={styles.label}>
                        {item.name}
                      </Typography>
                    </Box>
                  }
                  checked={Boolean(selectForwardIndex.includes(item.id))}
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                />
              </Box>
            ))}
          </Stack>
        </TabPanel>
      </Box>
    </Dialog>
  );
}
