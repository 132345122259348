export default {
  popper: {
    position: "absolute",
    zIndex: 1201,
  },
  paper: ({ borders }) => ({
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    minWidth: "250px",
    borderRadius: borders.borderRadius.xl,
  }),
  topItemContainer: ({ palette, borders }) => ({
    borderTopLeftRadius: borders.borderRadius.xl,
    borderTopRightRadius: borders.borderRadius.xl,
    display: "flex",
    justifyContent: "space-between",
    px: 1.5,
    py: 2.8,
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: palette.grey[100],
    },
  }),
  itemContainer: ({ palette }) => ({
    display: "flex",
    justifyContent: "space-between",
    px: 1.5,
    py: 2.8,
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: palette.grey[100],
    },
  }),
  bottomItemContainer: ({ palette, borders }) => ({
    borderBottomLeftRadius: borders.borderRadius.xl,
    borderBottomRightRadius: borders.borderRadius.xl,
    display: "flex",
    justifyContent: "space-between",
    px: 1.5,
    py: 2.8,
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: palette.grey[100],
    },
  }),
  item: ({ palette }) => ({
    color: palette.grey[750],
  }),
  itemErrorColor: ({ palette }) => ({
    color: palette.error.light,
  }),
  divider: ({ palette }) => ({
    my: 0,
    py: 0,
    backgroundColor: palette.grey[300],
  }),
};
