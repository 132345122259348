import {
  Box,
  Drawer,
  Typography,
  Divider,
  Switch,
  IconButton,
  Tab,
  Tabs,
  Grid,
  CircularProgress,
  Button,
} from "@mui/material";
import { useState } from "react";
import TabPanel from "components/TabPanel";
import defaultAvatar from "assets/images/default/default_avatar.png";
import styles from "assets/styles/pages/Chat/section/ChatInformation";
import { ReactComponent as Phone } from "assets/svg/icon/phone.svg";
import { ReactComponent as Ring } from "assets/svg/icon/ring.svg";
import { ReactComponent as AddMember } from "assets/svg/icon/add_member.svg";
import { ReactComponent as TrashIcon } from "assets/svg/icon/Icon_trash.svg";
import { ReactComponent as OutGroup } from "assets/svg/icon/out_group.svg";
import { ReactComponent as CloseIcon } from "assets/svg/icon/close_icon.svg";
import { ReactComponent as AddGroup } from "assets/svg/icon/add_group.svg";
import { ReactComponent as AddPrivateGroup } from "assets/svg/icon/add_private_group.svg";
import { ReactComponent as Participants } from "assets/svg/icon/participants.svg";
import { useTranslation } from "react-i18next";
import AddMemberChat from "../component/AddMemberChat";
import ListMember from "../component/ListMember";

const listImage = [];

function ChatInformation({
  handleToggleInformation,
  isOpenInformation,
  isMediumSize,
  handleToggleAddMemberChat,
  isOpenAddMemberChat,
  selectIndex,
  setSelectIndex,
  handleAddMemberChat,
  isOpenListMemberDialog,
  handleCloseListMember,
  users,
  detailGroupFetching,
  detailGroup,
  handleSetSearch,
  listSearchVID,
  handleOpenAddMemberChat,
  isAdmin,
  handleOpenListMember,
  handleChangePermissionGroup,
  handleRemoveUser,
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => setValue(newValue);

  if (detailGroupFetching) {
    return (
      <Drawer
        sx={(e) => styles.drawer(e, { isOpenInformation })}
        onClose={handleToggleInformation}
        open={isOpenInformation}
        anchor="right"
        variant={!isMediumSize ? "permanent" : "temporary"}
      >
        <Box
          sx={{
            height: "100%",
            ...styles.dFlexCenter,
          }}
        >
          <CircularProgress />
        </Box>
      </Drawer>
    );
  }

  return (
    <>
      <Drawer
        sx={(e) => styles.drawer(e, { isOpenInformation })}
        onClose={handleToggleInformation}
        open={isOpenInformation}
        anchor="right"
        variant={!isMediumSize ? "permanent" : "temporary"}
      >
        {detailGroup?.type === "group" && (
          <Box sx={styles.imageContainer}>
            <Box
              component="img"
              src={defaultAvatar}
              srcSet={detailGroup?.image}
              sx={styles.imgAvatar}
            />
            <IconButton sx={styles.groupCloseButton} onClick={handleToggleInformation}>
              <CloseIcon />
            </IconButton>
            <Box sx={styles.groupNameContainer}>
              <Typography variant="body1" color="white.main" sx={styles.groupName}>
                {detailGroup?.name}
              </Typography>
            </Box>
          </Box>
        )}
        {detailGroup?.type !== "group" && (
          <Box sx={styles.imageContainer}>
            <Box component="img" src={detailGroup?.image} sx={styles.imgAvatar} />
            <Box sx={styles.personalContainer}>
              <Box sx={styles.personalSection}>
                <Typography variant="body1" color="white.main" sx={styles.personalName}>
                  {detailGroup?.name}
                </Typography>
                <IconButton onClick={handleToggleInformation}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        )}

        <Box>
          {/* Phone */}
          {detailGroup?.type !== "group" && (
            <Box sx={styles.itemContainer}>
              <Box sx={styles.iconContainer}>
                <Phone />
              </Box>
              <Box sx={styles.informationContainer}>
                <Typography variant="body1" color="inherit.main" sx={styles.phone}>
                  +8498864233
                </Typography>
                <Typography variant="body2" sx={styles.vga}>
                  {`vID: ${users?.[0]?.id} `}
                </Typography>
              </Box>
            </Box>
          )}
          <Divider sx={styles.divider} />

          {/* Noti */}
          <Box sx={styles.itemContainerNotification}>
            <Box sx={styles.itemNotificationSection}>
              <Box sx={styles.iconContainer}>
                <Ring />
              </Box>
              <Typography variant="body1" color="inherit.main" sx={styles.textOnlyContainer}>
                {t("chat.chat_information.notification")}
              </Typography>
            </Box>
            {/* ---DISABLE--- */}
            <Switch defaultChecked disabled />
          </Box>
          <Divider sx={styles.divider} />

          {detailGroup?.type !== "group" && (
            <>
              {/* ---DISABLE--- */}
              <Button sx={styles.itemContainer} disabled>
                <Box sx={styles.iconContainer}>
                  <AddGroup />
                </Box>
                <Box sx={styles.newGroupContainer}>
                  <Typography variant="body1" color="inherit.main" sx={styles.newGroup}>
                    {t("chat.chat_information.public_group")}
                  </Typography>
                  <Typography variant="body1" color="inherit.main" sx={styles.newGroupName}>
                    {" "}
                    {detailGroup?.name}
                  </Typography>
                </Box>
              </Button>
              <Divider sx={styles.divider} />
              {/* ---DISABLE--- */}
              <Button sx={styles.itemContainer} disabled>
                <Box sx={styles.iconContainer}>
                  <AddPrivateGroup />
                </Box>
                <Box sx={styles.newGroupContainer}>
                  <Typography variant="body1" color="inherit.main" sx={styles.newGroup}>
                    {t("chat.chat_information.private_group")}
                  </Typography>
                  <Typography variant="body1" color="inherit.main" sx={styles.newGroupName}>
                    {" "}
                    {detailGroup?.name}
                  </Typography>
                </Box>
              </Button>
              <Divider sx={styles.divider} />
            </>
          )}

          {detailGroup?.type === "group" && (
            <>
              <Button sx={styles.itemDeleteContainer} onClick={handleOpenListMember}>
                <Box
                  sx={{
                    ...styles.iconContainer,
                    transform: "scale(1.5)",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Participants />
                </Box>
                <Typography variant="body1" sx={styles.textOnlyContainer}>
                  {t("chat.chat_information.participants")}
                </Typography>
              </Button>
              <Divider sx={styles.divider} />
              {isAdmin && (
                <Button sx={styles.itemAddGroupContainer} onClick={handleToggleAddMemberChat}>
                  <Box sx={styles.iconContainer}>
                    <AddMember />
                  </Box>
                  <Typography variant="body1" sx={styles.textOnlyContainer}>
                    {t("chat.chat_information.add_people_group")}
                  </Typography>
                </Button>
              )}
              <Divider sx={styles.divider} />
              {/* ---DISABLE--- */}
              <Button sx={styles.itemDeleteContainer} disabled>
                <Box sx={styles.iconContainer}>
                  <OutGroup />
                </Box>
                <Typography variant="body1" color="error.main" sx={styles.textOnlyContainer}>
                  {t("chat.chat_information.leave_and_delete_chat")}
                </Typography>
              </Button>
              <Divider sx={styles.divider} />
            </>
          )}

          {/* Delete Chat */}
          {/* ---DISABLE--- */}
          <Button sx={styles.itemDeleteContainer} disabled>
            <Box sx={styles.iconContainer}>
              <TrashIcon />
            </Box>
            <Typography variant="body1" color="error.main" sx={styles.textOnlyContainer}>
              {t("chat.chat_information.delete_chat")}
            </Typography>
          </Button>
          <Divider sx={styles.divider} />
        </Box>

        {/* Media */}
        <Divider sx={styles.dividerMedia} />
        <Box sx={styles.fullWidth}>
          <Box sx={styles.mediaTab}>
            <Tabs value={value} onChange={handleChange} sx={styles.tabList}>
              <Tab
                label={
                  <Typography
                    variant="body1"
                    sx={(e) => styles.textMedia(e, { isBlack: value !== 0, isBold: value === 0 })}
                  >
                    {t("chat.chat_information.media")}
                  </Typography>
                }
                sx={styles.tab}
              />
              <Tab
                label={
                  <Typography
                    variant="body1"
                    sx={(e) => styles.textMedia(e, { isBlack: value !== 1, isBold: value === 1 })}
                  >
                    {t("chat.chat_information.file")}
                  </Typography>
                }
                sx={styles.tab}
              />
              <Tab
                label={
                  <Typography
                    variant="body1"
                    sx={(e) => styles.textMedia(e, { isBlack: value !== 2, isBold: value === 2 })}
                  >
                    {t("chat.chat_information.link")}
                  </Typography>
                }
                sx={styles.tab}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} sx={styles.tabPanel} index={0}>
            <Grid container spacing={1}>
              {listImage.map((v, index) => (
                <Grid item xs={12 / 5} sm={12 / 7} md={4} lg={3} key={index}>
                  <Box component="img" src={v} sx={styles.imgMedia} />
                </Grid>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel value={value} sx={styles.tabPanel} index={1} />
          <TabPanel value={value} sx={styles.tabPanel} index={2} />
        </Box>
      </Drawer>

      <Box>
        <AddMemberChat
          isMediumSize={isMediumSize}
          handleToggleAddMemberChat={handleToggleAddMemberChat}
          isOpenAddMemberChat={isOpenAddMemberChat}
          selectIndex={selectIndex}
          setSelectIndex={setSelectIndex}
          handleAddMemberChat={handleAddMemberChat}
          group={detailGroup?.group}
          handleSetSearch={handleSetSearch}
          listSearchVID={listSearchVID}
        />
      </Box>
      <Box>
        <ListMember
          handleToggleAddMemberChat={handleToggleAddMemberChat}
          isMediumSize={isMediumSize}
          handleCloseListMember={handleCloseListMember}
          isOpenListMemberDialog={isOpenListMemberDialog}
          listMember={detailGroup?.users}
          handleOpenAddMemberChat={handleOpenAddMemberChat}
          group={detailGroup}
          isAdmin={isAdmin}
          handleChangePermissionGroup={handleChangePermissionGroup}
          handleRemoveUser={handleRemoveUser}
        />
      </Box>
    </>
  );
}
export default ChatInformation;
