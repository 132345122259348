import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Typography,
  Fade,
} from "@mui/material";
import TabPanel from "components/TabPanel";
import { useState } from "react";
import { ReactComponent as CloseIcon } from "assets/svg/icon/close.svg";
import { ReactComponent as Heart } from "assets/svg/icon/heart.svg";
import { ReactComponent as SmileIcon } from "assets/svg/icon/smile.svg";
import avatar from "assets/images/avatar.png";
import { TransitionGroup } from "react-transition-group";
import styles from "assets/styles/pages/Chat/section/ViewerDialog";
import { useTranslation } from "react-i18next";
import ViewerItem from "../component/ViewerItem";

const users = [
  {
    id: 1,
    avatar,
    name: "Nguyen Kelly",
    message: "Dạo này đi đánh đều không chú ?",
    time: "5 phút trước",
    emotion: "smile",
  },
  {
    id: 2,
    avatar,
    name: "Ngọc Còm",
    message: "Mai clb liên quan cái nhể",
    time: "5 phút trước",
    emotion: "heart",
  },
  {
    id: 3,
    avatar,
    name: "Ly Trang",
    message: "Mai clb liên quan cái nhể",
    time: "5 phút trước",
    emotion: "heart",
  },
  {
    id: 4,
    name: "Truong Thuy Giang",
    message: "Mai clb liên quan cái nhể",
    time: "4 phút trước",
    emotion: "heart",
  },
  { id: 5, avatar, name: "Ngọc Còm", message: "Mai clb liên quan cái nhể", time: "5 phút trước" },
  { id: 6, avatar, name: "Ly Trang", message: "Mai clb liên quan cái nhể", time: "5 phút trước" },
  { id: 7, avatar, name: "Ngọc Còm", message: "Mai clb liên quan cái nhể", time: "5 phút trước" },
  { id: 8, avatar, name: "Ly Trang", message: "Mai clb liên quan cái nhể", time: "5 phút trước" },
  { id: 9, avatar, name: "Ngọc Còm", message: "Mai clb liên quan cái nhể", time: "5 phút trước" },
  { id: 10, avatar, name: "Ly Trang", message: "Mai clb liên quan cái nhể", time: "5 phút trước" },
];

export default function SettingDialog({ onClose, isOpen }) {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog sx={styles.container} onClose={onClose} open={isOpen}>
      <Box sx={{ borderRadius: 0 }}>
        <DialogTitle sx={styles.DialogTittle}>
          <Box />
          <Typography
            color="initial"
            sx={({ typography }) => ({
              fontWeight: typography.fontWeightBold,
              fontSize: typography.size.md,
            })}
          >
            {t("chat.information_viewer.title")}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider sx={styles.DividerT} />
        <Box>
          <Tabs variant="scrollable" value={value} onChange={handleChange} sx={styles.Tabs}>
            <Tab
              sx={
                value === 0
                  ? { ...styles.Tab, ml: { xs: 2, sm: 3 } }
                  : { ...styles.TabBg, ml: { xs: 2, sm: 3 } }
              }
              label={
                <Typography
                  sx={value === 0 ? styles.TabViewed : styles.TabNoBorder}
                  variant="body2"
                >
                  {t("chat.information_viewer.watch")}
                </Typography>
              }
            />
            <Tab
              sx={value === 1 ? styles.Tab : styles.TabBg}
              label={
                <Box sx={styles.TabInfo}>
                  <SmileIcon style={styles.Icon} />
                  <Typography
                    sx={value === 1 ? styles.TabTitle : styles.fontFamily}
                    variant="caption"
                  >
                    1
                  </Typography>
                </Box>
              }
            />
            <Tab
              sx={value === 2 ? styles.Tab : styles.TabBg}
              label={
                <Box sx={styles.TabInfo}>
                  <Heart style={styles.Icon} />
                  <Typography
                    sx={value === 2 ? styles.TabTitle : styles.fontFamily}
                    variant="caption"
                  >
                    30
                  </Typography>
                </Box>
              }
            />
          </Tabs>
        </Box>
      </Box>
      <Divider sx={styles.DividerB} />
      <TabPanel sx={styles.TabPanel} value={value} index={0}>
        <TransitionGroup>
          {users.map((element) => (
            <Fade key={element.id}>
              <Box sx={styles.users}>
                <ViewerItem
                  information={{
                    avatar: element.avatar,
                    name: element.name,
                    message: element.message,
                    time: element.time,
                    emotion: element.emotion,
                  }}
                />
              </Box>
            </Fade>
          ))}
        </TransitionGroup>
      </TabPanel>
      <TabPanel sx={styles.TabPanel} value={value} index={1}>
        <TransitionGroup>
          {users
            .filter((item) => item.emotion === "smile")
            .map((element) => (
              <Fade key={element.id}>
                <Box sx={styles.users}>
                  <ViewerItem
                    information={{
                      avatar: element.avatar,
                      name: element.name,
                      message: element.message,
                      time: element.time,
                      emotion: element.emotion,
                    }}
                  />
                </Box>
              </Fade>
            ))}
        </TransitionGroup>
      </TabPanel>
      <TabPanel sx={styles.TabPanel} value={value} index={2}>
        <TransitionGroup>
          {users
            .filter((item) => item.emotion === "heart")
            .map((element) => (
              <Fade key={element.id}>
                <Box sx={styles.users}>
                  <ViewerItem
                    information={{
                      avatar: element.avatar,
                      name: element.name,
                      message: element.message,
                      time: element.time,
                      emotion: element.emotion,
                    }}
                  />
                </Box>
              </Fade>
            ))}
        </TransitionGroup>
      </TabPanel>
    </Dialog>
  );
}
