export default {
  popper: () => ({
    width: "20vw",
    minWidth: "300px",
    "& .MuiBackdrop-root": {
      width: "20vw",
      minWidth: "300px",
    },
  }),
  emotionBigIcon: {
    height: "32px",
  },
  emotionIcon: {
    height: "30px",
  },
  emotionPaper: ({ borders }) => ({
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
    py: 0.5,
    px: 1,
    justifyContent: "space-between",
    minWidth: "250px",
    borderRadius: borders.borderRadius.round,
    mb: 1.5,
    display: "flex",
    alignItems: "center",
  }),
  emotionButton: ({ isBackground }) => ({
    p: 0.5,
    borderRadius: "15px",
    backgroundColor: isBackground ? "#E9E8E8" : "transparent",
    "&:hover": {
      backgroundColor: isBackground ? "#E9E8E8" : "transparent",
    },
  }),
  paper: ({ borders }) => ({
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.3)",
    minWidth: "250px",
    borderRadius: borders.borderRadius.xl,
  }),
  topItemContainer: ({ palette, borders }) => ({
    borderTopLeftRadius: borders.borderRadius.xl,
    borderTopRightRadius: borders.borderRadius.xl,
    display: "flex",
    height: "44px",
    width: "100%",
    justifyContent: "flex-start",
    py: 1.5,
    px: 2,
    cursor: "pointer",
    alignItems: "center",
    "&:hover": {
      backgroundColor: palette.grey[100],
    },
  }),
  itemContainer: ({ palette }) => ({
    display: "flex",
    height: "44px",
    width: "100%",
    justifyContent: "flex-start",
    py: 1.5,
    px: 2,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: palette.grey[100],
    },
  }),
  bottomItemContainer: ({ palette, borders }) => ({
    borderBottomLeftRadius: borders.borderRadius.xl,
    borderBottomRightRadius: borders.borderRadius.xl,
    display: "flex",
    height: "44px",
    width: "100%",
    justifyContent: "flex-start",
    py: 1.5,
    px: 2,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: palette.grey[100],
    },
  }),
  item: ({ palette }) => ({
    color: palette.text.main,
    ml: 2,
  }),
  itemErrorColor: ({ palette }) => ({
    color: palette.error.light,
    ml: 2,
  }),
  divider: () => ({
    my: 0,
    py: 0,
    backgroundColor: "#3C3C434D",
  }),
  icon: {
    height: "18px",
  },
};
