import * as JSSIP from "jssip";
import { isEmpty } from "jssip/lib/Utils";
import { zip } from "utils/message";

class JsSip {
    constructor(host, port, username, password) {
        this.host = host
        this.port = port
        this.username = username
        this.password = password
    }

    start() {
        const socket = new JSSIP.WebSocketInterface(`ws://${this.host}:${this.port}`)
        this.ua = new JSSIP.UA({
            sockets: [socket],
            uri: `sip:${this.username}@${this.host}`,
            password: this.password,
            register: true,
        });
        this.ua.start();
        this.ua.on("connecting", () => {
            // do something on connecting
            console.log('UA "connecting" event')
        });
        this.ua.on("connected", () => {
            // do something on connected
            console.log('UA "connected" event')
        });
        this.ua.on("disconnected", () => {
            // do something on disconnected
            console.log('UA "disconnected" event')
        });
        this.ua.on("registered", (data) => {
            // do something on registered
            console.log('UA "registered" event', data)
        });
        this.ua.on("unregistered", () => {
            // do something on unregistered
            console.log('UA "unregistered" event')
        });
        this.ua.on("registrationFailed", () => {
            // do something on registrationFailed
            console.log('UA "registrationFailed" event')
        });
        this.ua.on("registrationExpiring", () => {
            // do something on registrationExpiring
            console.log('UA "registrationExpiring" event')
        });
    }

    newMessage(callback) {
        this.ua.on("newMessage", callback);
    }

    newOptions(callback) {
        this.ua.on("newOptions", callback);
    }

    sipEvent(callback) {
        this.ua.on("sipEvent", callback);
    }

    sendMessage(value, address = `sip:global@${this.host}`) {
        this.ua.sendMessage(address, zip(value))
    }

    call(target, options=null) {
        this.ua.call(target, options, ...rest)
    }

    stop() {
        if (!isEmpty(this.ua)) this.ua.stop()
    }

}

export default JsSip