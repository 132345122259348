import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import defaultAvatar from "assets/images/default/default_avatar.png";
import styles from "assets/styles/pages/Chat/section/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ReactComponent as InformationIcon } from "assets/svg/icon/information.svg";
import { ReactComponent as FillInformationIcon } from "assets/svg/icon/fill_information.svg";
import { ReactComponent as MoreVertIcon } from "assets/svg/icon/more_vert.svg";
import { ReactComponent as SearchIcon } from "assets/svg/icon/primary_search.svg";
import { useTranslation } from "react-i18next";
import { AdminNames } from "constant/chat";
import NotificationAddFriend from "../component/NotificationAddFriend";
import PinMessage from "../component/PinMessage";
import DeletePinMessageDialog from "../component/DeletePinMessageDialog";
import PinMessageDialog from "../component/PinMessageDialog";
import ChatActionSheet from "../component/ChatActionSheet";
import LoadingHeader from "../loading/Header";

function Header({
  isMobileSize,
  onBack,
  openSetting,
  anchor,
  handleOpenHeaderPopper,
  handleCloseHeaderPopper,
  handleToggleInformation,
  isAddFriend,
  isBlockFriend,
  handleAddFriend,
  isOpenInformation,
  openPinMessage,
  openDialogDeletePinMessage,
  messagePinContent,
  handleDeletePinMessage,
  handleOpenDeletePinMessage,
  openDialogPinMessage,
  handleDialogPinMessage,
  handleOpenListMember,
  detailGroup,
  listPin,
  handleScrollPin,
  isFetchingNewGroup,
  userInformation,
  handleRemoveGroupChat,
}) {
  const { t } = useTranslation();

  if (isFetchingNewGroup) return <LoadingHeader isOpenInformation={isOpenInformation} />;
  return (
    <AppBar sx={(e) => styles.appBar(e, { isOpenInformation })}>
      <Toolbar sx={styles.toolbar}>
        <Box sx={styles.informationContainer}>
          <Fade in={isMobileSize} unmountOnExit>
            <IconButton onClick={onBack} sx={styles.backButton}>
              <ArrowBackIcon style={styles.backIcon} />
            </IconButton>
          </Fade>
          <Avatar
            src={defaultAvatar}
            srcSet={detailGroup?.detail?.image || detailGroup?.group?.image}
            sx={styles.avatar}
          />
          <Box>
            <Typography variant="body1" color="initial" sx={styles.name}>
              {detailGroup?.detail?.name || detailGroup?.group?.name}
            </Typography>

            {!AdminNames.includes(detailGroup?.group?.name) && (
              <Typography
                onClick={detailGroup?.detail?.type === "group" ? handleOpenListMember : () => {}}
                variant="body2"
                sx={() => styles.onlineTime({ isGroup: detailGroup?.detail?.type === "group" })}
              >
                {detailGroup?.detail?.type === "group"
                  ? `${detailGroup?.detail?.total_member} ${t("chat.header.member")}`
                  : detailGroup?.detail?.users?.find(
                      (element) => +element.id !== +userInformation.id
                    )?.last_state}
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={styles.actionContainer}>
          {/* Search */}
          {/* ---DISABLE--- */}
          <IconButton disabled>
            <SearchIcon style={styles.headerIcon} />
          </IconButton>
          {/* Information */}
          <IconButton sx={styles.icon} onClick={handleToggleInformation}>
            {isOpenInformation ? (
              <FillInformationIcon style={styles.headerIcon} />
            ) : (
              <InformationIcon style={styles.headerIcon} />
            )}
          </IconButton>
          {/* Setting */}
          <IconButton onClick={handleOpenHeaderPopper}>
            <MoreVertIcon style={styles.headerIcon} />
          </IconButton>
          <ChatActionSheet
            handleRemoveGroupChat={() => handleRemoveGroupChat(detailGroup)}
            openSetting={openSetting}
            anchor={anchor}
            handleClose={handleCloseHeaderPopper}
          />
        </Box>
      </Toolbar>
      <PinMessage
        handleScrollPin={handleScrollPin}
        listPin={listPin}
        messagePinContent={messagePinContent}
        openPinMessage={openPinMessage}
        openDialogPinMessage={openDialogPinMessage}
        handleDialogPinMessage={handleDialogPinMessage}
        handleOpenDeletePinMessage={handleOpenDeletePinMessage}
      />
      <PinMessageDialog
        handleScrollPin={handleScrollPin}
        listPin={listPin}
        handleOpenDeletePinMessage={handleOpenDeletePinMessage}
        openDialogPinMessage={openDialogPinMessage}
        handleDialogPinMessage={handleDialogPinMessage}
      />
      <DeletePinMessageDialog
        pinContent={messagePinContent}
        handleOpenDeletePinMessage={handleOpenDeletePinMessage}
        openDialogDeletePinMessage={openDialogDeletePinMessage}
        handleDeletePinMessage={handleDeletePinMessage}
      />
      <NotificationAddFriend
        isBlockFriend={isBlockFriend}
        isAddFriend={isAddFriend}
        handleAddFriend={handleAddFriend}
      />
    </AppBar>
  );
}

export default Header;
