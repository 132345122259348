import { memo } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { Divider, Typography } from "@mui/material";
import styles from "assets/styles/pages/Chat/component/ListMemberAction";
import { getUserToken } from "utils/storage";
import { useTranslation } from "react-i18next";

function ListMemberAction({
  openPopperListMember,
  anchor,
  handleClose,
  member,
  group,
  handleChangePermissionGroup,
  handleRemoveUser,
}) {
  const { t } = useTranslation();
  const userInformation = getUserToken();
  return (
    <Popper
      placement="bottom-end"
      sx={styles.popper}
      open={openPopperListMember}
      anchorEl={anchor}
      transition
      disablePortal
      popperOptions={{ modifiers: [{ name: "offset", options: { offset: [0, -15] } }] }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper sx={styles.paper}>
            <ClickAwayListener disableReactTree onClickAway={handleClose}>
              <Box>
                {!group?.admin_ids.includes(userInformation?.id) ||
                  (userInformation?.id !== member?.id && (
                    <Box>
                      <Box sx={styles.topItemContainer}>
                        <Typography variant="body1" sx={styles.item}>
                          {t("chat.list_member_action.message")}
                        </Typography>
                      </Box>
                      <Divider sx={styles.divider} />
                      <Box sx={styles.itemContainer}>
                        <Typography variant="body1" sx={styles.item}>
                          {t("chat.list_member_action.call")}
                        </Typography>
                      </Box>
                      <Divider sx={styles.divider} />
                      <Box sx={styles.itemContainer}>
                        <Typography variant="body1" sx={styles.item}>
                          {t("chat.list_member_action.information")}
                        </Typography>
                      </Box>
                      <Divider sx={styles.divider} />
                      <Box
                        onClick={() => handleChangePermissionGroup(member?.id, group?.id)}
                        sx={styles.itemContainer}
                      >
                        <Typography variant="body1" sx={styles.item}>
                          {group?.admin_ids.includes(member?.id)
                            ? t("chat.list_member_action.remove_as_admin")
                            : t("chat.list_member_action.add_as_admin")}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                <Box
                  onClick={() => handleRemoveUser(member?.id, group?.id)}
                  sx={styles.bottomItemContainer}
                >
                  <Typography variant="body1" sx={styles.itemErrorColor}>
                    {group.admin_ids.includes(member?.id)
                      ? t("chat.list_member_action.leave_from_chat")
                      : t("chat.list_member_action.remove_from_chat")}
                  </Typography>
                </Box>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

ListMemberAction.defaultProps = {
  openSetting: false,
  anchor: "",
};

ListMemberAction.propTypes = {
  openSetting: PropTypes.bool,
  anchor: PropTypes.any,
  handleClose: PropTypes.func.isRequired,
};

export default memo(ListMemberAction);
