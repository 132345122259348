import PropTypes from "prop-types";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { memo, useState } from "react";
import defaultAvatar from "assets/images/default/default_avatar.png";
import { ReactComponent as DropdownIcon } from "assets/svg/icon/dropdown.svg";
import { ReactComponent as ContactIcon } from "assets/svg/icon/contact.svg";
import { ReactComponent as SettingIcon } from "assets/svg/icon/setting.svg";
import { ReactComponent as LogoutIcon } from "assets/svg/icon/logout.svg";
import styles from "assets/styles/pages/Chat/component/PersonalPopper";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

function PersonalPopper({
  isOpenPopper,
  anchor,
  handleClose,
  handleLogout,
  userInformation,
  handleOpenSettingDialog,
}) {
  const { t } = useTranslation();
  const [arrowRef, setArrowRef] = useState(null);
  return (
    <Popper
      placement="bottom-start"
      open={isOpenPopper}
      anchorEl={anchor}
      transition
      disablePortal
      sx={styles.popper}
      modifiers={[
        { name: "offset", options: { offset: [0, 10] } },
        {
          name: "flip",
          enabled: false,
          options: {
            altBoundary: true,
            rootBoundary: "viewport",
            padding: 8,
          },
        },
        {
          name: "preventOverflow",
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: false,
            rootBoundary: "viewport",
            padding: 8,
          },
        },
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper sx={styles.paper} ref={setArrowRef}>
            <ClickAwayListener disableReactTree onClickAway={handleClose}>
              <Box sx={styles.container}>
                <Box sx={styles.personalContainer} />
                <Box sx={styles.avatarContainer}>
                  <Avatar src={defaultAvatar} srcSet={userInformation.avatar} sx={styles.avatar} />
                  <Box>
                    <Typography variant="body1" color="initial" sx={styles.name}>
                      {userInformation?.username}
                    </Typography>
                    <Typography variant="body2" sx={styles.vga}>
                      vID: {userInformation?.id}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={styles.statusContainer}>
                  <Box sx={styles.statusFlexContainer}>
                    <Box sx={styles.statusIconBorder}>
                      <Box sx={styles.statusIcon} />
                    </Box>
                    <Typography sx={styles.status} variant="body2" color="inherit.main">
                      {t("chat.user_list.personal.action")}
                    </Typography>
                  </Box>
                  {/* ---DISABLE--- */}
                  <IconButton disabled>
                    <DropdownIcon />
                  </IconButton>
                </Box>
                <Divider sx={styles.divider} />

                <Box px={2}>
                  {/* ---DISABLE--- */}
                  <Button color="secondary" sx={styles.settingItemButton} disabled>
                    <Box sx={styles.settingItemWithMarginContainer}>
                      <ContactIcon style={styles.icon} />
                      <Typography sx={styles.settingItem} color="inherit.main">
                        {t("chat.user_list.personal.contacts")}
                      </Typography>
                    </Box>
                  </Button>

                  <Button
                    color="secondary"
                    sx={styles.settingItemButton}
                    onClick={() => {
                      handleOpenSettingDialog();
                      handleClose();
                    }}
                  >
                    <Box sx={styles.settingItemWithMarginContainer}>
                      <SettingIcon style={styles.icon} />
                      <Typography sx={styles.settingItem} color="inherit.main">
                        {t("chat.user_list.personal.setting")}
                      </Typography>
                    </Box>
                  </Button>

                  <Button color="secondary" sx={styles.settingItemButton} onClick={handleLogout}>
                    <Box sx={styles.settingItemWithMarginContainer}>
                      <LogoutIcon style={styles.icon} />
                      <Typography sx={styles.settingItem} color="inherit.main">
                        {t("chat.user_list.personal.sign_out")}
                      </Typography>
                    </Box>
                  </Button>

                  <Box sx={styles.descriptionContainer}>
                    <Typography sx={styles.description} variant="body2">
                      WGHN webZ 1.0.0
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

PersonalPopper.defaultProps = {
  isOpenPopper: false,
  anchor: "",
};

PersonalPopper.propTypes = {
  isOpenPopper: PropTypes.bool,
  anchor: PropTypes.any,
  handleClose: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default memo(PersonalPopper);
