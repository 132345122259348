import {
  informationWidth,
  lgInformationWidth,
  xlInformationWidth,
  xxlInformationWidth,
} from "constant/chat";

export default {
  drawer: ({ transitions, breakpoints }, { isOpenInformation }) => ({
    transition: transitions.create("width", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
    ...(!isOpenInformation && {
      width: 0,
      "& .MuiDrawer-paper": {
        width: 0,
      },
    }),
    ...(isOpenInformation && {
      width: informationWidth,
      "& .MuiDrawer-paper": {
        width: informationWidth,
      },
      [breakpoints.down("xxl")]: {
        width: xxlInformationWidth,
        "& .MuiDrawer-paper": {
          width: xxlInformationWidth,
        },
      },
      [breakpoints.down("xl")]: {
        width: xlInformationWidth,
        "& .MuiDrawer-paper": {
          width: xlInformationWidth,
        },
      },
      [breakpoints.down("lg")]: {
        width: lgInformationWidth,
        "& .MuiDrawer-paper": {
          width: lgInformationWidth,
        },
      },
      [breakpoints.down("md")]: {
        width: "100%",
        "& .MuiDrawer-paper": {
          width: "100%",
        },
      },
    }),
  }),
  dFlexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageContainer: {
    position: "relative",
  },
  imgAvatar: {
    width: "100%",
    height: "100%",
    minHeight: "160px",
    objectFit: "cover",
    maxHeight: "368px",
  },
  groupCloseButton: {
    position: "absolute",
    top: "4px",
    right: "4px",
  },
  groupNameContainer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)",
  },
  groupName: ({ typography }) => ({
    pb: 1.5,
    pl: 2,
    fontWeight: typography.fontWeightBold,
    fontFamily: "SF Pro Text, sans-serif",
    paddingTop: "90px",
  }),

  personalContainer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)",
  },
  personalSection: {
    pl: 2,
    pr: 1,
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "90px",
    alignItems: "flex-end",
  },
  personalName: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    fontFamily: "SF Pro Text, sans-serif",
    pb: 1.5,
  }),
  itemContainer: {
    display: "flex",
    px: 2,
    justifyContent: "flex-start",
    height: "56px",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F2F2F2",
    },
    "&:focus": {
      backgroundColor: "#F2F2F2",
    },
    width: "100%",
  },
  itemAddGroupContainer: {
    display: "flex",
    px: 2,
    height: "56px",
    justifyContent: "flex-start",
    width: "100%",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F2F2F2",
    },
    "&:focus": {
      backgroundColor: "#F2F2F2",
    },
  },
  informationContainer: {
    ml: 2.5,
  },
  iconContainer: {
    width: "33px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  phone: {
    pt: 0.25,
    fontFamily: "SF Pro Text, sans-serif",
  },
  vga: {
    fontFamily: "SF Pro Text, sans-serif",
    color: "#A1A1A1",
  },

  itemContainerNotification: {
    display: "flex",
    pl: 2,
    pr: 1,
    height: "56px",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "#F2F2F2",
    },
    "&:focus": {
      backgroundColor: "#F2F2F2",
    },
  },
  itemNotificationSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textOnlyContainer: {
    alignSelf: "center",
    fontFamily: "SF Pro Text, sans-serif",
    ml: 2.5,
  },
  newGroupContainer: {
    ml: 2.5,
  },
  newGroup: {
    fontFamily: "SF Pro Text, sans-serif",
    display: "inline",
  },
  newGroupName: ({ typography }) => ({
    fontFamily: "SF Pro Text, sans-serif",
    display: "inline",
    fontWeight: typography.fontWeightBold,
  }),
  itemDeleteContainer: {
    display: "flex",
    px: 2,
    height: "56px",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F2F2F2",
    },
    "&:focus": {
      backgroundColor: "#F2F2F2",
    },
  },

  divider: {
    my: 0,
    py: 0,
    opacity: "1",
    backgroundColor: "#DDDDDD",
  },
  fullWidth: {
    width: "100%",
  },
  dividerMedia: {
    // mt: "70px",
    mt: 0,
    mb: 0,
    pt: "10px",
    opacity: "1",
    backgroundColor: "#DDDDDD",
  },
  mediaTab: ({ palette }) => ({
    p: 0,
    width: "100%",
    backgroundColor: palette.background.paper,
  }),
  tabList: ({ palette }) => ({
    p: 0,
    backgroundColor: palette.background.paper,
    borderRadius: 0,
    borderBottom: 2.5,
    borderColor: "#DDDDDD",
    "& .MuiButtonBase-root": {
      borderRadius: 0,
    },
    "& .MuiTabs-indicator": {
      borderRadius: 0,
      boxShadow: "none",
      borderBottom: "transparent solid 5px !important",
      background:
        "linear-gradient(#fff 0 0) padding-box, linear-gradient(to right, #26BBFE, #1BC88B) border-box",
      "&:hover": {
        background:
          "linear-gradient(#f8ffff 0 0) padding-box, linear-gradient(to right, #26BBFE, #1BC88B) border-box",
      },
    },
  }),
  tabPanel: {
    p: 1,
  },
  textMedia: ({ palette, typography }, { isBlack, isBold }) => ({
    fontFamily: "SF Pro Text, sans-serif",
    background: `-webkit-linear-gradient(${isBlack ? palette.black.main : "#26BBFE"}, ${
      isBlack ? palette.black.main : "#1BC88B"
    })`,
    color: "#3D3D3D",

    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: isBold ? typography.fontWeightBold : typography.fontWeightRegular,
  }),
  tab: {
    px: 1.5,
    pt: 2,
    pb: 1.5,
  },
  imgMedia: () => ({
    cursor: "pointer",
    width: "100%",
    objectFit: "cover",
  }),
};
