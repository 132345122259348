export default {
  scrollBottomContainer: {
    position: "sticky",
    bottom: "32px",
    right: "32px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    zIndex: 20,
    alignSelf: "flex-end",
  },
  unreadButton: ({ borders }) => ({
    maxWidth: "176px",
    alignSelf: "flex-end",
    border: "transparent solid 2px !important",
    borderRadius: borders.borderRadius.round,
    background:
      "linear-gradient(#fff 0 0) padding-box, linear-gradient(to bottom, #26BBFE, #1BC88B) border-box",
    "&:hover": {
      background:
        "linear-gradient(#f8ffff 0 0) padding-box, linear-gradient(to bottom, #26BBFE, #1BC88B) border-box",
    },
    mb: 2,
    px: 1.5,
    boxShadow: "0px 0px 4.85714px rgba(0, 0, 0, 0.25)",
    alignItems: "center",
    justifyContent: "center",
  }),
  unread: {
    background: "-webkit-linear-gradient(#26BBFE 3.27%, #1BC88B 97.45%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    display: "flex",
    pr: 1,
  },
  scrollEndButton: {
    width: "40px",
    height: "40px",
    border: "transparent solid 2px !important",
    background:
      "linear-gradient(#fff 0 0) padding-box, linear-gradient(to bottom, #26BBFE, #1BC88B) border-box",
    "&:hover": {
      background:
        "linear-gradient(#f8ffff 0 0) padding-box, linear-gradient(to bottom, #26BBFE, #1BC88B) border-box",
    },
    alignSelf: "flex-end",
    display: "flex",
    justifyContent: "center",
    boxShadow: "0px 0px 4.85714px rgba(0, 0, 0, 0.25)",
  },
  badge: ({ palette }) => ({
    color: palette.white.main,
    height: "40px",
    width: "40px",
    "& .MuiBadge-badge": {
      position: "absolute",
      mr: "19px",
      backgroundColor: palette.error.main,
      width: "fit-content",
    },
  }),
  scrollEndIcon: {
    marginTop: "12px",
    height: "16px",
    width: "100%",
  },
};
