import { memo } from "react";
import { Avatar, Box, Grow, Typography, Link } from "@mui/material";
import { formatTimeSecond } from "utils/date";
import { isEmpty } from "utils/validate";
import { ReactComponent as SendedIcon } from "assets/svg/icon/sended.svg";
import { ReactComponent as SendIcon } from "assets/svg/icon/send.svg";
import { ReactComponent as PendingIcon } from "assets/svg/icon/pending.svg";
import { ReactComponent as ReplyIcon } from "assets/svg/icon/reply.svg";
import { ReactComponent as LikeIcon } from "assets/svg/icon/emotion_like.svg";
import { ReactComponent as DislikeIcon } from "assets/svg/icon/emotion_dislike.svg";
import { ReactComponent as SadIcon } from "assets/svg/icon/emotion_sad.svg";
import { ReactComponent as FunIcon } from "assets/svg/icon/emotion_fun.svg";
import { ReactComponent as LoveIcon } from "assets/svg/icon/sub_emotion_love.svg";
import defaultAvatar from "assets/images/default/default_avatar.png";
import styles from "assets/styles/pages/Chat/component/MessageNormal";
import { urlRegex } from "constant/regex";
import { MESSAGE_STATE } from "constant/chat";

function MessageNormal({
  idMessage,
  isOwner,
  value,
  isDeleted,
  handleOpenPopper,
  replyName,
  replyMessage,
  emotion,
  handleOpenViewerDialog,
  isShowAvatar,
}) {
  return (
    <Box className={`id-message-${idMessage}`} sx={styles.container}>
      <Box sx={() => styles.section({ isOwner })}>
        {!isOwner && (
          <Box sx={styles.avatarContainer}>
            <Avatar
              src={defaultAvatar}
              srcSet={value?.user?.avatar}
              sx={() => styles.avatar({ isShowAvatar })}
            />
          </Box>
        )}
        <Box
          onContextMenu={(e) => (isDeleted ? () => {} : handleOpenPopper(e, isOwner))}
          data-id={idMessage}
          sx={(e) => styles.messageSection(e, { isLink: urlRegex.exec(value?.text) })}
        >
          <Box
            sx={(e) =>
              styles.messageContainer(e, {
                isOwner,
                isReply: !replyMessage && !replyName,
                isDisplayUsername: value?.group?.type === "group",
              })
            }
          >
            {value?.group?.type === "group" && !isOwner && (
              <Box sx={styles.nameContainer}>
                <Typography color="initial" variant="body1" sx={styles.username}>
                  {value?.user?.username} -
                </Typography>
                <Typography color="initial" variant="body1" sx={styles.userID}>
                  {value?.user?.id}
                </Typography>
              </Box>
            )}
            {/* reply */}
            {!isEmpty(replyName) && !isEmpty(replyMessage) && (
              <Box
                sx={(e) =>
                  styles.replyContainer(e, {
                    isOwner,
                    isDisplayUsername: value?.group?.type === "group",
                  })
                }
              >
                <Box sx={(e) => styles.replyIconContainer(e, { isOwner })}>
                  <ReplyIcon style={styles.replyIcon} />
                </Box>
                <Box sx={styles.replyInformationContainer}>
                  <Typography
                    color="initial"
                    variant="body1"
                    sx={(e) => styles.replyName(e, { isOwner })}
                  >
                    {replyName}
                  </Typography>
                  <Typography
                    color="initial"
                    variant="body1"
                    sx={(e) => styles.replyMessage(e, { isOwner })}
                  >
                    {replyMessage}
                  </Typography>
                </Box>
              </Box>
            )}
            {/* reply */}
            {/* message */}
            {!urlRegex.exec(value?.text) && (
              <Box
                sx={(e) => styles.message(e, { replyMessage, replyName, isOwner })}
                className="message"
                dangerouslySetInnerHTML={{ __html: value?.text }}
              />
            )}
            {/* message */}
            {/* link */}
            {urlRegex.exec(value?.text) && (
              <Box sx={() => styles.linkContainer({ isOwner })}>
                <Box component={Link} target="_blank" href={urlRegex.exec(value?.text)[0]}>
                  <Box sx={styles.imageContainer}>
                    <Box
                      component="img"
                      src={value?.url_preview?.image}
                      sx={(e) => styles.image(e, { isOwner })}
                    />
                  </Box>
                  <Box sx={styles.linkContentContainer}>
                    <Typography sx={(e) => styles.linkTitle(e, { isOwner })} variant="body1">
                      {value?.url_preview?.description}
                    </Typography>
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: value?.text?.replace(
                          urlRegex.exec(value?.text)[0],
                          `<a href="${urlRegex.exec(value?.text)[0]}" target="_blank">${
                            urlRegex.exec(value?.text)[0]
                          }</a>`
                        ),
                      }}
                      sx={(e) => styles.link(e, { isOwner })}
                    />
                  </Box>
                </Box>
              </Box>
            )}
            {/* link */}
            <Box sx={styles.messageTimeContainer}>
              {/* emoji */}
              <Grow in={!isEmpty(emotion)}>
                <Box
                  sx={(e) => styles.emotionContainer(e, { isOwner })}
                  onClick={handleOpenViewerDialog}
                >
                  {emotion?.love > 0 && <LoveIcon style={styles.emotionIcon} />}
                  {emotion?.last === "fun" && <FunIcon style={styles.emotionIcon} />}
                  {emotion?.last === "like" && <LikeIcon style={styles.emotionIcon} />}
                  {emotion?.last === "dislike" && <DislikeIcon style={styles.emotionIcon} />}
                  {emotion?.last === "sad" && <SadIcon style={styles.emotionIcon} />}
                  <Typography sx={(e) => styles.emotionCount(e, { isOwner })} variant="body2">
                    {!isEmpty(emotion) &&
                      Object.keys(emotion)
                        .filter((c) => c !== "last")
                        .reduce((a, c) => a + emotion[c], 0)}
                  </Typography>
                </Box>
              </Grow>
              {/* emoji */}
              {/* send time */}
              <Typography sx={(e) => styles.messageTime(e, { isOwner })} variant="body2">
                {formatTimeSecond(value?.createdAt)}
              </Typography>
              {/* send time */}
              {/* send icon */}
              {isOwner && (
                <>
                  {value.state === MESSAGE_STATE.PENDING && (
                    <PendingIcon style={styles.messagePendingIcon} />
                  )}
                  {value.state === MESSAGE_STATE.SENDING && (
                    <SendIcon style={styles.messageSendedIcon} />
                  )}
                  {value.state === MESSAGE_STATE.SENDED && (
                    <SendedIcon style={styles.messageSendedIcon} />
                  )}
                </>
              )}
              {/* send icon */}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default memo(MessageNormal);
