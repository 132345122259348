const SendTextMessage = ({
  message_text,
  message_time,
  message_uuid,
  message_user_id,
  message_group_id,
}) => ({
  t: "text",
  f: "messenge",
  v: "v2.0",
  via: "WS",
  debug: true,
  d: message_text,
  time: message_time,
  i: message_uuid,
  u: message_user_id,
  g: message_group_id,
});

const SaveTextMessage = ({
  message_uuid,
  message_time,
  message_group_id,
  message_text,
  message_user,
  message_user_id,
  message_state,
  message_url_preview,
}) => ({
  kind: "text",
  message_type: "messenge",
  client_id: message_uuid,
  count_down_time: -1,
  createdAt: message_time,
  group_id: message_group_id,
  text: message_text,
  user: message_user,
  user_id: message_user_id,
  _id: message_uuid,
  state: message_state,
  system: 0,
  image: [],
  url_preview: message_url_preview,
  // group : {},
  // id: 0,
  // redis_request_id: uuid(),
  // server_user_id: msg.u,
});

const SendImageMessage = ({
  message_image,
  message_time,
  message_uuid,
  message_user_id,
  message_group_id,
}) => ({
  t: "image",
  f: "messenge",
  v: "v2.0",
  via: "WS",
  debug: true,
  d: message_image,
  time: message_time,
  i: message_uuid,
  u: message_user_id,
  g: message_group_id,
});

const SaveImageMessage = ({
  message_uuid,
  message_time,
  message_group_id,
  message_user,
  message_user_id,
  message_state,
  message_image,
}) => ({
  kind: "image",
  message_type: "messenge",
  client_id: message_uuid,
  count_down_time: -1,
  createdAt: message_time,
  group_id: message_group_id,
  user: message_user,
  user_id: message_user_id,
  _id: message_uuid,
  state: message_state,
  system: 0,
  image: message_image,
  // group : {},
  // id: 0,
  // redis_request_id: uuid(),
  // server_user_id: msg.u,
});

const SendVideoMessage = ({
  message_video,
  message_time,
  message_uuid,
  message_user_id,
  message_group_id,
}) => ({
  t: "video",
  f: "messenge",
  v: "v2.0",
  via: "WS",
  debug: true,
  d: {
    ...message_video,
    full: {
      url: message_video.url,
      createdAt: message_time,
    },
  },
  time: message_time,
  i: message_uuid,
  u: message_user_id,
  g: message_group_id,
});

const SaveVideoMessage = ({
  message_uuid,
  message_time,
  message_group_id,
  message_user,
  message_user_id,
  message_state,
  message_video,
}) => ({
  kind: "video",
  message_type: "messenge",
  client_id: message_uuid,
  count_down_time: -1,
  createdAt: message_time,
  group_id: message_group_id,
  user: message_user,
  user_id: message_user_id,
  _id: message_uuid,
  state: message_state,
  system: 0,
  video: {
    ...message_video,
    full: {
      url: message_video.url,
      createdAt: message_time,
    },
  },
  // group : {},
  // id: 0,
  // redis_request_id: uuid(),
  // server_user_id: msg.u,
});

const SendFileMessage = ({
  message_other,
  message_time,
  message_uuid,
  message_user_id,
  message_group_id,
}) => ({
  t: "file",
  f: "messenge",
  v: "v2.0",
  via: "WS",
  debug: true,
  d: {
    ...message_other,
    url_link: message_other.url,
    uri: message_other.url,
    createdAt: message_time,
  },
  time: message_time,
  i: message_uuid,
  u: message_user_id,
  g: message_group_id,
});

const SaveFileMessage = ({
  message_uuid,
  message_time,
  message_group_id,
  message_user,
  message_user_id,
  message_state,
  message_other,
}) => ({
  kind: "file",
  message_type: "messenge",
  client_id: message_uuid,
  count_down_time: -1,
  createdAt: message_time,
  group_id: message_group_id,
  user: message_user,
  user_id: message_user_id,
  _id: message_uuid,
  state: message_state,
  system: 0,
  file: {
    ...message_other,
    url_link: message_other.url,
    uri: message_other.url,
    createdAt: message_time,
  },
  // group : {},
  // id: 0,
  // redis_request_id: uuid(),
  // server_user_id: msg.u,
});

export {
  SendTextMessage,
  SaveTextMessage,
  SendImageMessage,
  SaveImageMessage,
  SendVideoMessage,
  SaveVideoMessage,
  SendFileMessage,
  SaveFileMessage,
};
