const IS_PRODUCT = process.env.REACT_APP_IS_PRODUCT;
const BASE_URL = process.env.REACT_APP_BASE_URL;
// KEY
const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY;
const LANGUAGE_KEY = process.env.REACT_APP_LANGUAGE_KEY;
const PRIVATE_KEY = process.env.REACT_APP_PRIVATE_KEY;
// TOKEN
const SEND_OTP_TOKEN = process.env.REACT_APP_OTP_TOKEN;
// IDB
const CHAT_DATABASE = process.env.REACT_APP_CHAT_DB;
const MESSAGE_TABLE = process.env.REACT_APP_MESSAGE_TABLE;
const GROUP_TABLE = process.env.REACT_APP_GROUP_TABLE;
// PUBLIC
const UPLOAD_URL = `${process.env.REACT_APP_BASE_URL}/static/uploads`;

export {
  TOKEN_KEY,
  BASE_URL,
  IS_PRODUCT,
  LANGUAGE_KEY,
  PRIVATE_KEY,
  SEND_OTP_TOKEN,
  CHAT_DATABASE,
  MESSAGE_TABLE,
  GROUP_TABLE,
  UPLOAD_URL,
};
