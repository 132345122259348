import { Box, Fade } from "@mui/material";
import breakpoints from "assets/theme/base/breakpoints";
import { useEffect, useRef, useState } from "react";
import styles from "assets/styles/pages/Chat";
import { isEmpty } from "utils/validate";
import copy from "copy-to-clipboard";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { useNavigate, useParams } from "react-router-dom";
import { getUserToken, setUserToken } from "utils/storage";
import { useDispatch } from "react-redux";
import { updateRoute } from "redux/actions/routeAction";
import routes from "routes/routes";
import { GetLastMessage, compareGroup, compareGroupDetail, unzip } from "utils/message";
import { jsSip } from "config/config";
import { v5 as uuid, v4 } from "uuid";
import { useMutation, useQuery } from "react-query";
import { decodeID, encodeID } from "utils/hex";
import { searchVGA, websitePreview } from "api/global";
import {
  getListGroupChat,
  groupChatDetail,
  AddMember,
  messageOffline,
  uploadResource,
  ChangePermission,
  RemoveMember,
  addGroupChat,
} from "api/chat";
import useDelaySearch from "utils/useDelaySearch";
import { addPin, getListPin, removePin } from "api/pinChat";
import { decrypt, encrypt } from "utils/cipher";
import {
  MESSAGE_STATE,
  ACTION_TYPE,
  AdminNames,
  MESSAGE_TYPE,
  SPECIAL_FILE_TYPE,
} from "constant/chat";
import GroupIDB from "IndexedDB/GroupIDB";
import MessageIDB from "IndexedDB/MessageIDB";
import {
  SaveFileMessage,
  SaveImageMessage,
  SaveTextMessage,
  SaveVideoMessage,
  SendFileMessage,
  SendImageMessage,
  SendTextMessage,
  SendVideoMessage,
} from "utils/format";
import { UPLOAD_URL } from "config/keys";
import { urlRegex } from "constant/regex";
// import logo from "assets/images/icon/logo.ico";
// import { useTranslation } from "react-i18next";
import { overrideDefault } from "utils/utils";

import JsSip from "middleware/JsSip";
import Header from "./section/Header";
import UserList from "./section/UserList";
import SettingDialog from "./section/SettingDialog";
import Footer from "./section/Footer";
import ContentChat from "./section/ContentChat";
import ChatInformation from "./section/ChatInformation";

// JsSIP.debug.enable("JsSIP:*");

export default function Chat() {
  // ##########################################################################
  // #                              STATE                                     #
  // ##########################################################################
  // global
  const userInformation = getUserToken();
  const [isMobileSize, setMobileSize] = useState(false);
  const [isMediumSize, setMediumSize] = useState(false);
  const [sip, setSip] = useState();
  const { vID } = useParams();
  const [messageLimit, setMessageLimit] = useState(20);
  const [isFetchingMessage, setFetchingMessage] = useState(false);
  const [searchUser, setSearchUser] = useState("");
  const [filterGroup, setFilterGroup] = useState([]);
  // user list
  const [isAddGroup, setAddGroup] = useState(false);
  const [isAddConversation, setAddConversation] = useState(false);
  const [isPersonalPopperOpen, setPersonalPopperOpen] = useState(false);
  const [personalAnchor, setPersonalAnchor] = useState(null);
  const [isSelectGroup, setSelectGroup] = useState(false);
  const [searchGolferKey, setSearchGolferKey] = useState("");
  // setting dialog
  const [isOpenSettingDialog, setOpenSettingDialog] = useState(false);
  // header
  const [openSetting, setOpenSetting] = useState(false);
  const [anchor, setAnchorEl] = useState(null);
  const [isAddFriend, setAddFriend] = useState(false);
  const [isBlockFriend, setBlockFriend] = useState(false);
  const [openPinMessage, setOpenPinMessage] = useState(false);
  const [messagePinContent, setMessagePinContent] = useState({});
  const [openDialogDeletePinMessage, setOpenDialogDeletePinMessage] = useState(false);
  const [openDialogPinMessage, setOpenDialogPinMessage] = useState(false);
  // chat
  const chatRef = useRef();
  const [isDragOverlay, setDragOverlay] = useState(false);
  const [openMessageSetting, setOpenMessageSetting] = useState(false);
  const [messageAnchor, setMessageAnchorEl] = useState(null);
  const [isSelectOwner, setSelectOwner] = useState(false);
  const [openViewerDialog, setOpenViewerDialog] = useState(false);
  const [openReplyMessage, setOpenReplyMessage] = useState(false);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [messageReplyContent, setMessageReplyContent] = useState({});
  const [unreadMessage, setUnreadMessage] = useState(0);
  const [isScrollEnd, setScrollEnd] = useState(false);
  // footer
  const textRef = useRef();
  const sendRef = useRef();
  const [isVoice, setIsVoice] = useState(false);
  const [time, setTime] = useState("00:00");
  const [images, setImages] = useState([]);
  const [prevText, setPrevText] = useState("");
  const [isBlock, setIsBlock] = useState(false);
  const [openEditMessage, setOpenEditMessage] = useState(false);
  // information
  const [isOpenInformation, setOpenInformation] = useState(false);
  // chat-info-add-member-chat
  const [selectIndex, setSelectIndex] = useState([]);
  const [isOpenAddMemberChat, setOpenAddMemberChat] = useState(false);
  // forward-dialog
  const [selectForwardIndex, setSelectForwardIndex] = useState([]);
  const [isOpenForwardDialog, setOpenForwardDialog] = useState(false);
  // add-member
  const [listSearchVID, setListSearchVID] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  // list-member
  const [isOpenListMemberDialog, setOpenListMemberDialog] = useState(false);
  // ##########################################################################
  // #                              HOOK                                      #
  // ##########################################################################
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { t } = useTranslation();
  // voice
  const { startRecording, stopRecording, togglePauseResume, recordingBlob, isRecording, isPaused } =
    useAudioRecorder();
  const listPin = useQuery("pins", () => getListPin({ id: +decodeID(vID) }));
  // query
  const groups = useQuery("groups-idb", () => GroupIDB.getAll(userInformation?.id, "user_id"), {
    select: (res) =>
      res
        .map((value) => ({
          ...value,
          group: decrypt(value.group),
          detail: decrypt(value.detail),
          last_message: {
            ...(decrypt(value.last_message) || {}),
            text: GetLastMessage(decrypt(value.last_message)?.text),
          },
        }))
        .sort((a, b) => a.index - b.index),
  });
  const groupDetail = useQuery("group-idb", () => GroupIDB.get(+decodeID(vID), "_id"), {
    select: (res) => ({
      ...res,
      group: decrypt(res.group),
      detail: decrypt(res.detail),
      last_message: {
        ...(decrypt(res.last_message) || {}),
        text: GetLastMessage(decrypt(res.last_message)?.text),
      },
    }),
  });
  const listMessage = useQuery(
    "message-idb",
    () => MessageIDB.getLimit(+decodeID(vID), "group_id", { limit: messageLimit }),
    {
      onSuccess: () => setFetchingMessage(false),
      select: (res) => res.map((value) => ({ ...value, message: decrypt(value.message) })),
    }
  );
  const groupQuery = useQuery("group", () => getListGroupChat(), {
    onSuccess: async (res) => {
      if (res.error !== 0 || !res.groups) return;
      const groupByUser = await GroupIDB.getAll(userInformation.id, "user_id");
      const removeGroups = await Promise.all(
        groupByUser.map(async (value) => {
          if ((await GroupIDB.getAll(value._id, "_id"))?.length > 1) return value.id;
          if (!res?.groups?.map((v) => v.id).includes(value._id)) return value.id;
          return null;
        })
      );
      await Promise.all(removeGroups.filter((id) => id).map((id) => GroupIDB.remove(id)));
      await Promise.all(
        res?.groups?.map(async (value) => {
          const idbGroup = await GroupIDB.get(value.id, "_id");
          const saveGroup = {
            ...(idbGroup || {}),
            _id: value.id,
            user_id: userInformation?.id,
            group: encrypt(value),
          };
          if (!idbGroup) return GroupIDB.add(saveGroup);
          if (compareGroup(decrypt(idbGroup?.group), value)) return GroupIDB.put(saveGroup);
        })
      );
      await Promise.all(
        res.groups.map(async (value, index) => {
          const idbGroup = await GroupIDB.get(value.id, "_id");
          const saveGroup = {
            ...(idbGroup || {}),
            index,
          };
          return GroupIDB.put(saveGroup);
        })
      );
      groups.refetch();
    },
  });
  const groupDetailQuery = useQuery("detail", () => groupChatDetail(+decodeID(vID)), {
    onSuccess: async (res) => {
      if (res.error !== 0) return;
      const idbGroup = await GroupIDB.get(res?.group?.id, "_id");
      if (isEmpty(idbGroup)) return groups.refetch();
      if (compareGroupDetail(groupDetail?.data, res.group))
        await GroupIDB.put({
          ...(idbGroup || {}),
          _id: res?.group?.id,
          user_id: userInformation?.id,
          detail: encrypt(res?.group),
        });
      groupDetail.refetch();
      groups.refetch();
    },
  });
  const listMessageQuery = useQuery("offline-message", () => messageOffline(), {
    // onSuccess: async (res) => {
    //   if (res.error !== 0) return;
    //   Object.keys(res?.groups)?.forEach((key) => {
    //     MessageIDB.addMany(
    //       res?.groups[key].map((value) => ({
    //         _id: value._id,
    //         group_id: +value.group_id,
    //         message: encrypt(value),
    //         date: value.createdAt,
    //       }))
    //     );
    //   });
    //   listMessage.refetch();
    // },
  });
  // mutation
  const uploadImageMutate = useMutation((data) => uploadResource(data));
  const searchGolferMutate = useMutation((key) => searchVGA({ vga_id: key }));
  const addGroupMutate = useMutation((value) => addGroupChat(value));
  // JSSIP
  useEffect(() => {
    const SipUA = new JsSip(
      jsSip.host,
      jsSip.port,
      userInformation?.sip?.user_name_vphone,
      userInformation?.sip?.password_vphone
    );
    SipUA.start();
    SipUA.newMessage(async (props) => {
      const group_id = +decodeID(window.location.pathname.replace("/t/", ""));
      const unzipValue = unzip(props?.request?.body) || {};
      if (unzipValue.content !== "ping")
        // eslint-disable-next-line no-console
        console.log("_________________________", unzipValue, props.originator);
      if (props.originator === "remote") {
        if (unzipValue.type === "signal") return;
        if (unzipValue.type === "update_point") return;
        // change message state
        if (unzipValue.type === "message_update") {
          const idbMessage = await MessageIDB.get(unzipValue._id, "_id");
          if (!idbMessage) return;
          await MessageIDB.put({
            ...idbMessage,
            message: encrypt({
              ...decrypt(idbMessage?.message || {}),
              state: MESSAGE_STATE.SENDED,
              id: unzipValue?.update?.id,
            }),
            state: MESSAGE_STATE.SENDED,
          });
          listMessage.refetch();
          return;
        }
        // action
        if (unzipValue.message_type === "action") {
          switch (+unzipValue.action) {
            case ACTION_TYPE.DELETE_GROUP:
              // Luôn chỉ nhận được event sau khi tải lại trang (kết nối socket) ?
              // groupQuery.refetch().then(async () => {
              //   let location = await GroupIDB.get(0, "index");
              //   if (+location._id === +unzipValue.group_id)
              //     location = await GroupIDB.get(1, "index");
              //   navigate(`/t/${encodeID(location._id)}`);
              // }); break;
              return;
            case ACTION_TYPE.UPDATE_GROUP:
              // Luôn chỉ nhận được event sau khi tải lại trang (kết nối socket) ?
              // groupQuery.refetch();
              // break;
              return;
            default:
              return;
          }
        }
        // message
        if (unzipValue.message_type === "message") {
          if (+unzipValue.user_id === +userInformation.id) return; // && +unzipValue.system === 0 luôn luôn gọi lại khi refresh ?
          const listGroup = await GroupIDB.getAll(userInformation?.id, "user_id");
          if (!listGroup?.map((v) => +v._id).includes(+unzipValue.group_id))
            groupQuery.refetch().then(() => {
              setTimeout(async () => {
                const idbGroup = await GroupIDB.get(unzipValue.group_id, "_id");
                if (!isEmpty(idbGroup)) {
                  await GroupIDB.put({
                    ...idbGroup,
                    last_message: encrypt({
                      ...unzipValue,
                      isUnread: group_id !== unzipValue.group_id,
                    }),
                  });
                  groups.refetch();
                }
              }, 500);
            });
          let last_message = "";
          switch (unzipValue.kind) {
            case MESSAGE_TYPE.TEXT:
              {
                const message_url_preview = urlRegex.test(unzipValue.text)
                  ? await websitePreview({ uri: urlRegex.exec(unzipValue.text)[0] })
                  : null;
                if (message_url_preview) unzipValue.url_preview = message_url_preview;
                last_message = encrypt({
                  ...unzipValue,
                  isUnread: group_id !== unzipValue.group_id,
                });
              }
              break;
            case MESSAGE_TYPE.IMAGE:
              last_message = encrypt({
                ...unzipValue,
                text: "system_message.image_message_text",
                isUnread: group_id !== unzipValue.group_id,
              });
              break;
            case MESSAGE_TYPE.VIDEO:
              last_message = encrypt({
                ...unzipValue,
                text: "system_message.video_message_text",
                isUnread: group_id !== unzipValue.group_id,
              });
              break;
            case MESSAGE_TYPE.FILE:
              last_message = encrypt({
                ...unzipValue,
                text: "system_message.file_message_text",
                isUnread: group_id !== unzipValue.group_id,
              });
              break;
            default:
              return;
          }
          // save message
          await MessageIDB.add({
            _id: unzipValue._id,
            group_id: +unzipValue.group_id,
            message: encrypt(unzipValue),
            date: unzipValue.createdAt,
          });
          // set last message text to group
          const idbGroup = await GroupIDB.get(unzipValue.group_id, "_id");
          if (!isEmpty(idbGroup)) {
            await GroupIDB.put({ ...idbGroup, last_message });
            groups.refetch();
          }
          // check bold unread and render
          if (+unzipValue.group_id === group_id)
            if (chatRef.current?.scrollTop < -100) setUnreadMessage((prev) => prev + 1);
          // alway bottom refetch
          if (+unzipValue.group_id === group_id) {
            if (messageLimit < 30) setMessageLimit((prev) => prev + 1);
            else listMessage.refetch();
          }
        }
      }
      if (props.originator === "local") {
        if (unzipValue.message_type === "message") {
          // change message state
          const idbMessage = await MessageIDB.get(unzipValue.i, "_id");
          if (!idbMessage) return;
          await MessageIDB.put({
            ...idbMessage,
            message: encrypt({
              ...decrypt(idbMessage.message || {}),
              state: MESSAGE_STATE.SENDING,
            }),
            state: MESSAGE_STATE.SENDING,
          });
          listMessage.refetch();
        }
      }
    });
    setSip(SipUA);

    const notificationOnBlur = async () => {
      // const listNotify = {};
      // if (Notification.permission !== "granted") return;
      // // const startTime = Date.now();
      // const muteList = (await GroupIDB.getAll(userInformation?.id, "user_id"))
      //   .map((value) => decrypt(value.group))
      //   ?.filter((value) => value?.is_mute)
      //   .map((value) => value.id);
      // SipUA.ua.on("newMessage", async (props) => {
      //   if (props.originator !== "remote") return;
      //   const unzipValue = unzip(props?.request?.body) || {};
      //   if (+unzipValue.system !== 0) return;
      //   if (unzipValue.message_type !== "message") return;
      //   if (muteList.includes(unzipValue.group_id)) return;
      //   const newTime = Date.now();
      //   let notification_message = "";
      //   if (unzipValue.kind === "text") notification_message = unzipValue?.text;
      //   if (unzipValue.kind === "image")
      //     notification_message = t("system_message.image_message_text");
      //   if (
      //     !listNotify[unzipValue.group_id]?.last_time ||
      //     Math.ceil((newTime - listNotify[unzipValue.group_id].last_time) / 1000) > 5
      //   ) {
      //     listNotify[unzipValue.group_id] = {
      //       last_time: newTime,
      //       count: listNotify[unzipValue.group_id]?.count
      //         ? listNotify[unzipValue.group_id].count + 1
      //         : 1,
      //       notify: [...(listNotify[unzipValue?.group_id]?.notify || []), unzipValue],
      //     };
      //     // eslint-disable-next-line no-new
      //     new Notification(unzipValue?.user?.username, {
      //       body: notification_message,
      //       icon: unzipValue?.user?.avatar || logo,
      //     });
      //   } else {
      //     listNotify[unzipValue.group_id] = {
      //       ...(listNotify[unzipValue.group_id] || []),
      //       notify: [...(listNotify[unzipValue?.group_id]?.notify || []), unzipValue],
      //     };
      //   }
      // });
    };

    const ping = setInterval(() => {
      SipUA.sendMessage({
        type: "action",
        user_id: userInformation.id,
        content: "ping",
        account: userInformation.id,
        msg_id: uuid(`${Math.floor(Math.random() * 1000)}`, v4()),
      });
    }, 10000);

    window.addEventListener("blur", notificationOnBlur);
    return () => {
      window.removeEventListener("blur", notificationOnBlur);
      clearInterval(ping);
      SipUA.stop();
    };
  }, []);

  useEffect(() => {
    if (listMessage?.isFetching) {
      listMessage.remove();
      listMessage.refetch();
    } else {
      listMessage.refetch();
    }
  }, [messageLimit]);

  useEffect(() => {
    listPin.refetch();
    textRef?.current?.focus();
    setTimeout(() => chatRef?.current?.scroll({ top: 0, behavior: "smooth" }), 700);
    if (groupDetail?.isFetching) {
      groupDetail.remove();
      groupDetail.refetch();
    } else {
      groupDetail.refetch();
    }
    if (messageLimit !== 20) setMessageLimit(20);
    else if (listMessage?.isFetching) {
      listMessage.remove();
      listMessage.refetch();
    } else {
      listMessage.refetch();
    }

    if (groupDetailQuery.isFetching) {
      groupDetailQuery.remove();
      groupDetailQuery.refetch();
    } else {
      groupDetailQuery.refetch();
    }
    async function checkReadMessage() {
      const idbGroup = await GroupIDB.get(+decodeID(vID), "_id");
      if (decrypt(idbGroup?.last_message)?.isUnread) {
        await GroupIDB.put({
          ...idbGroup,
          last_message: encrypt({
            ...decrypt(idbGroup?.last_message || {}),
            isUnread: false,
          }),
        });
        groups.refetch();
      }
    }
    checkReadMessage();
  }, [vID]);
  // other
  const delaySearchValue = useDelaySearch(searchValue, 1000);
  const searchList = useQuery("search-vga", () => searchVGA({ vga_id: delaySearchValue }), {
    onSuccess: (res) => {
      if (res.error === 0) {
        if (res && searchValue.length > 0) setListSearchVID(res.res);
      }
    },
  });
  const delaySearchGolfer = useDelaySearch(searchGolferKey, 1000);
  useEffect(() => {
    searchGolferMutate.mutateAsync(delaySearchGolfer);
  }, [delaySearchGolfer]);
  useEffect(() => {
    searchList.refetch();
  }, [delaySearchValue]);
  const delaySearchUser = useDelaySearch(searchUser, 1000);
  useEffect(() => {
    async function FilterUser() {
      const idbGroups = (await GroupIDB.getAll(userInformation?.id, "user_id")).map((value) => ({
        ...value,
        group: decrypt(value.group),
        detail: decrypt(value.detail),
        last_message: decrypt(value.last_message),
      }));
      const listChat = idbGroups?.filter((value) => {
        if (value?.group?.name?.toLowerCase().includes(delaySearchUser.toLowerCase())) return true;
        return false;
      });
      setFilterGroup(listChat || []);
    }
    FilterUser();
  }, [delaySearchUser]);
  useEffect(() => {
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.md) {
        setMobileSize(true);
        setMediumSize(false);
      } else if (
        window.innerWidth >= breakpoints.values.md &&
        window.innerWidth < breakpoints.values.xxl
      ) {
        setMediumSize(true);
        setMobileSize(false);
      } else {
        setMobileSize(false);
        setMediumSize(false);
      }
    }
    async function onChatScroll() {
      if (chatRef.current?.scrollTop < -100) {
        if (
          chatRef.current.scrollHeight ===
          chatRef.current.offsetHeight - chatRef.current.scrollTop
        ) {
          const limit = await MessageIDB.count();
          setMessageLimit((prev) => {
            if (prev < limit) {
              setFetchingMessage(true);
              return prev + 10;
            }
            return prev;
          });
        }
        setScrollEnd(true);
      } else {
        setUnreadMessage(0);
        setScrollEnd(false);
      }
    }
    function onChatScrollMobile() {
      setTimeout(async () => {
        if (chatRef.current?.scrollTop < -100) {
          if (
            chatRef.current.scrollHeight ===
            chatRef.current.offsetHeight - chatRef.current.scrollTop
          ) {
            const limit = await MessageIDB.count();
            setMessageLimit((prev) => {
              if (prev < limit) {
                setFetchingMessage(true);
                return prev + 10;
              }
              return prev;
            });
          }
          setScrollEnd(true);
        } else {
          setUnreadMessage(0);
          setScrollEnd(false);
        }
      }, 800);
    }
    const requestPermission = async () => {
      if (Notification.permission === "default") Notification.requestPermission();
    };
    // Add Event Listener
    window.addEventListener("resize", displayMobileNavbar);
    chatRef?.current?.addEventListener("scroll", onChatScroll);
    window.addEventListener("touchmove", onChatScrollMobile);
    // First call
    requestPermission();
    displayMobileNavbar();
    return () => {
      window.removeEventListener("resize", displayMobileNavbar);
      chatRef?.current?.removeEventListener("scroll", onChatScroll);
      window.removeEventListener("touchmove", onChatScrollMobile);
    };
  }, []);

  useEffect(() => {
    if (recordingBlob) {
      // eslint-disable-next-line no-console
      console.log(recordingBlob);
    }
  }, [recordingBlob]);
  useEffect(() => {
    if (listPin?.data?.list?.length > 0) setOpenPinMessage(true);
    else {
      setOpenPinMessage(false);
      setOpenDialogPinMessage(false);
    }
  }, [listPin]);

  // ##########################################################################
  // #                            FUNCTION                                    #
  // ##########################################################################
  // setting dialog
  const handleLogout = () => {
    setUserToken({});
    dispatch(updateRoute(routes.filter((element) => !element.require_token)));
    navigate("/login");
  };
  const handleOpenSettingDialog = () => setOpenSettingDialog(true);
  const handleCloseSettingDialog = () => setOpenSettingDialog(false);
  // header
  const handleOpenHeaderPopper = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenSetting((prevOpen) => !prevOpen);
  };
  const handleAddFriend = () => {
    setAddFriend(false);
    setBlockFriend(false);
  };
  const handleCloseHeaderPopper = () => setOpenSetting(false);
  const handleDialogPinMessage = () => {
    setOpenDialogPinMessage((prevOpen) => !prevOpen);
  };
  const handleOpenDeletePinMessage = async (element) => {
    setOpenDialogDeletePinMessage((prevOpen) => !prevOpen);
    if (element.id) setMessagePinContent(element);
  };
  const handleDeletePinMessage = async () => {
    const res = await removePin({ id: +decodeID(vID), message_id: messagePinContent.id });
    if (res.error === 0) {
      listPin.refetch();
      setOpenDialogDeletePinMessage((prevOpen) => !prevOpen);
    }
  };

  // user list
  const handleOpenAddGroup = () => {
    setAddGroup(true);
    setSearchGolferKey("");
  };
  const handleCloseAddGroup = (callback) => {
    setAddGroup(false);
    setSearchGolferKey("");
    if (callback && typeof callback === "function") callback();
  };
  const handleOpenConversation = () => setAddConversation(true);
  const handleCloseConversation = () => {
    setSearchGolferKey("");
    setAddConversation(false);
  };
  const addNewGroupChat = (storeData, callback) => {
    addGroupMutate
      .mutateAsync(storeData)
      .then((res) => {
        if (+res.error !== 0) return;
        groupQuery.refetch().then(() => {
          setTimeout(() => {
            setSelectGroup(true);
            navigate(`/t/${encodeID(res?.group?.id)}`);
          }, 500);
        });
      })
      .finally(() => {
        handleCloseConversation();
        if (callback && typeof callback === "function") callback();
      });
  };
  const handleAddSingleChat = async (element) => {
    if (isEmpty(searchGolferKey)) {
      navigate(`/t/${encodeID(element.group_id)}`);
      setSelectGroup(true);
      handleCloseConversation();
    } else {
      let storeData = {};
      storeData = {
        type: "single",
        member_users: [userInformation?.id, element.id],
        is_private: 0,
      };
      addNewGroupChat(storeData);
    }
  };
  const handleAddGroupChat = async (element, callback) => {
    const res = await fetch(element.image);
    const img = await res.blob();
    const formData = new FormData();
    formData.append("image", img, "image.png");
    const imageURL = await uploadImageMutate.mutateAsync(formData);
    let storeData = {};
    storeData = {
      type: "group",
      name: element.name,
      admin_ids: [userInformation?.id],
      member_users: element.members,
      image: `${UPLOAD_URL}/${imageURL.path}`,
      is_private: 0,
    };
    addNewGroupChat(storeData, callback);
  };
  const handleSearchUserList = (e) => setSearchUser(e?.target?.value);
  const handleSearchUser = (e) => setSearchGolferKey(e?.target?.value);
  const closeAllDialogInformation = () => {
    setOpenAddMemberChat(false);
    setOpenListMemberDialog(false);
  };
  const handleSelect = (element) => {
    if (element?.id) {
      setSelectGroup(true);
      const vId = encodeID(element.id);
      navigate(`/t/${vId}`);
    } else {
      setSelectGroup(false);
    }
    if (element?.is_block) setIsBlock(true);
    else setIsBlock(false);
    closeAllDialogInformation();
  };
  const handleOpenPersonalPopper = (event) => {
    setPersonalAnchor(event.currentTarget);
    setPersonalPopperOpen((prevOpen) => !prevOpen);
  };
  const handleClosePersonalPopper = () => setPersonalPopperOpen(false);
  // chat
  const handleRemoveGroupChat = (detail) => {
    sip.sendMessage({
      type: "action",
      group_id: detail._id,
      account: userInformation.id,
      value: ACTION_TYPE.DELETE_GROUP,
      msg_id: uuid(`${Math.floor(Math.random() * 1000)}`, v4()),
    });
    groupQuery.refetch().then(async () => {
      let location = await GroupIDB.get(0, "index");
      if (+location._id === +detail._id) location = await GroupIDB.get(1, "index");
      navigate(`/t/${encodeID(location._id)}`);
    });
    handleCloseHeaderPopper();
  };
  const handleDropFiles = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOverlay(false);
    if (!e.dataTransfer.files) return;
    const { files } = e.dataTransfer;
    const message_files = await Promise.all(
      Object.keys(files).map(async (key, index) => {
        const formData = new FormData();
        let saveFile = {};
        if (files[key].type.includes(SPECIAL_FILE_TYPE.IMAGE)) {
          const image = new Image();
          image.src = window.URL.createObjectURL(files[key]);
          await image.decode();
          saveFile = {
            index,
            // url_local: null,
            // url_original: null,
            width: image.width,
            height: image.height,
            createdAt: Math.floor(Date.now() / 1000),
            //
            fileType: "image",
          };
        } else if (files[key].type.includes(SPECIAL_FILE_TYPE.VIDEO)) {
          // eslint-disable-next-line no-undef
          // const video = new Image();
          // video.src = window.URL.createObjectURL(files[key]);
          // await video.decode();
          saveFile = {
            index,
            // full: {
            //   url: "ph://7DA9EBA3-1152-4510-A556-219E91EB0460/L0/001",
            //   createdAt: Math.floor(Date.now() / 1000),
            // },
            _id: uuid(`${Math.floor(Math.random() * 1000)}`, v4()),
            // url_local: null,
            // url_original: null,
            // width: video.width,
            // height: video.height,
            //
            fileType: "video",
          };
        } else {
          saveFile = {
            index,
            _id: uuid(`${Math.floor(Math.random() * 1000)}`, v4()),
            typeFile: e.dataTransfer.files[key].type,
            name: e.dataTransfer.files[key].name,
            size: e.dataTransfer.files[key].size,
            // url_local: null,
            // url_original: null,
            // width: video.width,
            // height: video.height,
            //
            fileType: "file",
          };
        }

        formData.append("image", files[key]);
        return uploadImageMutate
          .mutateAsync(formData)
          .then((value) => ({ ...saveFile, url: `${UPLOAD_URL}/${value.path}` }));
      })
    );
    // MESSAGE IMAGE
    const message_image = message_files
      .filter((v) => v.fileType === "image")
      .sort((a, b) => a.index - b.index)
      .map((v, i) => {
        const img = v;
        delete img.fileType;
        return { ...img, index: i };
      });
    const message_video = message_files
      .filter((v) => v.fileType === "video")
      .sort((a, b) => a.index - b.index)
      .map((v, i) => {
        const img = v;
        delete img.fileType;
        return { ...img, index: i };
      });
    const message_other = message_files
      .filter((v) => v.fileType === "file")
      .sort((a, b) => a.index - b.index)
      .map((v, i) => {
        const img = v;
        delete img.fileType;
        return { ...img, index: i };
      });
    if (!isEmpty(message_image)) {
      const message_time = Math.floor(Date.now() / 1000);
      const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
      const message_user_id = userInformation.id;
      const message_group_id = +decodeID(window.location.pathname.replace("/t/", ""));
      const message_state = MESSAGE_STATE.PENDING;
      const message_user = {
        id: userInformation.id,
        avatar: userInformation.avatar,
        user_name: userInformation.user_name,
      };
      const sendImage = SendImageMessage({
        message_image,
        message_group_id,
        message_time,
        message_user_id,
        message_uuid,
      });
      const saveImage = SaveImageMessage({
        message_group_id,
        message_state,
        message_image,
        message_time,
        message_user,
        message_user_id,
        message_uuid,
      });
      await MessageIDB.add({
        _id: message_uuid,
        group_id: message_group_id,
        message: encrypt(saveImage),
        date: message_time * 1000,
        state: message_state,
      });
      if (messageLimit < 30) setMessageLimit((prev) => prev + 1);
      else listMessage.refetch();
      // send message
      sip.sendMessage(sendImage);
      // save last message
      const idbGroup = await GroupIDB.get(message_group_id, "_id");
      if (!isEmpty(idbGroup))
        await GroupIDB.put({
          ...idbGroup,
          last_message: encrypt({
            ...saveImage,
            text: "system_message.image_message_text",
            isUnread: false,
          }),
        });
      groups.refetch();
      if (chatRef.current?.scrollTop < -100)
        chatRef?.current?.scroll({ top: 0, behavior: "smooth" });
    }
    // MESSAGE VIDEO
    if (!isEmpty(message_video)) {
      message_video.forEach(async (value) => {
        const message_time = Math.floor(Date.now() / 1000);
        const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
        const message_user_id = userInformation.id;
        const message_group_id = +decodeID(window.location.pathname.replace("/t/", ""));
        const message_state = MESSAGE_STATE.PENDING;
        const message_user = {
          id: userInformation.id,
          avatar: userInformation.avatar,
          user_name: userInformation.user_name,
        };
        const sendVideo = SendVideoMessage({
          message_video: value,
          message_group_id,
          message_time,
          message_user_id,
          message_uuid,
        });
        const saveVideo = SaveVideoMessage({
          message_group_id,
          message_state,
          message_video: value,
          message_time,
          message_user,
          message_user_id,
          message_uuid,
        });
        await MessageIDB.add({
          _id: message_uuid,
          group_id: message_group_id,
          message: encrypt(saveVideo),
          date: message_time * 1000,
          state: message_state,
        });
        if (messageLimit < 30) setMessageLimit((prev) => prev + 1);
        else listMessage.refetch();
        // send message
        sip.sendMessage(sendVideo);
        // save last message
        const idbGroup = await GroupIDB.get(message_group_id, "_id");
        if (!isEmpty(idbGroup))
          await GroupIDB.put({
            ...idbGroup,
            last_message: encrypt({
              ...saveVideo,
              text: "system_message.video_message_text",
              isUnread: false,
            }),
          });
        if (chatRef.current?.scrollTop < -100)
          chatRef?.current?.scroll({ top: 0, behavior: "smooth" });
        groups.refetch();
      });
    }
    // MESSAGE FILE
    if (!isEmpty(message_other)) {
      message_other.forEach(async (value) => {
        const message_time = Math.floor(Date.now() / 1000);
        const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
        const message_user_id = userInformation.id;
        const message_group_id = +decodeID(window.location.pathname.replace("/t/", ""));
        const message_state = MESSAGE_STATE.PENDING;
        const message_user = {
          id: userInformation.id,
          avatar: userInformation.avatar,
          user_name: userInformation.user_name,
        };
        const sendFile = SendFileMessage({
          message_other: value,
          message_group_id,
          message_time,
          message_user_id,
          message_uuid,
        });
        const saveFile = SaveFileMessage({
          message_group_id,
          message_state,
          message_other: value,
          message_time,
          message_user,
          message_user_id,
          message_uuid,
        });
        await MessageIDB.add({
          _id: message_uuid,
          group_id: message_group_id,
          message: encrypt(saveFile),
          date: message_time * 1000,
          state: message_state,
        });
        if (messageLimit < 30) setMessageLimit((prev) => prev + 1);
        else listMessage.refetch();
        // send message
        sip.sendMessage(sendFile);
        // save last message
        const idbGroup = await GroupIDB.get(message_group_id, "_id");
        if (!isEmpty(idbGroup))
          await GroupIDB.put({
            ...idbGroup,
            last_message: encrypt({
              ...saveFile,
              text: "system_message.file_message_text",
              isUnread: false,
            }),
          });
        if (chatRef.current?.scrollTop < -100)
          setTimeout(() => chatRef?.current?.scroll({ top: 0, behavior: "smooth" }), 100);
        groups.refetch();
      });
    }
    // eslint-disable-next-line no-param-reassign
    e.target.value = "";
  };
  const handleDragEnterFiles = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOverlay(true);
  };
  const handleDragLeaveFiles = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOverlay(false);
  };
  const handleMessageClose = () => {
    setMouseX(0);
    setMouseY(0);
    setOpenMessageSetting(false);
  };
  const handleOpenMessagePopper = (event, isOwner) => {
    event.stopPropagation();
    setMouseX(event.clientX);
    setMouseY(event.clientY);
    setSelectOwner(isOwner);
    event.preventDefault();
    setMessageAnchorEl(event.currentTarget);
    setOpenMessageSetting(true);
  };
  const handleViewerClose = () => setOpenViewerDialog(false);
  const handleOpenViewerDialog = () => {
    setOpenViewerDialog(true);
    setOpenMessageSetting(false);
  };
  const handleCopyToClipboard = () => {
    const messageText = messageAnchor.getElementsByClassName("message")[0]?.innerText;
    if (!isEmpty(messageText)) copy(messageText);
    handleMessageClose();
  };
  const handleSelectText = () => handleMessageClose();
  const handleSend = async () => {
    if (openReplyMessage) setOpenReplyMessage(false);
    // send voice
    if (isVoice) {
      stopRecording();
      setIsVoice(false);
      setTime("00:00");
      return;
    }
    // send message
    if (textRef?.current?.value?.trim() !== "") {
      const message_text = textRef?.current?.value?.trim();
      const message_time = Math.floor(Date.now() / 1000);
      const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
      const message_user_id = userInformation.id;
      const message_group_id = +decodeID(window.location.pathname.replace("/t/", ""));
      const message_state = MESSAGE_STATE.PENDING;
      const message_url_preview = urlRegex.test(message_text)
        ? await websitePreview({ uri: urlRegex.exec(message_text)[0] })
        : null;

      const message_user = {
        id: userInformation.id,
        avatar: userInformation.avatar,
        user_name: userInformation.user_name,
      };
      const sendMessage = SendTextMessage({
        message_text,
        message_group_id,
        message_time,
        message_user_id,
        message_uuid,
      });
      const saveMessage = SaveTextMessage({
        message_group_id,
        message_state,
        message_text,
        message_time,
        message_user,
        message_user_id,
        message_uuid,
        message_url_preview,
      });
      await MessageIDB.add({
        _id: message_uuid,
        group_id: message_group_id,
        message: encrypt(saveMessage),
        date: message_time * 1000,
        state: message_state,
      });
      if (messageLimit < 30) setMessageLimit((prev) => prev + 1);
      else listMessage.refetch();
      sip.sendMessage(sendMessage);
      const idbGroup = await GroupIDB.get(message_group_id, "_id");
      if (!isEmpty(idbGroup))
        await GroupIDB.put({
          ...idbGroup,
          last_message: encrypt({ ...saveMessage, isUnread: false }),
        });
      groups.refetch();
      if (chatRef.current?.scrollTop < -100)
        chatRef?.current?.scroll({ top: 0, behavior: "smooth" });
    }
    // send image
    if (images.length > 0) {
      if (isEmpty(images)) return;
      const message_image = await Promise.all(
        Object.keys(images).map(async (key, index) => {
          const formData = new FormData();
          const image = new Image();
          image.src = window.URL.createObjectURL(images[key]);
          await image.decode();
          formData.append("image", images[key]);
          return uploadImageMutate.mutateAsync(formData).then((value) => ({
            index,
            url_local: null,
            url_original: null,
            width: image.width,
            height: image.height,
            createdAt: Math.floor(Date.now() / 1000),
            url: `${UPLOAD_URL}/${value.path}`,
          }));
        })
      );
      const message_time = Math.floor(Date.now() / 1000);
      const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
      const message_user_id = userInformation.id;
      const message_group_id = +decodeID(window.location.pathname.replace("/t/", ""));
      const message_state = MESSAGE_STATE.PENDING;
      const message_user = {
        id: userInformation.id,
        avatar: userInformation.avatar,
        user_name: userInformation.user_name,
      };
      const sendImage = SendImageMessage({
        message_image,
        message_group_id,
        message_time,
        message_user_id,
        message_uuid,
      });
      const saveImage = SaveImageMessage({
        message_group_id,
        message_state,
        message_image,
        message_time,
        message_user,
        message_user_id,
        message_uuid,
      });
      await MessageIDB.add({
        _id: message_uuid,
        group_id: message_group_id,
        message: encrypt(saveImage),
        date: message_time * 1000,
        state: message_state,
      });
      if (messageLimit < 30) setMessageLimit((prev) => prev + 1);
      else listMessage.refetch();
      sip.sendMessage(sendImage);
      const idbGroup = await GroupIDB.get(message_group_id, "_id");
      if (!isEmpty(idbGroup))
        await GroupIDB.put({
          ...idbGroup,
          last_message: encrypt({
            ...saveImage,
            text: "system_message.image_message_text",
            isUnread: false,
          }),
        });
      groups.refetch();
      setImages([]);
      if (chatRef.current?.scrollTop < -100)
        chatRef?.current?.scroll({ top: 0, behavior: "smooth" });
    }
    // reset
    if (textRef?.current) textRef.current.value = "";
    textRef.current.focus();
  };
  // forward dialog
  const handleCloseForwardDialog = () => {
    setSelectForwardIndex([]);
    setOpenForwardDialog(false);
  };
  const handleOpenForwardDialog = () => {
    setOpenForwardDialog(true);
    handleMessageClose();
  };
  const handleDoneForwardDialog = () => {
    if (selectForwardIndex.length > 0) {
      setOpenForwardDialog(false);
      setSelectForwardIndex([]);
    }
  };
  const handleOpenPinMessage = async () => {
    const res = await addPin({
      uid: getUserToken().id,
      id: +decodeID(vID),
      message_id: messageAnchor?.dataset?.id,
    });
    if (res.error === 0) {
      listPin.refetch();
    }
    handleMessageClose();
  };
  const handleScrollPin = (id) => {
    const scrollPin = document.getElementsByClassName(`id-message-${id}`)[0];
    if (scrollPin) {
      scrollPin.style.backgroundColor = "#DDF7F9";
      scrollPin.scrollIntoView({ behavior: "instant", block: "center", inline: "nearest" });
      setOpenDialogPinMessage(false);
      setTimeout(() => {
        scrollPin.style.backgroundColor = "#ffff";
      }, 1700);
    }
  };
  // list-member-dialog
  const handleOpenListMember = () => {
    setOpenInformation(true);
    setOpenListMemberDialog(true);
    setOpenAddMemberChat(false);
  };
  const handleCloseListMember = () => {
    setOpenListMemberDialog(false);
  };
  // footer
  const handleUploadImage = async (e) => {
    if (isEmpty(e?.target?.files)) return;
    const message_files = await Promise.all(
      Object.keys(e.target.files).map(async (key, index) => {
        const formData = new FormData();
        let saveFile = {};
        if (e.target.files[key].type.includes(SPECIAL_FILE_TYPE.IMAGE)) {
          const image = new Image();
          image.src = window.URL.createObjectURL(e.target.files[key]);
          await image.decode();
          saveFile = {
            index,
            // url_local: null,
            // url_original: null,
            width: image.width,
            height: image.height,
            createdAt: Math.floor(Date.now() / 1000),
            //
            fileType: "image",
          };
        } else if (e.target.files[key].type.includes(SPECIAL_FILE_TYPE.VIDEO)) {
          // eslint-disable-next-line no-undef
          // const video = new Image();
          // video.src = window.URL.createObjectURL(e.target.files[key]);
          // await video.decode();
          saveFile = {
            index,
            // full: {
            //   url: "ph://7DA9EBA3-1152-4510-A556-219E91EB0460/L0/001",
            //   createdAt: Math.floor(Date.now() / 1000),
            // },
            _id: uuid(`${Math.floor(Math.random() * 1000)}`, v4()),
            // url_local: null,
            // url_original: null,
            // width: video.width,
            // height: video.height,
            //
            fileType: "video",
          };
        } else {
          saveFile = {
            index,
            _id: uuid(`${Math.floor(Math.random() * 1000)}`, v4()),
            typeFile: e.target.files[key].type,
            name: e.target.files[key].name,
            size: e.target.files[key].size,
            // url_local: null,
            // url_original: null,
            // width: video.width,
            // height: video.height,
            //
            fileType: "file",
          };
        }

        formData.append("image", e.target.files[key]);
        return uploadImageMutate
          .mutateAsync(formData)
          .then((value) => ({ ...saveFile, url: `${UPLOAD_URL}/${value.path}` }));
      })
    );

    // MESSAGE IMAGE
    const message_image = message_files
      .filter((v) => v.fileType === "image")
      .sort((a, b) => a.index - b.index)
      .map((v, i) => {
        const img = v;
        delete img.fileType;
        return { ...img, index: i };
      });
    const message_video = message_files
      .filter((v) => v.fileType === "video")
      .sort((a, b) => a.index - b.index)
      .map((v, i) => {
        const img = v;
        delete img.fileType;
        return { ...img, index: i };
      });
    const message_other = message_files
      .filter((v) => v.fileType === "file")
      .sort((a, b) => a.index - b.index)
      .map((v, i) => {
        const img = v;
        delete img.fileType;
        return { ...img, index: i };
      });

    if (!isEmpty(message_image)) {
      const message_time = Math.floor(Date.now() / 1000);
      const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
      const message_user_id = userInformation.id;
      const message_group_id = +decodeID(window.location.pathname.replace("/t/", ""));
      const message_state = MESSAGE_STATE.PENDING;
      const message_user = {
        id: userInformation.id,
        avatar: userInformation.avatar,
        user_name: userInformation.user_name,
      };
      const sendImage = SendImageMessage({
        message_image,
        message_group_id,
        message_time,
        message_user_id,
        message_uuid,
      });
      const saveImage = SaveImageMessage({
        message_group_id,
        message_state,
        message_image,
        message_time,
        message_user,
        message_user_id,
        message_uuid,
      });
      await MessageIDB.add({
        _id: message_uuid,
        group_id: message_group_id,
        message: encrypt(saveImage),
        date: message_time * 1000,
        state: message_state,
      });
      if (messageLimit < 30) setMessageLimit((prev) => prev + 1);
      else listMessage.refetch();
      // send message
      sip.sendMessage(sendImage);
      // save last message
      const idbGroup = await GroupIDB.get(message_group_id, "_id");
      if (!isEmpty(idbGroup))
        await GroupIDB.put({
          ...idbGroup,
          last_message: encrypt({
            ...saveImage,
            text: "system_message.image_message_text",
            isUnread: false,
          }),
        });
      groups.refetch();
      if (chatRef.current?.scrollTop < -100)
        chatRef?.current?.scroll({ top: 0, behavior: "smooth" });
    }
    // MESSAGE VIDEO
    if (!isEmpty(message_video)) {
      message_video.forEach(async (value) => {
        const message_time = Math.floor(Date.now() / 1000);
        const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
        const message_user_id = userInformation.id;
        const message_group_id = +decodeID(window.location.pathname.replace("/t/", ""));
        const message_state = MESSAGE_STATE.PENDING;
        const message_user = {
          id: userInformation.id,
          avatar: userInformation.avatar,
          user_name: userInformation.user_name,
        };
        const sendVideo = SendVideoMessage({
          message_video: value,
          message_group_id,
          message_time,
          message_user_id,
          message_uuid,
        });
        const saveVideo = SaveVideoMessage({
          message_group_id,
          message_state,
          message_video: value,
          message_time,
          message_user,
          message_user_id,
          message_uuid,
        });
        await MessageIDB.add({
          _id: message_uuid,
          group_id: message_group_id,
          message: encrypt(saveVideo),
          date: message_time * 1000,
          state: message_state,
        });
        if (messageLimit < 30) setMessageLimit((prev) => prev + 1);
        else listMessage.refetch();
        // send message
        sip.sendMessage(sendVideo);
        // save last message
        const idbGroup = await GroupIDB.get(message_group_id, "_id");
        if (!isEmpty(idbGroup))
          await GroupIDB.put({
            ...idbGroup,
            last_message: encrypt({
              ...saveVideo,
              text: "system_message.video_message_text",
              isUnread: false,
            }),
          });
        groups.refetch();
        if (chatRef.current?.scrollTop < -100)
          chatRef?.current?.scroll({ top: 0, behavior: "smooth" });
      });
    }
    // MESSAGE FILE
    if (!isEmpty(message_other)) {
      message_other.forEach(async (value) => {
        const message_time = Math.floor(Date.now() / 1000);
        const message_uuid = uuid(`${Math.floor(Math.random() * 1000)}`, v4());
        const message_user_id = userInformation.id;
        const message_group_id = +decodeID(window.location.pathname.replace("/t/", ""));
        const message_state = MESSAGE_STATE.PENDING;
        const message_user = {
          id: userInformation.id,
          avatar: userInformation.avatar,
          user_name: userInformation.user_name,
        };
        const sendFile = SendFileMessage({
          message_other: value,
          message_group_id,
          message_time,
          message_user_id,
          message_uuid,
        });
        const saveFile = SaveFileMessage({
          message_group_id,
          message_state,
          message_other: value,
          message_time,
          message_user,
          message_user_id,
          message_uuid,
        });
        await MessageIDB.add({
          _id: message_uuid,
          group_id: message_group_id,
          message: encrypt(saveFile),
          date: message_time * 1000,
          state: message_state,
        });
        if (messageLimit < 30) setMessageLimit((prev) => prev + 1);
        else listMessage.refetch();
        // send message
        sip.sendMessage(sendFile);
        // save last message
        const idbGroup = await GroupIDB.get(message_group_id, "_id");
        if (!isEmpty(idbGroup))
          await GroupIDB.put({
            ...idbGroup,
            last_message: encrypt({
              ...saveFile,
              text: "system_message.file_message_text",
              isUnread: false,
            }),
          });
        if (chatRef.current?.scrollTop < -100)
          setTimeout(() => chatRef?.current?.scroll({ top: 0, behavior: "smooth" }), 100);
        groups.refetch();
      });
    }

    // eslint-disable-next-line no-param-reassign
    e.target.value = "";
  };
  const handleCloseEditMessage = () => {
    setOpenEditMessage(false);
    if (textRef?.current) textRef.current.value = "";
    setPrevText("");
  };
  const handleCloseReplyMessage = () => {
    setOpenReplyMessage(false);
    setMessageReplyContent({});
  };
  const handleOpenReplyMessage = () => {
    const message = messageAnchor.getElementsByClassName("message")[0]?.innerText;
    const imageUrl = messageAnchor.getElementsByClassName("imageUrl")[0]?.src;
    const fileName = messageAnchor.getElementsByClassName("fileName")[0]?.innerText;
    handleCloseEditMessage();
    setMessageReplyContent({ replyName: "Kelly Nguyen", message, imageUrl, fileName });
    setOpenReplyMessage(true);
    handleMessageClose();
  };
  const handleOpenEditMessage = () => {
    const messageText = messageAnchor.getElementsByClassName("message")[0]?.innerText;
    const message = !isEmpty(messageText) ? messageText : "";
    handleCloseReplyMessage();
    if (textRef?.current) textRef.current.value = "";
    setPrevText(message);
    setOpenEditMessage(true);
    handleMessageClose();
  };
  const onPaste = (e) => {
    const clipboardItems = e.clipboardData.items;
    const items = [].slice
      .call(clipboardItems)
      ?.filter((item) => item.type.indexOf("image") !== -1);
    if (items.length === 0) return;
    const file = items[0].getAsFile();
    file.preview = URL.createObjectURL(file);
    setImages([...images, file]);
  };
  const handleDeleteImage = (e, index) => {
    const cpList = [...images];
    cpList.splice(index, 1);
    setImages(cpList);
  };
  const handleKeypress = (event) => {
    if (event && event.key === "Enter" && !event.nativeEvent.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };
  const handleReadAllMessage = () => chatRef.current.scroll({ top: 0, behavior: "smooth" });
  // information
  const handleToggleInformation = () => {
    setOpenInformation((prev) => !prev);
    if (isOpenInformation) closeAllDialogInformation();
  };
  // information-add-member-chat
  const handleSetSearch = (e) => {
    setSearchValue(e.target.value);
  };
  const handleToggleAddMemberChat = (group = true) => {
    if (group) {
      setOpenAddMemberChat((prev) => !prev);
      setSelectIndex([]);
    } else {
      setOpenAddMemberChat(false);
    }
  };
  const handleOpenAddMemberChat = () => {
    setOpenAddMemberChat(true);
    setOpenListMemberDialog(false);
    setSelectIndex([]);
  };
  const handleAddMemberChat = (id) => {
    if (selectIndex.length > 0) {
      AddMember({
        user_ids: selectIndex.map((item) => item.id),
        permission: 0,
        group_id: id,
      }).then((res) => {
        if (res.error === 0) {
          setOpenAddMemberChat(false);
          setOpenInformation(false);
          setSelectIndex([]);
          groupDetailQuery.refetch();
        }
      });
    }
  };

  const handleChangePermissionGroup = async (userId, groupId) => {
    const res = await ChangePermission({
      user_id: userId,
      permission: groupDetail.data.group.admin_ids.includes(userId) ? 0 : 1,
      group_id: groupId,
    });
    if (res.error === 0) {
      setOpenInformation(true);
      groupDetail.refetch();
    }
  };
  const handleRemoveUser = async (userId, groupId) => {
    const res = await RemoveMember({
      user_ids: typeof userId === "object" ? userId : [userId],
      group_id: groupId,
    });
    if (res.error === 0) {
      setOpenInformation(true);
      groupDetail.refetch();
    }
  };

  // ##########################################################################
  // #                            RENDER VIEW                                 #
  // ##########################################################################
  return (
    <Box
      sx={styles.container}
      onDragStart={overrideDefault}
      onDrop={overrideDefault}
      onDragLeave={overrideDefault}
      onDragOver={overrideDefault}
    >
      <SettingDialog isOpen={isOpenSettingDialog} onClose={handleCloseSettingDialog} />
      <Fade in={!isSelectGroup || !isMobileSize} unmountOnExit timeout={500}>
        <Box>
          <UserList
            listGroupChat={searchUser ? filterGroup : groups?.data}
            isLoading={groupQuery?.isLoading || groups?.isLoading}
            searchOptions={{ handleSearchUserList }}
            addGroupChat={{
              searchGolferKey,
              handleSearchUser,
              handleAddSingleChat,
              isAddGroup,
              handleOpenAddGroup,
              handleCloseAddGroup,
              refetchRecent: groupQuery.refetch,
              handleAddGroupChat,
            }}
            selectOptions={{ handleSelect }}
            personalOptions={{
              isPersonalPopperOpen,
              handleOpenPersonalPopper,
              personalAnchor,
              handleClosePersonalPopper,
              handleLogout,
            }}
            listGroupAdd={
              searchGolferKey
                ? searchGolferMutate?.data?.res
                : groups?.data
                    ?.filter((value) => value?.group?.type === "single")
                    ?.filter((value) => !AdminNames.includes(value.group.name))
                    .map((value) => {
                      const detail = value?.detail?.users?.find(
                        (v) => +v.id !== +userInformation.id
                      );
                      return {
                        group_id: value?._id,
                        // _id: detail?._id,
                        id: detail?.id,
                        username: detail?.username || value?.group?.name,
                        avatar: detail?.avatar || value?.group?.image,
                      };
                    })
            }
            conversationOptions={{
              isAddConversation,
              handleOpenConversation,
              handleCloseConversation,
            }}
            dialogOptions={{ handleOpenSettingDialog }}
            userInformation={userInformation}
          />
        </Box>
      </Fade>
      <Fade in={(!isOpenInformation && isSelectGroup) || !isMobileSize} unmountOnExit timeout={500}>
        <Box sx={(e) => styles.header(e, { isHide: !isSelectGroup })}>
          <Header
            isMobileSize={isMobileSize}
            isOpenInformation={isOpenInformation}
            openSetting={openSetting}
            anchor={anchor}
            isAddFriend={isAddFriend}
            isBlockFriend={isBlockFriend}
            openPinMessage={openPinMessage}
            onBack={() => handleSelect()}
            handleAddFriend={handleAddFriend}
            handleOpenHeaderPopper={handleOpenHeaderPopper}
            handleCloseHeaderPopper={handleCloseHeaderPopper}
            handleToggleInformation={handleToggleInformation}
            messagePinContent={messagePinContent}
            openDialogDeletePinMessage={openDialogDeletePinMessage}
            handleOpenDeletePinMessage={handleOpenDeletePinMessage}
            handleDeletePinMessage={handleDeletePinMessage}
            openDialogPinMessage={openDialogPinMessage}
            handleDialogPinMessage={handleDialogPinMessage}
            handleOpenListMember={handleOpenListMember}
            detailGroup={groupDetail.data}
            listPin={listPin?.data?.list || []}
            handleScrollPin={handleScrollPin}
            userInformation={userInformation}
            isFetchingNewGroup={groupQuery?.isLoading || groups?.isLoading}
            handleRemoveGroupChat={handleRemoveGroupChat}
          />
          <ContentChat
            mouseX={mouseX}
            mouseY={mouseY}
            isSelectOwner={isSelectOwner}
            openMessageSetting={openMessageSetting}
            openViewerDialog={openViewerDialog}
            isPaddingTop={isAddFriend || isBlockFriend || openPinMessage}
            unreadMessage={unreadMessage}
            chatRef={chatRef}
            handleDragLeaveFiles={handleDragLeaveFiles}
            handleDropFiles={handleDropFiles}
            handleDragEnterFiles={handleDragEnterFiles}
            isDragOverlay={isDragOverlay}
            isScrollEnd={isScrollEnd}
            selectForwardIndex={selectForwardIndex}
            isOpenForwardDialog={isOpenForwardDialog}
            handleSelectText={handleSelectText}
            handleMessageClose={handleMessageClose}
            handleCopyToClipboard={handleCopyToClipboard}
            handleOpenMessagePopper={handleOpenMessagePopper}
            handleOpenViewerDialog={handleOpenViewerDialog}
            handleViewerClose={handleViewerClose}
            handleReadAllMessage={handleReadAllMessage}
            handleOpenEditMessage={handleOpenEditMessage}
            handleOpenReplyMessage={handleOpenReplyMessage}
            setSelectForwardIndex={setSelectForwardIndex}
            handleCloseForwardDialog={handleCloseForwardDialog}
            handleOpenForwardDialog={handleOpenForwardDialog}
            handleDoneForwardDialog={handleDoneForwardDialog}
            handleOpenPinMessage={handleOpenPinMessage}
            listMessage={listMessage?.data || []}
            userInformation={userInformation}
            isFetchingMessage={isFetchingMessage}
            isFetchingNewGroup={
              groupQuery?.isLoading || groups?.isLoading || listMessageQuery?.isLoading
            }
          />
          <Footer
            isFetchingNewGroup={groupQuery?.isLoading || groups?.isLoading}
            textRef={textRef}
            sendRef={sendRef}
            isVoice={isVoice}
            time={time}
            images={images}
            recordingBlob={recordingBlob}
            isRecording={isRecording}
            isPaused={isPaused}
            openEditMessage={openEditMessage}
            openReplyMessage={openReplyMessage}
            replyObject={messageReplyContent}
            isBlock={isBlock}
            setTime={setTime}
            setIsVoice={setIsVoice}
            startRecording={startRecording}
            stopRecording={stopRecording}
            togglePauseResume={togglePauseResume}
            handleSend={handleSend}
            handleKeypress={handleKeypress}
            handleCloseEditMessage={handleCloseEditMessage}
            handleCloseReplyMessage={handleCloseReplyMessage}
            onPaste={onPaste}
            handleDeleteImage={handleDeleteImage}
            handleUploadImage={handleUploadImage}
            isChangeText={
              textRef?.current?.value === "" ||
              prevText.localeCompare(textRef?.current?.value) === 0
            }
            disabled={AdminNames.includes(groupDetail?.data?.group?.name)}
          />
        </Box>
      </Fade>
      <Fade in={isSelectGroup || !isMobileSize} unmountOnExit timeout={500}>
        <Box>
          <ChatInformation
            isMediumSize={isMediumSize}
            isOpenInformation={isOpenInformation}
            isOpenAddMemberChat={isOpenAddMemberChat}
            selectIndex={selectIndex}
            handleToggleInformation={handleToggleInformation}
            handleToggleAddMemberChat={handleToggleAddMemberChat}
            handleOpenAddMemberChat={handleOpenAddMemberChat}
            handleAddMemberChat={handleAddMemberChat}
            setSelectIndex={setSelectIndex}
            isOpenListMemberDialog={isOpenListMemberDialog}
            handleCloseListMember={handleCloseListMember}
            users={
              groupDetail?.data?.detail?.users?.filter((item) => item.id !== userInformation?.id) ||
              []
            }
            detailGroupFetching={groupDetail.isFetching}
            detailGroup={groupDetail?.data?.detail}
            handleSetSearch={handleSetSearch}
            listSearchVID={listSearchVID}
            isAdmin={groupDetail?.data?.detail?.admin_ids?.includes(userInformation?.id)}
            handleChangePermissionGroup={handleChangePermissionGroup}
            handleRemoveUser={handleRemoveUser}
            handleOpenListMember={handleOpenListMember}
          />
        </Box>
      </Fade>
    </Box>
  );
}
