export default {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    my: "auto",
    pt: 8,
  },
  qrImage: {
    maxWidth: "100%",
    width: "360px",
  },
  title: ({ typography }) => ({
    mt: 2.5,
    fontWeight: typography.fontWeightBold,
    mx: 4,
  }),
  subtitleContainer: {
    mt: 4.5,
    mx: 4,
  },
  inlineContainer: {
    py: 2,
  },
  inline: {
    display: "inline",
  },
  inlineBold: ({ typography }) => ({
    display: "inline",
    fontWeight: typography.fontWeightBold,
  }),
  loginButton: ({ borders }) => ({
    borderRadius: `${borders.borderRadius.round} !important`,
    textTransform: "uppercase",
    height: "50px",
    mt: 5.5,
    mb: 8,
    mx: 2,

    border: "transparent solid 2px !important",
    background:
      "linear-gradient(#fff 0 0) padding-box, linear-gradient(to right, #26BBFE, #1BC88B) border-box",
    "&:hover": {
      background:
        "linear-gradient(#f8ffff 0 0) padding-box, linear-gradient(to right, #26BBFE, #1BC88B) border-box",
    },
  }),
  login: ({ typography, breakpoints }) => ({
    background: "-webkit-linear-gradient(#26BBFE 3.27%, #1BC88B 97.45%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: typography.fontWeightBold,
    fontFamily: "'SVN-Gotham', sans-serif !important",
    [breakpoints.down("sm")]: {
      mx: -0.5,
    },
  }),
};
