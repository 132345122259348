export default {
  container: ({ breakpoints, borders }) => ({
    my: 2,
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: borders.borderRadius.lg,
        width: "100%",
        height: "100%",
        maxHeight: "615px",
        maxWidth: "486px",
        minWidth: "300px",
        [breakpoints.down("lg")]: {
          maxWidth: "45vw",
        },
      },
    },
  }),
  DialogTittle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    m: 2,
  },
  Tabs: ({ breakpoints }) => ({
    background: "#ffff",
    borderRadius: 0,
    padding: 0,
    minWidth: "250px",
    height: "32px",
    [breakpoints.down("md")]: {
      minWidth: "210px",
    },
    [breakpoints.down("sm")]: {
      minWidth: "110px",
    },
    "& .MuiTabs-indicator": {
      borderRadius: 0,
      boxShadow: "none",
    },
  }),
  TabPanel: { maxHeight: "490px", overflowY: "auto", pb: 6 },
  DividerT: { mb: 1, mt: 0, backgroundColor: "#707070" },
  DividerB: { my: 1, backgroundColor: "#707070" },
  Icon: { flexShrink: 0 },
  Tab: {
    p: 1.5,
    borderRadius: 4,
    mr: 1,
    textAlign: "center",
    border: "transparent solid 2px !important",
    background:
      "linear-gradient(#ffff 0 0) padding-box, linear-gradient(180deg, #26BBFE 3.27%, #1BC88B 97.45%) border-box",
    "&:hover": {
      background:
        "linear-gradient(#ffff 0 0) padding-box, linear-gradient(180deg, #26BBFE 3.27%, #1BC88B 97.45%) border-box",
    },
  },
  TabBg: {
    p: 1.5,
    borderRadius: 4,
    mr: 1,
    border: "2px solid transparent",
    textAlign: "center",
    transition: "1s",
    background: "#F4F4F4",
  },
  TabViewed: ({ typography }) => ({
    fontFamily: typography.fontFamily3,
    fontSize: "15px",
    fontWeight: typography.fontWeightRegular,
    background: "-webkit-linear-gradient(#26BBFE, #1BC88B)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  }),
  TabNoBorder: ({ typography }) => ({
    fontFamily: typography.fontFamily3,
    fontSize: "15px",
  }),
  TabTitle: ({ typography }) => ({
    fontFamily: typography.fontFamily3,
    fontSize: typography.size.xxs,
    fontWeight: typography.fontWeightRegular,
    background: "-webkit-linear-gradient(#26BBFE, #1BC88B)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  }),
  fontFamily: ({ typography }) => ({
    fontSize: typography.size.xxs,
    fontFamily: typography.fontFamily3,
    fontWeight: typography.fontWeightRegular,
  }),
  TabInfo: {
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  users: {
    px: { xs: 1, sm: 2 },
    minHeight: "60px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    py: 0,
  },
};
