export default {
  container: {
    mt: 2.5,
    p: 1,
  },
  section: ({ isOwner }) => ({
    display: "flex",
    alignSelf: isOwner ? "flex-end" : "flex-start",
    flexDirection: isOwner ? "row-reverse" : "row",
  }),
  avatar: {
    mr: 2,
    width: "50px",
    height: "50px",
  },
  messageSection: ({ breakpoints }) => ({
    maxWidth: "30%",
    [breakpoints.down("xl")]: {
      maxWidth: "50%",
    },
  }),
  // local
  imageContainer: ({ borders, palette }, { isOwner }) => ({
    cursor: "pointer",
    boxShadow: "0px 0px 7.75px rgba(0, 0, 0, 0.25)",
    borderTopRightRadius: !isOwner ? borders.borderRadius.xl : 0,
    borderTopLeftRadius: isOwner ? borders.borderRadius.xl : 0,
    borderBottomRightRadius: borders.borderRadius.xl,
    borderBottomLeftRadius: borders.borderRadius.xl,
    background: palette.white.main,
    p: 0.2,
    lineHeight: 0,
    position: "relative",
    overflow: "hidden",
  }),
  nameContainer: {
    px: 2,
    my: 0.5,
  },
  username: ({ typography, palette }) => ({
    fontWeight: typography.fontWeightBold,
    color: palette.inherit.main,
    display: "inline",
  }),
  userID: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    background: "-webkit-linear-gradient(#26BBFE, #1BC88B)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    pl: 0.7,
    display: "inline",
  }),
  imageSection: ({ borders, palette }, { isOwner, isDisplayUsername }) => ({
    minWidth: "120px",
    minHeight: "60px",
    overflow: "hidden",
    borderTopRightRadius:
      !isOwner && !isDisplayUsername ? `calc(${borders.borderRadius.xl} - 2px)` : 0,
    borderTopLeftRadius: isOwner ? `calc(${borders.borderRadius.xl} - 2px)` : 0,
    borderBottomRightRadius: `calc(${borders.borderRadius.xl} - 2px)`,
    borderBottomLeftRadius: `calc(${borders.borderRadius.xl} - 2px)`,
    backgroundColor: palette.grey[200],
  }),
  image: () => ({
    width: "100%",
    height: "100%",
    objectFit: "cover",
    maxHeight: "18vh",
    mt: -0.5,
  }),
  imageTimeContainer: {
    position: "absolute",
    bottom: "8px",
    right: "16px",
    display: "flex",
  },
  imageTime: ({ palette }) => ({
    color: palette.white.main,
    textShadow: "0px 0px 3.9px rgba(0, 0, 0, 0.75)",
    zIndex: 2,
  }),
  sendedIcon: {
    width: "22px",
    marginLeft: "8px",
  },
  overlay: ({ palette, functions }, { isDisplay }) => ({
    position: "absolute",
    display: isDisplay ? "flex" : "none",
    width: "100%",
    height: "100%",
    left: 0,
    right: 0,
    top: 0,
    justifyContent: "center",
    alignItems: "center",
    bottom: 0,
    backgroundColor: functions.rgba(palette.black.main, 0.4),
    zIndex: 1,
    flexGrow: 1,
  }),
};
