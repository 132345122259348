import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import styles from "assets/styles/pages/Chat/component/AddMemberChat";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as SearchIcon } from "assets/svg/icon/search.svg";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import defaultAvatar from "assets/images/default/default_avatar.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "./CheckBox";

function AddMemberChat({
  handleToggleAddMemberChat,
  handleAddMemberChat,
  isOpenAddMemberChat,
  isMediumSize,
  selectIndex,
  setSelectIndex,
  group,
  handleSetSearch,
  listSearchVID,
}) {
  const { t } = useTranslation();
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    if (selectIndex.length > 0) setIsEmpty(false);
    else setIsEmpty(true);
  }, [selectIndex]);

  const handleCheckBox = (item) => {
    if (group?.users.map((val) => val.id).includes(item.id)) {
      return false;
    }
    const cpList = [...selectIndex];
    if (cpList.includes(item)) {
      const newList = cpList.filter((val) => val.id !== item.id);
      setSelectIndex(newList);
    } else {
      cpList.push(item);
      setSelectIndex(cpList);
    }
  };
  return (
    <Drawer
      sx={(e) => styles.drawer(e, { isOpenAddMemberChat })}
      onClose={handleToggleAddMemberChat}
      anchor="right"
      open={isOpenAddMemberChat}
      variant={!isMediumSize ? "permanent" : "temporary"}
    >
      <Box sx={styles.fLexCol}>
        <Box />
        <Box sx={styles.dialogTitle}>
          <IconButton onClick={handleToggleAddMemberChat}>
            <ArrowBackIosIcon sx={styles.arrowIcon} />
          </IconButton>

          <Box sx={styles.textCenter}>
            <Typography variant="body1" color="initial" sx={styles.title}>
              {t("chat.list_member.add_member")}
            </Typography>
            <Typography
              variant="caption"
              sx={styles.textDefault}
            >{`${selectIndex.length}`}</Typography>
          </Box>
          <IconButton disabled={isEmpty} onClick={() => handleAddMemberChat(group?.id)}>
            <Typography variant="body2" sx={isEmpty ? styles.doneDefault : styles.doneActive}>
              {t("chat.list_member.save")}
            </Typography>
          </IconButton>
        </Box>
        <TextField
          placeholder={t("chat.list_member.search")}
          onChange={handleSetSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={styles.searchIcon} position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            autoComplete: "off",
          }}
          sx={styles.search}
        />
      </Box>
      <Divider sx={styles.divider} />
      <Box sx={styles.contentContainer}>
        {selectIndex.length !== 0 && (
          <Box sx={styles.boxShowSelected}>
            <Box sx={styles.overflowX}>
              {selectIndex?.map((item, i) => (
                <Box key={i + 10000}>
                  <Box key={item.id} sx={styles.selectedImage}>
                    <Box
                      key={item.id}
                      sx={{ ...styles.avatarImageSelected, mr: 1 }}
                      component="img"
                      src={defaultAvatar}
                      srcSet={item.avatar}
                      alt="item.name"
                    />
                    <IconButton onClick={() => handleCheckBox(item)} sx={styles.closeImageIcon}>
                      <CloseIcon sx={styles.iconW18} />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        )}
        <Box sx={styles.countSelectedBox}>
          <Typography variant="caption" color="initial" sx={styles.subTitle}>
            {t("chat.list_member.suggest")}
          </Typography>
        </Box>
        <Stack sx={styles.overflowY}>
          {listSearchVID?.map((item, i) => (
            <Box
              key={i}
              sx={(e) => styles.boxCheckBox(e, group?.users.map((val) => val.id).includes(item.id))}
              onChange={() => handleCheckBox(item)}
            >
              <Checkbox
                label={
                  <Box sx={{ ...styles.flexGrow, py: 1 }}>
                    <Avatar src={defaultAvatar} srcSet={item.avatar} sx={styles.avatarImage} />
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="caption" color="initial" sx={styles.labelLeft}>
                          {item.username.slice(
                            0,
                            item.username.length -
                              (item.username.split(" ")[item.username.split(" ").length - 1]
                                .length +
                                2)
                          )}
                        </Typography>
                        <Typography variant="caption" color="initial" sx={styles.labelRight}>
                          {`${item.username.slice(
                            -(
                              item.username.split(" ")[item.username.split(" ").length - 1].length +
                              2
                            )
                          )} - `}
                        </Typography>
                      </Box>
                      <Typography variant="caption" sx={styles.labelActive}>
                        {item.id}
                      </Typography>
                    </Box>
                  </Box>
                }
                checked={
                  selectIndex.includes(item) || group?.users.map((val) => val.id).includes(item.id)
                }
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                disabled={group?.users.map((val) => val.id).includes(item.id)}
              />
            </Box>
          ))}
        </Stack>
      </Box>
    </Drawer>
  );
}
export default AddMemberChat;
