import { chatBarHeight } from "constant/chat";

export default {
  container: ({ breakpoints }) => ({
    marginTop: chatBarHeight,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflowY: "hidden",
    flexGrow: 1,
    position: "relative",

    [breakpoints.down("md")]: {
      p: {
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none",
        userSelect: "none",
        webkitTouchCallout: "none",
      },
      span: {
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none",
        userSelect: "none",
        webkitTouchCallout: "none",
      },
    },
  }),
  overlay: ({ palette }, { isFetchingNewGroup }) => ({
    position: "absolute",
    display: isFetchingNewGroup ? "block" : "none",
    width: "100%",
    height: "100%",
    left: 0,
    bottom: 2,
    backgroundColor: palette.white.main,
    zIndex: 1,
    flexGrow: 1,
  }),
  listMessage: ({ isPaddingTop }) => ({
    pt: isPaddingTop ? 8 : 2,
    pb: 2,
    pl: 2.5,
    pr: 1,
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column-reverse",
    minHeight: "100%",
    justifyContent: "space-between",
  }),
  progressContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  progress: {
    width: "24px",
    height: "24px",
  },
  dragOverlay: ({ palette, functions }, { isDragOverlay }) => ({
    position: "absolute",
    display: isDragOverlay ? "flex" : "none",
    width: "100%",
    height: "100%",
    backgroundColor: functions.rgba(palette.black.main, 0.4),
    zIndex: 999,
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  }),
  dragOverlayText: {
    fontWeight: 500,
    zIndex: 999,
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
};
