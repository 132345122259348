export default {
  PinMessage: {
    with: "100%",
    background: "#ffff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    py: 1.5,
    borderBottom: 2,
    borderBottomColor: "#00ABA7",
    borderTop: 1,
    borderTopColor: "#DDDDDD",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
  },
  boxMessagePin: {
    ml: 3,
    flexGrow: 1,
  },
  message: ({ typography }) => ({
    fontFamily: typography.fontFamily2,
    background: "-webkit-linear-gradient(#26BBFE, #1BC88B)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: 600,
    wordBreak: "break-all",
    fontSize: typography.fontSizeXS,
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  }),
  dayPin: ({ typography }) => ({
    fontFamily: typography.fontFamily2,
    color: "#949494",
    fontWeight: typography.fontWeightRegular,
    fontSize: typography.fontSizeXXS,
  }),
  IconClose: {
    mr: 2,
    flexShrink: 0,
    alignItems: "center",
  },
  textImage: ({ typography }) => ({
    fontFamily: typography.fontFamily3,
    fontWeight: 600,
    fontSize: "16px",
    background: "-webkit-linear-gradient(#26BBFE, #1BC88B)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    width: "90%",
  }),
  boxImage: {
    width: "45px",
    height: "45px",
    mr: 2,
    position: "relative",
  },
  image: ({ borders }) => ({
    border: "1px solid #D5D5D5",
    width: "45px",
    objectFit: "cover",
    height: "45px",
    borderRadius: borders.borderRadius.md,
  }),
  imageBg: ({ borders }) => ({
    background: "black",
    opacity: 0.5,
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    height: "45px",
    borderRadius: borders.borderRadius.md,
  }),
  numberImagePin: ({ typography }) => ({
    position: "absolute",
    top: "30%",
    left: "30%",
    color: "#ffff",
    fontSize: "14px",
    fontFamily: typography.fontFamily3,
  }),
  contentImageOrFile: {
    flexGrow: 1,
    display: "flex",
    alignItems: "flex-end",
    ml: -0.5,
  },
  boxContentImage: { flexGrow: 1 },
  fillColorFileIcon: {
    width: "40px",
    height: "40px",
    mr: 1.5,
  },
  content: {
    display: "flex",
    alignItems: "center",
    ml: -1,
  },
  fileIcon: {
    ml: 1,
    mr: 1.5,
    p: 0,
  },
  messagePin: {
    mt: 0.5,
  },
  buttonDialog: {
    px: { xs: 2, sm: 3 },
  },
  button: {
    px: 2,
    borderRadius: 25,
    border: "transparent solid 2px !important",
    background:
      "linear-gradient(#fff 0 0) padding-box, linear-gradient(to right, #26BBFE, #1BC88B) border-box",
    "&:hover": {
      background:
        "linear-gradient(#f8ffff 0 0) padding-box, linear-gradient(to right, #26BBFE, #1BC88B) border-box",
    },
  },
  numberPin: ({ typography }) => ({
    pr: 1,
    fontSize: typography.size.sm,
    background: "-webkit-linear-gradient(#26BBFE, #1BC88B)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textAlign: "center",
  }),
  iconDown: ({ openDialogPinMessage }) => ({
    pt: 0.5,
    transform: openDialogPinMessage ? "rotate(-180deg)" : "none",
    transition: "0.5s",
  }),
};
