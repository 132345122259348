import TabPanel from "components/TabPanel";
import {
  Box,
  ClickAwayListener,
  Grid,
  Grow,
  List,
  Paper,
  Popper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import styles from "assets/styles/pages/Chat/component/EmojiPopper";
import { useEffect, useState } from "react";
import HiImg from "assets/images/hi.png";
import TakeBallImg from "assets/images/take_ball.png";
import WhoCareImg from "assets/images/whocare.png";
import OutTingImg from "assets/images/outting.png";
import FunnyImg from "assets/images/funny.png";
import CryImg from "assets/images/be_gay.png";

const listImageTab1 = Array(20).fill(HiImg);
const listImageTab2 = Array(12).fill(FunnyImg);
const listImageTab3 = Array(17).fill(CryImg);
const listTab = Array(9).fill(WhoCareImg, 0, 1).fill(TakeBallImg, 1, 2).fill(OutTingImg, 2);

function EmojiPopper({ openSetting, anchor, handleClose }) {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (openSetting) {
      const slider = document.querySelector("#items");
      let isDown = false;
      let startX;
      let scrollLeft;
      slider.addEventListener("mousedown", (e) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = x - startX;
        slider.scrollLeft = scrollLeft - walk;
      });
    }
  }, [openSetting]);

  return (
    <Popper
      placement="bottom-end"
      open={openSetting}
      anchorEl={anchor}
      role={undefined}
      transition
      disablePortal
      popperOptions={{ modifiers: [{ name: "offset", options: { offset: [15, 15] } }] }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper sx={styles.paper}>
            <ClickAwayListener disableReactTree mouseEvent="onMouseDown" onClickAway={handleClose}>
              <Box sx={styles.heightFull}>
                <List sx={styles.heightFull}>
                  <Box sx={styles.dFlex}>
                    <Box sx={styles.IconBox}>
                      <TabPanel value={value} sx={styles.tabPanel} index={0}>
                        <Grid container spacing={1}>
                          {listImageTab1.map((v, index) => (
                            <Grid
                              item
                              xs={12 / 5}
                              sm={12 / 5}
                              md={12 / 5}
                              lg={12 / 5}
                              key={index}
                              sx={styles.hoverIcon}
                            >
                              <Box component="img" src={v} sx={styles.imgMedia} />
                            </Grid>
                          ))}
                        </Grid>
                      </TabPanel>
                      <TabPanel value={value} sx={styles.tabPanel} index={1}>
                        <Grid container spacing={1}>
                          {listImageTab2.map((v, index) => (
                            <Grid
                              item
                              xs={12 / 5}
                              sm={12 / 5}
                              md={12 / 5}
                              lg={12 / 5}
                              key={index}
                              sx={styles.hoverIcon}
                            >
                              <Box component="img" src={v} sx={styles.imgMedia} />
                            </Grid>
                          ))}
                        </Grid>
                      </TabPanel>
                      <TabPanel value={value} sx={styles.tabPanel} index={2}>
                        <Grid container spacing={1}>
                          {listImageTab3.map((v, index) => (
                            <Grid
                              item
                              xs={12 / 5}
                              sm={12 / 5}
                              md={12 / 5}
                              lg={12 / 5}
                              key={index}
                              sx={styles.hoverIcon}
                            >
                              <Box component="img" src={v} sx={styles.imgMedia} />
                            </Grid>
                          ))}
                        </Grid>
                      </TabPanel>
                    </Box>
                    <Box sx={styles.height50}>
                      <Tabs
                        id="items"
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{
                          style: styles.borderRadius0,
                        }}
                        sx={styles.tabList}
                      >
                        {listTab.map((tab, i) => (
                          <Tab
                            key={i}
                            sx={styles.tab}
                            label={
                              <Typography variant="body1" sx={styles.height49}>
                                <Box component="img" src={tab} sx={styles.tab} />
                              </Typography>
                            }
                          />
                        ))}
                      </Tabs>
                    </Box>
                  </Box>
                </List>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

export default EmojiPopper;
