import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import styles from "assets/styles/pages/Chat/component/ListMember";
import { ReactComponent as AddMemberIcon } from "assets/svg/icon/add_member_icon.svg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import defaultAvatar from "assets/images/default/default_avatar.png";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserToken } from "utils/storage";
import ListMemberAction from "./ListMemberAction";

function ListMember({
  handleCloseListMember,
  isMediumSize,
  isOpenListMemberDialog,
  handleOpenAddMemberChat,
  group,
  isAdmin,
  handleChangePermissionGroup,
  handleRemoveUser,
}) {
  const [active, setActive] = useState(0);
  const [anchorListMember, setAnchorListMember] = useState();
  const [select, setSelect] = useState(null);
  const [openPopperListMember, setOpenPopperListMember] = useState(false);
  const { t } = useTranslation();
  const userInformation = getUserToken();

  const buttonList = [
    { id: 0, label: t("chat.list_member.all") },
    { id: 1, label: t("chat.list_member.admin") },
  ];
  const handleActive = (id) => {
    if (!openPopperListMember) {
      setActive(id);
    }
  };
  const handleOpenListMemberPopper = (event, item) => {
    event.preventDefault();
    setSelect(item);
    setAnchorListMember(event.currentTarget);
    setOpenPopperListMember(true);
  };
  const handleCloseListMemberPopper = () => {
    setSelect(null);
    setOpenPopperListMember(false);
  };
  return (
    <>
      <Drawer
        sx={(e) => styles.drawer(e, { isOpenListMemberDialog })}
        onClose={handleCloseListMember}
        anchor="right"
        open={isOpenListMemberDialog}
        variant={!isMediumSize ? "permanent" : "temporary"}
      >
        <Box sx={styles.fLexCol}>
          <Box />
          <Box sx={styles.dialogTitle}>
            <IconButton onClick={handleCloseListMember}>
              <ArrowBackIosIcon sx={styles.arrowIcon} />
            </IconButton>

            <Box>
              <Typography variant="body1" color="initial" sx={styles.title}>
                {t("chat.list_member.participants")}
              </Typography>
            </Box>
            <Box>
              <IconButton
                sx={styles.icon}
                disabled={!isAdmin}
                onClick={isAdmin ? handleOpenAddMemberChat : handleOpenAddMemberChat}
              >
                <AddMemberIcon />
              </IconButton>
            </Box>
          </Box>
          <ButtonGroup sx={styles.buttonGroup}>
            {buttonList.map((button) => (
              <Button
                key={button.id}
                onClick={() => handleActive(button.id)}
                sx={() => styles.buttonGroupChild(active, button.id)}
              >
                {button.label}
              </Button>
            ))}
          </ButtonGroup>
        </Box>
        <Divider sx={styles.divider} />
        <Box
          sx={styles.contentContainer}
          onContextMenu={(event) => {
            event.preventDefault();
          }}
        >
          <Stack sx={styles.overflowY}>
            {group?.users
              ?.filter((item) => (active === 0 ? item : group.admin_ids.includes(item.id)))
              .map((item) => (
                <Box
                  onContextMenu={
                    group?.admin_ids.includes(userInformation?.id) ||
                    userInformation?.id === item?.id
                      ? (e) => handleOpenListMemberPopper(e, item)
                      : (e) => {
                          e.preventDefault();
                          return false;
                        }
                  }
                  key={item.id}
                  sx={({ breakpoints }) => ({
                    ...styles.dFlexNotJust,
                    ...(select === item ? styles.boxUserSelect : styles.boxUser),
                    py: 1.25,
                    pl: 2.5,
                    pr: 1.5,
                    [breakpoints.between("md", "lg")]: {
                      px: 1.25,
                    },
                  })}
                >
                  <Box
                    sx={{
                      ...styles.dFlexNotJust,
                      flexGrow: 1,
                    }}
                  >
                    <Avatar src={defaultAvatar} srcSet={item.avatar} sx={styles.avatarImage} />
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="caption" color="initial" sx={styles.labelLeft}>
                          {item.username.slice(
                            0,
                            item.username.length -
                              (item.username.split(" ")[item.username.split(" ").length - 1]
                                .length +
                                2)
                          )}
                        </Typography>
                        <Typography variant="caption" color="initial" sx={styles.labelRight}>
                          {`${item.username.slice(
                            -(
                              item.username.split(" ")[item.username.split(" ").length - 1].length +
                              2
                            )
                          )} - `}
                        </Typography>
                      </Box>
                      <Typography variant="caption" sx={styles.labelActive}>
                        {item.id}
                      </Typography>
                    </Box>
                  </Box>
                  {group.admin_ids.includes(item.id) && (
                    <Box sx={styles.tag}>
                      <Typography variant="body2" sx={styles.tagLabel}>
                        {t("chat.list_member.admin")}
                      </Typography>
                    </Box>
                  )}
                </Box>
              ))}
          </Stack>
        </Box>
      </Drawer>
      <ListMemberAction
        openPopperListMember={openPopperListMember}
        anchor={anchorListMember}
        handleClose={handleCloseListMemberPopper}
        member={select}
        group={group}
        handleChangePermissionGroup={handleChangePermissionGroup}
        handleRemoveUser={handleRemoveUser}
      />
    </>
  );
}
export default ListMember;
