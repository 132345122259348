export default {
  list: ({ borders: { borderRadius } }) => ({
    width: "100%",
    borderRadius: `${borderRadius.lg} !important`,
    minWidth: "100px",
    "& .MuiListItemButton-root": {
      // mt: 0.5,
    },
  }),
  listItemButton: ({ borders: { borderRadius } }) => ({
    borderRadius: borderRadius.xs,
    display: "flex",
  }),
  listItem: {
    display: "inline",
  },
  paper: {
    boxShadow: "none",
  },
  container: ({ breakpoints }, { openEditMessage, openReplyMessage }) => ({
    padding: "0 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    boxShadow: !openEditMessage && !openReplyMessage ? "0px -2px 4px rgba(0, 0, 0, 0.15)" : "none",
    [breakpoints.down("lg")]: {
      padding: "0 8px",
    },
  }),
  flexGrow: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  flexGrowResponsive: ({ breakpoints }, images) => ({
    flexGrow: 1,
    display: "flex",
    flexDirection: images.length > 0 ? "column-reverse" : "row",
    alignItems: images.length > 0 ? "start" : "center",
    width: "100%",
    "&::after": {
      [breakpoints.up("lg")]: {
        content: '""',
      },
      height: "16px",
      width: "1.5px",
      backgroundColor: "#8B8B8B",
      position: "absolute",
      bottom: images.length > 0 ? "22px" : "none",
    },
  }),
  flexBoxRight: ({ breakpoints }, isVoice) => ({
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [breakpoints.down("lg")]: {
      flexDirection: "column-reverse",
      mr: 1.5,
    },
    [breakpoints.down("xl")]: {
      display: isVoice ? "none" : "flex",
    },
  }),
  listIcon: ({ breakpoints }) => ({
    height: "26px",
    [breakpoints.down("lg")]: {
      width: "100%",
      marginBottom: "5px",
    },
  }),
  positionCenter: ({ breakpoints }) => ({
    width: "190px",
    [breakpoints.down("lg")]: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
    },
    [breakpoints.between("lg", "xxl")]: {
      width: "180px",
    },
  }),
  pIcon: {
    py: 0,
  },
  fillColorIconLeft: {
    fill: "#00ABA7",
    width: "24px",
    height: "24px",
  },
  fillColorIconRight: ({ isChangeText }) => ({
    background: "linear-gradient(124.77deg, #26BBFE 13.59%, #1BC88B 91.04%);",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    float: "right",
    opacity: isChangeText ? 0.5 : 1,
  }),
  fillColorWhite: {
    fill: "white",
  },
  closeVoiceIcon: {
    px: 1.25,
    py: 0,
  },
  inputMessage: ({ breakpoints }) => ({
    width: "100%",
    position: "relative",
    top: "12px",
    borderRadius: "100px",
    mb: 3,

    fieldset: {
      borderRadius: "100px",
      border: "none",
      height: "50px",
    },
    input: {
      fontSize: "18px",
      py: 0,
      "&::placeholder": {
        fontWeight: 550,
      },
    },
    [breakpoints.down("lg")]: {
      position: "relative",
      top: "8px",
      mb: 1.5,
    },
  }),
  boxRight: ({ breakpoints }) => ({
    width: "190px",
    padding: "20px 10px",
    [breakpoints.down("lg")]: {
      width: "64px",
    },
    [breakpoints.between("lg", "xl")]: {
      width: "100px",
    },
    [breakpoints.down("md")]: {
      // flexGrow: 1,
    },
  }),
  flexEnd: ({ breakpoints }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    [breakpoints.down("md")]: {
      flexGrow: 1,
    },
  }),
  time: ({ palette }) => ({
    margin: "0 10px 0 3px",
    fontSize: "16px",
    color: palette.grey[650],
    fontFamily: "SF Pro Text, sans-serif",
  }),
  pasteInputBox: {
    display: "flex",
    flexWrap: "wrap",
    maxHeight: "200px",
    overflowY: "auto",
    px: 1.5,
  },
  pasteInputImage: {
    height: "50px",
    width: "80px",
    objectFit: "cover",
    marginTop: "5px",
    position: "relative",
    border: "1px solid #F3F3F3",
    borderRadius: "10px",
  },
  pasteInputClose: {
    fill: "#8A8A8D",
    position: "relative",
    right: "25px",
    bottom: "35px",
    background: "white",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    opacity: 0.8,
    "&:hover": {
      background: "white",
      opacity: 1,
    },
  },
  iconW18: { width: "18px" },
  blockBox: {
    background: "#DB4040",
    py: 1,
  },
  blockTitle: ({ palette }) => ({
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: palette.white.main,
  }),
};
