export default {
  popper: () => ({
    zIndex: 2000,
    '&[data-popper-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "0 100%",
      },
    },
    '&[data-popper-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "100% 0",
      },
    },
    '&[data-popper-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "100% 100%",
      },
    },
    '&[data-popper-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "0 0",
      },
    },
  }),
  paper: ({ functions, palette, borders }) => ({
    backgroundColor: palette.white.main,
    borderRadius: borders.borderRadius.lg,
    boxShadow: `0px 0px 7px ${functions.rgba("#000", 0.3)}`,
    mb: 1,
    zIndex: 2000,
  }),
  container: ({ breakpoints, borders }) => ({
    backdropFilter: "blur(2px)",
    py: 2,
    maxWidth: "375px",
    width: "375px",
    borderRadius: borders.borderRadius.lg,
    [breakpoints.down("sm")]: {
      width: "calc(100vw - 32px)",
    },
  }),
  personalContainer: ({ palette }) => ({
    position: "absolute",
    top: "-1px",
    left: "20px",
    width: "18px",
    height: "13px",
    boxSizing: "border-box",
    color: palette.white.main,
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: "100%",
      height: "100%",
      backgroundColor: "currentColor",
      transform: "rotate(45deg)",
    },
  }),
  avatarContainer: {
    display: "flex",
    alignItems: "center",
    px: 2,
  },
  avatar: {
    width: "80px",
    height: "80px",
    mr: 2,
  },
  name: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    lineHeight: "18px",
    overflow: "hidden",
    height: "18px",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
  }),
  vga: ({ palette }) => ({
    color: palette.grey[650],
    lineHeight: "16px",
    overflow: "hidden",
    height: "16px",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-wrap",
    mt: 1,
  }),
  statusContainer: {
    display: "flex",
    mt: 2,
    mx: 2,
    pl: 1,
    alignItems: "center",
    justifyContent: "space-between",
  },
  statusFlexContainer: {
    display: "flex",
    alignItems: "center",
  },
  statusIconBorder: ({ palette, borders }) => ({
    cursor: "pointer",
    backgroundColor: palette.white.main,
    width: "15px",
    height: "15px",
    borderRadius: borders.borderRadius.xxxl,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mr: 2,
  }),
  statusIcon: ({ palette, borders }) => ({
    backgroundColor: palette.success.light,
    width: "13px",
    height: "13px",
    borderRadius: borders.borderRadius.xxxl,
  }),
  status: {
    cursor: "pointer",
  },
  divider: {
    height: "2px",
    backgroundColor: "#D1D1D1",
  },
  settingItemContainer: {
    display: "flex",
    p: 1,
    cursor: "pointer",
    alignItems: "center",
  },
  settingItem: ({ typography }) => ({
    ml: 2,
    fontWeight: typography.fontWeightBold,
    userSelect: "none",
  }),
  settingItemWithMarginContainer: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    my: 1,
  },
  settingItemButton: ({ borders }) => ({
    borderRadius: borders.borderRadius.md,
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    mx: 0,
    px: 1,
    my: 1,
  }),
  descriptionContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    mt: 7,
  },
  description: ({ palette }) => ({
    color: palette.grey[650],
  }),
  icon: {
    height: "22px",
    width: "22px",
  },
};
