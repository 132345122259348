import { memo, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
// import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
// import Popper from "@mui/material/Popper";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { ReactComponent as InformationIcon } from "assets/svg/icon/message_information.svg";
import { ReactComponent as ReplyIcon } from "assets/svg/icon/message_reply.svg";
import { ReactComponent as PinIcon } from "assets/svg/icon/message_pin.svg";
import { ReactComponent as CopyIcon } from "assets/svg/icon/message_copy.svg";
import { ReactComponent as ForwardIcon } from "assets/svg/icon/message_forward.svg";
import { ReactComponent as SaveIcon } from "assets/svg/icon/message_save.svg";
import { ReactComponent as TrashIcon } from "assets/svg/icon/message_trash.svg";
import { ReactComponent as LikeIcon } from "assets/svg/icon/emotion_like.svg";
import { ReactComponent as DislikeIcon } from "assets/svg/icon/emotion_dislike.svg";
import { ReactComponent as SadIcon } from "assets/svg/icon/emotion_sad.svg";
import { ReactComponent as FunIcon } from "assets/svg/icon/emotion_fun.svg";
import { ReactComponent as LoveIcon } from "assets/svg/icon/emotion_love.svg";
import { ReactComponent as EditIcon } from "assets/svg/icon/edit_message.svg";

import styles from "assets/styles/pages/Chat/component/MessageActionSheet";
import { Button, IconButton, Popover } from "@mui/material";
import { useTranslation } from "react-i18next";

function MessageActionSheet({
  openSetting,
  // anchor,
  handleClose,
  handleCopyToClipboard,
  onSelectText,
  handleOpenViewerDialog,
  handleOpenEditMessage,
  handleOpenReplyMessage,
  mouseX,
  mouseY,
  handleOpenForwardDialog,
  handleOpenPinMessage,
}) {
  const { t } = useTranslation();
  const [emotion, setEmotion] = useState(false);

  return (
    <Popover
      open={openSetting}
      disableEnforceFocus
      anchorReference="anchorPosition"
      anchorPosition={{ top: mouseY, left: mouseX }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      sx={styles.popper}
      onContextMenu={(e) => e.preventDefault()}
    >
      <Box onClick={(e) => e.stopPropagation()}>
        <Paper sx={styles.emotionPaper}>
          {/* ---DISABLE--- */}
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setEmotion("like");
            }}
            sx={() => styles.emotionButton({ isBackground: emotion === "like" })}
            disabled
          >
            <LikeIcon style={styles.emotionBigIcon} />
          </IconButton>
          {/* ---DISABLE--- */}
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setEmotion("dislike");
            }}
            sx={() => styles.emotionButton({ isBackground: emotion === "dislike" })}
            disabled
          >
            <DislikeIcon style={styles.emotionBigIcon} />
          </IconButton>
          {/* ---DISABLE--- */}
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setEmotion("sad");
            }}
            sx={() => styles.emotionButton({ isBackground: emotion === "sad" })}
            disabled
          >
            <SadIcon style={styles.emotionIcon} />
          </IconButton>
          {/* ---DISABLE--- */}
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setEmotion("fun");
            }}
            sx={() => styles.emotionButton({ isBackground: emotion === "fun" })}
            disabled
          >
            <FunIcon style={styles.emotionIcon} />
          </IconButton>
          {/* ---DISABLE--- */}
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setEmotion("love");
            }}
            sx={() => styles.emotionButton({ isBackground: emotion === "love" })}
            disabled
          >
            <LoveIcon style={styles.emotionIcon} />
          </IconButton>
        </Paper>
        <Paper sx={styles.paper}>
          <ClickAwayListener disableReactTree onClickAway={handleClose}>
            <Box>
              {/* ---DISABLE--- */}
              <Button onClick={handleOpenViewerDialog} sx={styles.topItemContainer} disabled>
                <InformationIcon style={styles.icon} />
                <Typography variant="body1" sx={styles.item}>
                  {t("chat.content_chat.message_action_sheet.information")}
                </Typography>
              </Button>
              <Divider sx={styles.divider} />
              {/* ---DISABLE--- */}
              <Button onClick={handleOpenReplyMessage} sx={styles.itemContainer} disabled>
                <ReplyIcon style={styles.icon} />
                <Typography variant="body1" sx={styles.item}>
                  {t("chat.content_chat.message_action_sheet.reply")}
                </Typography>
              </Button>
              {/* Edit */}
              <Divider sx={styles.divider} />
              {/* ---DISABLE--- */}
              <Button onClick={handleOpenEditMessage} sx={styles.itemContainer} disabled>
                <EditIcon style={styles.icon} />
                <Typography variant="body1" sx={styles.item}>
                  {t("chat.content_chat.message_action_sheet.edit")}
                </Typography>
              </Button>
              <Divider sx={styles.divider} />
              {/* Pin */}
              <Button onClick={handleOpenPinMessage} sx={styles.itemContainer}>
                <PinIcon style={styles.icon} />
                <Typography variant="body1" sx={styles.item}>
                  {t("chat.content_chat.message_action_sheet.pin")}
                </Typography>
              </Button>
              <Divider sx={styles.divider} />
              {/* Copy */}
              <Button onClick={handleCopyToClipboard} sx={styles.itemContainer}>
                <CopyIcon style={styles.icon} />
                <Typography variant="body1" sx={styles.item}>
                  {t("chat.content_chat.message_action_sheet.copy_text")}
                </Typography>
              </Button>
              <Divider sx={styles.divider} />
              {/* Forward */}
              {/* ---DISABLE--- */}
              <Button onClick={handleOpenForwardDialog} sx={styles.itemContainer} disabled>
                <ForwardIcon style={styles.icon} />
                <Typography variant="body1" sx={styles.item}>
                  {t("chat.content_chat.message_action_sheet.forward")}
                </Typography>
              </Button>
              <Divider sx={styles.divider} />
              {/* Select */}
              {/* ---DISABLE--- */}
              <Button onClick={onSelectText} sx={styles.itemContainer} disabled>
                <SaveIcon style={styles.icon} />
                <Typography variant="body1" sx={styles.item}>
                  {t("chat.content_chat.message_action_sheet.save")}
                </Typography>
              </Button>
              <Divider sx={styles.divider} />
              {/* Delete */}
              {/* ---DISABLE--- */}
              <Button onClick={handleClose} sx={styles.bottomItemContainer} disabled>
                <TrashIcon style={styles.icon} />
                <Typography variant="body1" sx={styles.itemErrorColor}>
                  {t("chat.content_chat.message_action_sheet.delete")}
                </Typography>
              </Button>
            </Box>
          </ClickAwayListener>
        </Paper>
      </Box>
    </Popover>
  );
}

MessageActionSheet.defaultProps = {
  openSetting: false,
};

MessageActionSheet.propTypes = {
  openSetting: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

export default memo(MessageActionSheet);
