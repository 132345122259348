export default {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // background: "#D9EDFF",
    px: 3,
    py: 1,
    borderTop: "0.1px solid #D5D5D5",
    borderBottom: "0.1px solid #D5D5D5",
  },
  title: ({ typography }) => ({
    fontFamily: typography.fontFamily3,
    fontSize: "14px",
    background: "-webkit-linear-gradient(#26BBFE, #1BC88B)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  }),
  IconClose: { flexShrink: 0 },
};
