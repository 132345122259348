import { Avatar, Box, Grow, Typography } from "@mui/material";
import { memo } from "react";
import { ReactComponent as MuteIcon } from "assets/svg/icon/mute.svg";
import defaultAvatar from "assets/images/default/default_avatar.png";
import styles from "assets/styles/pages/Chat/component/ViewerItem";
import PropTypes from "prop-types";
import { ReactComponent as HeartIcon } from "assets/svg/icon/heart.svg";
import { ReactComponent as SmileIcon } from "assets/svg/icon/smile.svg";

function ViewerItem({ information, mute, isSelected }) {
  return (
    <Box sx={(e) => styles.container(e, { isSelected })}>
      <Box sx={styles.informationContainer}>
        <Box sx={styles.avatarContainer}>
          <Avatar srcSet={information.avatar} sx={styles.avatar} src={defaultAvatar} />
        </Box>
        <Box sx={styles.information}>
          <Box sx={styles.nameContainer}>
            <Typography variant="body1" color="initial" sx={styles.name}>
              {information.name}
            </Typography>
            <Grow in={Boolean(mute)} unmountOnExit>
              <MuteIcon />
            </Grow>
          </Box>
          <Typography variant="caption" sx={styles.time}>
            {information.time}
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.emotion}>
        {information.emotion === "smile" && <SmileIcon />}
        {information.emotion === "heart" && <HeartIcon />}
      </Box>
    </Box>
  );
}

ViewerItem.defaultProps = {
  information: {
    avatar: "",
    name: "",
    emotion: "",
  },
};

ViewerItem.propTypes = {
  information: PropTypes.shape({
    avatar: PropTypes.any,
    name: PropTypes.string,
    emotion: PropTypes.string,
  }),
};

export default memo(ViewerItem);
