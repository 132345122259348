import { Box, CircularProgress, Fade, Grow, Typography } from "@mui/material";
import styles from "assets/styles/pages/Chat/section/ContentChat";
import CircularLoading from "components/Loading/CircularLoading";
// import { formatDate } from "utils/date";
import { isJSON, overrideDefault } from "utils/utils";
import { useTranslation } from "react-i18next";
import { TransitionGroup } from "react-transition-group";
import MessageActionSheet from "../component/MessageActionSheet";
import Unread from "../component/Unread";
import ViewerDialog from "./ViewerDialog";
// import MessageDeleted from "../component/MessageDeleted";
import MessageFile from "../component/MessageFile";
import MessageImage from "../component/MessageImage";
// import MessageDivider from "../component/MessageDivider";
// import MessageVote from "../component/MessageVote";
import MessageNotification from "../component/MessageNotification";
import MessageNormal from "../component/MessageNormal";
import ForwardDialog from "../component/ForwardDialog";
import MessageVideo from "../component/MessageVideo";

function ContentChat({
  handleCopyToClipboard,
  handleOpenMessagePopper,
  isSelectOwner,
  openMessageSetting,
  handleMessageClose,
  handleSelectText,
  unreadMessage,
  handleReadAllMessage,
  chatRef,
  handleOpenViewerDialog,
  openViewerDialog,
  handleViewerClose,
  isPaddingTop,
  isScrollEnd,
  handleOpenEditMessage,
  handleOpenReplyMessage,
  mouseX,
  mouseY,
  isOpenForwardDialog,
  handleCloseForwardDialog,
  handleOpenForwardDialog,
  handleDoneForwardDialog,
  selectForwardIndex,
  setSelectForwardIndex,
  handleOpenPinMessage,
  listMessage,
  userInformation,
  isFetchingNewGroup,
  isFetchingMessage,
  handleDropFiles,
  handleDragEnterFiles,
  isDragOverlay,
  handleDragLeaveFiles,
}) {
  const { t } = useTranslation();
  return (
    <>
      <MessageActionSheet
        mouseX={mouseX}
        mouseY={mouseY}
        handleOpenViewerDialog={handleOpenViewerDialog}
        handleOpenForwardDialog={handleOpenForwardDialog}
        onSelectText={handleSelectText}
        isSelectOwner={isSelectOwner}
        openSetting={openMessageSetting}
        handleClose={handleMessageClose}
        handleCopyToClipboard={handleCopyToClipboard}
        handleOpenEditMessage={handleOpenEditMessage}
        handleOpenReplyMessage={handleOpenReplyMessage}
        handleOpenPinMessage={handleOpenPinMessage}
      />
      <ViewerDialog onClose={handleViewerClose} isOpen={openViewerDialog} />
      <ForwardDialog
        onClose={handleCloseForwardDialog}
        isOpen={isOpenForwardDialog}
        selectForwardIndex={selectForwardIndex}
        setSelectForwardIndex={setSelectForwardIndex}
        handleDoneForwardDialog={handleDoneForwardDialog}
      />
      <Box
        sx={(e) => styles.container(e)}
        onDragEnter={handleDragEnterFiles}
        onDragLeave={overrideDefault}
        onDrop={overrideDefault}
        onDragOver={overrideDefault}
        onContextMenu={(e) => {
          e.preventDefault();
          handleMessageClose();
        }}
      >
        <Box sx={(e) => styles.overlay(e, { isFetchingNewGroup })}>
          <CircularLoading />
        </Box>
        <Box
          onDragEnter={overrideDefault}
          onDragLeave={handleDragLeaveFiles}
          onDrop={handleDropFiles}
          onDragOver={overrideDefault}
          sx={(e) => styles.dragOverlay(e, { isDragOverlay })}
        >
          <Typography
            onDragEnter={overrideDefault}
            onDragLeave={handleDragLeaveFiles}
            onDrop={handleDropFiles}
            onDragOver={overrideDefault}
            color="white.main"
            variant="h3"
            sx={styles.dragOverlayText}
          >
            {t("system.drop_to_send")}
          </Typography>
        </Box>

        <Box ref={chatRef} sx={() => styles.listMessage({ isPaddingTop })}>
          <Box>
            <Grow in={isFetchingMessage} timeout={0}>
              <Box sx={styles.progressContainer}>
                <CircularProgress style={styles.progress} />
              </Box>
            </Grow>
            <TransitionGroup>
              {listMessage.map((element) => (
                <Fade key={element.id}>
                  <Box>
                    {/* <MessageNotification content={formatDate(element.date)} /> */}
                    {/* TEXT */}
                    {element?.message?.kind === "text" && element?.message?.system === 0 && (
                      <MessageNormal
                        idMessage={element?.message?.id}
                        handleClose={handleMessageClose}
                        handleOpenPopper={handleOpenMessagePopper}
                        handleOpenViewerDialog={handleOpenViewerDialog}
                        isShowAvatar
                        value={element?.message}
                        isOwner={userInformation.id === element?.message.user_id}
                      />
                    )}
                    {/* NOTIFICATION */}
                    {element?.message?.kind === "text" &&
                      element?.message?.system === 1 &&
                      !isJSON(element?.message?.text) && (
                        <MessageNotification content={element?.message?.text} />
                      )}
                    {/* IMAGE */}
                    {element?.message?.kind === "image" && (
                      <MessageImage
                        idMessage={element?.message?.id}
                        handleClose={handleMessageClose}
                        handleOpenPopper={handleOpenMessagePopper}
                        handleOpenViewerDialog={handleOpenViewerDialog}
                        isShowAvatar
                        value={element?.message}
                        isOwner={userInformation.id === element?.message.user_id}
                      />
                    )}
                    {/* Video */}
                    {element?.message?.kind === "video" && (
                      <MessageVideo
                        idMessage={element?.message?.id}
                        handleClose={handleMessageClose}
                        handleOpenPopper={handleOpenMessagePopper}
                        handleOpenViewerDialog={handleOpenViewerDialog}
                        isShowAvatar
                        value={element?.message}
                        isOwner={userInformation.id === element?.message.user_id}
                      />
                    )}
                    {/* File */}
                    {element?.message?.kind === "file" && (
                      <MessageFile
                        idMessage={element?.message?.id}
                        handleClose={handleMessageClose}
                        handleOpenPopper={handleOpenMessagePopper}
                        handleOpenViewerDialog={handleOpenViewerDialog}
                        isShowAvatar
                        value={element?.message}
                        isOwner={userInformation.id === element?.message.user_id}
                      />
                    )}
                  </Box>
                </Fade>
              ))}
            </TransitionGroup>
          </Box>
        </Box>
        <Unread
          unreadMessage={unreadMessage}
          handleReadAllMessage={handleReadAllMessage}
          isScrollEnd={isScrollEnd}
        />
      </Box>
    </>
  );
}

export default ContentChat;
