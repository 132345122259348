export default {
  container: {
    mt: 2.5,
    p: 1,
  },
  section: (_, { isOwner }) => ({
    display: "flex",
    alignSelf: isOwner ? "flex-end" : "flex-start",
    flexDirection: isOwner ? "row-reverse" : "row",
  }),
  avatar: {
    mr: 2,
    width: "50px",
    height: "50px",
  },
  messageSection: ({ breakpoints }) => ({
    overflow: "hidden",
    maxWidth: "30%",
    [breakpoints.down("xl")]: {
      maxWidth: "50%",
    },
    [breakpoints.down("lg")]: {
      maxWidth: "80%",
    },
    [breakpoints.down("md")]: {
      maxWidth: "50%",
    },
    [breakpoints.down("sm")]: {
      maxWidth: "80%",
    },
  }),
  icon: {
    width: "100%",
  },
  nameLeft: ({ palette }, { isOwner }) => ({
    fontSize: "16px",
    color: isOwner ? palette.white.main : palette.black.main,
    flex: "0 1 content",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  }),
  nameRight: ({ palette }, { isOwner }) => ({
    fontSize: "16px",
    color: isOwner ? palette.white.main : palette.black.main,
    flex: "1 0 content",
    whiteSpace: "nowrap",
  }),
  fileContainer: ({ borders }, { isOwner }) => ({
    cursor: "pointer",
    borderTopRightRadius: !isOwner ? borders.borderRadius.xl : 0,
    borderTopLeftRadius: isOwner ? borders.borderRadius.xl : 0,
    borderBottomRightRadius: borders.borderRadius.xl,
    borderBottomLeftRadius: borders.borderRadius.xl,
    background: !isOwner ? "#F0F0F0" : "linear-gradient(90deg, #1BC88B 0%, #26BBFE 100%)",
    p: 2,
    display: "flex",
    flexDirection: "column",
    userSelect: "none",
  }),
  nameContainer: ({ breakpoints }) => ({
    mb: 0.5,
    display: "flex",
    flexWrap: "nowrap",
    width: "200px",
    [breakpoints.down("xxl")]: {
      width: "150px",
    },
  }),
  downloadContainer: () => ({
    display: "flex",
    alignItems: "center",
  }),
  downloadIcon: {
    width: "30px",
  },
  fileInformationContainer: {
    pl: 2,
    display: "flex",
    flexDirection: "column",
  },
  fileText: ({ palette }, { isOwner }) => ({
    color: isOwner ? palette.white.main : palette.black.main,
    mb: 0.5,
  }),
  fileTextSize: ({ palette }, { isOwner }) => ({
    color: isOwner ? palette.white.main : palette.black.main,
    mb: 0.5,
    opacity: 0.5,
  }),
  timeContainer: {
    display: "flex",
    alignSelf: "flex-end",
    alignItems: "center",
  },
  time: ({ palette, functions }, { isOwner }) => ({
    color: isOwner ? functions.rgba(palette.white.main, 0.5) : palette.grey[300],
  }),
  sendedIcon: {
    width: "22px",
    marginLeft: "8px",
  },
};
