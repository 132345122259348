export default {
  dialog: ({ borders }) => ({
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: borders.borderRadius.lg,
      },
    },
  }),
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    my: 2,
    px: 2,
  },
  title: ({ palette, typography }) => ({
    color: palette.grey[400],
    fontSize: typography.size.md,
    fontWeight: typography.fontWeightLight,
  }),
  divider: {
    m: 0,
    backgroundColor: "#D1D1D1",
  },
  cropperContainer: {
    my: 3,
    display: "flex",
    justifyContent: "center",
    width: "350px",
    minHeight: "180px",
    alignItems: "center",
  },
  cropper: {
    maxWidth: "330px",
    maxHeight: "330px",
    position: "relative",
    width: "fit-content",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    height: "50px",
    mb: 2,
  },
  selectButton: ({ borders }) => ({
    borderRadius: borders.borderRadius.round,
  }),
  select: ({ palette, typography }) => ({
    color: palette.grey[700],
    fontWeight: typography.fontWeightMedium,
  }),
  doneButton: ({ borders, palette }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: borders.borderRadius.round,
    ml: 1,
    backgroundColor: `${palette.primary.main} !important`,
    "& .Mui-disabled": {
      opacity: 0.5,
    },
  }),
  done: {
    px: 1,
  },
};
