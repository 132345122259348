import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import CheckIcon from "@mui/icons-material/Check";
import { createRef, useEffect, useRef, useState } from "react";
import styles from "assets/styles/pages/Chat/section/Footer";
import { TextField, Typography } from "@mui/material";
import { ReactComponent as InsertEmoticonIcon } from "assets/svg/icon/emoji_icon.svg";
import { ReactComponent as InsertEmoticonActiveIcon } from "assets/svg/icon/emoji_icon_active.svg";
import { ReactComponent as BarChartIcon } from "assets/svg/icon/poll_icon.svg";
import { ReactComponent as BarChartActiveIcon } from "assets/svg/icon/poll_icon_active.svg";
import { ReactComponent as AttachFileActiveIcon } from "assets/svg/icon/attach_icon_active.svg";
import { ReactComponent as AttachFileIcon } from "assets/svg/icon/attach_icon.svg";
import { ReactComponent as CloseVoiceIcon } from "assets/svg/icon/close_voice.svg";
import { ReactComponent as CircleVoiceIcon } from "assets/svg/icon/circle_voice.svg";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { isEmpty } from "utils/validate";
import { useParams } from "react-router-dom";
import EditMessage from "../component/EditMessage";
import ReplyMessage from "../component/ReplyMessage";
import EmojiPopper from "../component/EmojiPopper";
import PollPopper from "../component/PollPopper";
import LoadingFooter from "../loading/Footer";

export default function Footer({
  handleSend,
  textRef,
  isVoice,
  setIsVoice,
  time,
  setTime,
  startRecording,
  togglePauseResume,
  images,
  onPaste,
  handleDeleteImage,
  sendRef,
  handleKeypress,
  openEditMessage,
  handleCloseEditMessage,
  openReplyMessage,
  handleCloseReplyMessage,
  replyObject,
  isBlock,
  isChangeText,
  isFetchingNewGroup,
  handleUploadImage,
  disabled,
}) {
  const { vID } = useParams();
  const [openSetting, setOpenSetting] = useState(false);
  const [openVote, setOpenVote] = useState(false);
  const list = [{ value: "" }, { value: "" }];
  const [listVote, setListVote] = useState(list);
  const arrLength = listVote.length;
  const [elRefs, setElRefs] = useState([]);
  const boxRef = useRef();
  const [isMaxOption, setIsMaxOption] = useState(false);
  const [active, setActive] = useState(null);
  const [textQuestion, setTextQuestion] = useState("");
  const inputQuestionRef = useRef("");
  const [isActive, setIsActive] = useState(false);
  const [selectMulti, setSelectMulti] = useState(false);
  const [, setMessage] = useState("");
  const containerRef = useRef();

  const { t } = useTranslation();
  const handleVoice = () => {
    startRecording();
    setIsVoice(true);
  };

  useEffect(() => {
    let timer = null;
    timer = setTimeout(() => {
      if (isVoice) setTime((oldTime) => moment(oldTime, "mm:ss").add(1, "seconds").format("mm:ss"));
    }, 1000);

    return () => clearTimeout(timer);
  }, [time, isVoice]);
  useEffect(() => {
    if (openReplyMessage || openEditMessage) textRef?.current?.focus();
  }, [openReplyMessage, openEditMessage]);
  const handleCloseVoice = () => {
    togglePauseResume();
    setIsVoice(false);
    setTime("00:00");
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const checkIt = () => {
    setActive(null);
    document.body.onfocus = null;
    setActive(null);
  };

  const initialize = () => {
    setActive(3);
    document.body.onfocus = checkIt;
  };

  useEffect(() => {
    setElRefs((eR) =>
      Array(arrLength)
        .fill()
        .map((_, i) => eR[i] || createRef())
    );
  }, [arrLength]);

  useEffect(() => {
    if (boxRef?.current?.scrollTop) {
      boxRef.current.scrollTop = boxRef.current.scrollHeight + 300;
    }
  }, [arrLength]);

  const handleClose = () => {
    setActive(null);
    setOpenSetting(false);
  };

  const handleOpen = () => {
    setActive(1);
    setOpenSetting((prevOpen) => !prevOpen);
  };

  const handleCloseVote = () => {
    setActive(null);
    setOpenVote(false);
    setListVote(list);
    setTextQuestion("");
    setIsMaxOption(false);
  };

  const handleOpenVote = () => {
    setActive(2);
    setOpenVote((prevOpen) => !prevOpen);
  };

  const handleAddVote = () => {
    if (arrLength <= 10) {
      setListVote([...listVote, { value: "" }]);
    } else {
      setIsMaxOption(true);
    }
  };

  const handleChange = (event, index) => {
    const cpList = [...listVote];
    cpList[index].value = event.target.value;
    setListVote(cpList);
  };

  const handleTextQuestion = (event) => {
    setTextQuestion(event.target.value);
  };
  useEffect(() => {
    if (
      inputQuestionRef.current.value &&
      listVote.map((item) => item.value).filter((item) => item.length > 0).length >= 2
    )
      setIsActive(true);
    else setIsActive(false);
  }, [listVote, textQuestion]);

  const handleCreatePoll = () => {
    const listVoteFinal = listVote.map((item) => item.value).filter((item) => item.length > 0);
    // eslint-disable-next-line no-console
    console.log({ selectMulti, textQuestion, listVoteFinal });
    setOpenVote((prevOpen) => !prevOpen);
    setActive(null);
    setListVote(list);
    setTextQuestion("");
    setSelectMulti(false);
  };

  if (disabled || isEmpty(vID)) return null;
  if (isFetchingNewGroup) return <LoadingFooter />;
  return (
    <Box>
      {!isBlock ? (
        <Box ref={containerRef}>
          <EditMessage
            openReplyMessage={openReplyMessage}
            openEditMessage={openEditMessage}
            handleCloseEditMessage={handleCloseEditMessage}
          />
          <ReplyMessage
            reply={replyObject}
            openEditMessage={openEditMessage}
            openReplyMessage={openReplyMessage}
            handleCloseReplyMessage={handleCloseReplyMessage}
          />
          <Box ref={boxRef} sx={(e) => styles.container(e, { openEditMessage, openReplyMessage })}>
            <Box sx={(e) => styles.flexBoxRight(e, isVoice)}>
              <Box sx={styles.listIcon}>
                <Box sx={styles.positionCenter}>
                  {/* ---DISABLE--- */}
                  <IconButton sx={styles.pIcon} onClick={handleOpen} disabled>
                    {active === 1 ? (
                      <InsertEmoticonActiveIcon sx={styles.fillColorIconLeft} />
                    ) : (
                      <InsertEmoticonIcon sx={styles.fillColorIconLeft} />
                    )}
                  </IconButton>
                  {/* ---DISABLE--- */}
                  <IconButton sx={styles.pIcon} onClick={handleOpenVote} disabled>
                    {active === 2 ? (
                      <BarChartActiveIcon sx={styles.fillColorIconLeft} />
                    ) : (
                      <BarChartIcon sx={styles.fillColorIconLeft} />
                    )}
                  </IconButton>
                  <IconButton
                    sx={styles.pIcon}
                    onClick={initialize}
                    component="label"
                    onChange={handleUploadImage}
                  >
                    <input hidden id="file_upload" type="file" multiple />
                    {active === 3 ? (
                      <AttachFileActiveIcon sx={styles.fillColorIconLeft} />
                    ) : (
                      <AttachFileIcon sx={styles.fillColorIconLeft} />
                    )}
                  </IconButton>
                </Box>
              </Box>
              <Box sx={(e) => styles.flexGrowResponsive(e, images)}>
                <TextField
                  onPaste={onPaste}
                  autoFocus
                  multiline
                  inputRef={textRef}
                  maxRows={5}
                  placeholder={t("chat.footer.text_input")}
                  onChange={handleMessage}
                  onKeyDown={handleKeypress}
                  InputProps={{ autoComplete: "off" }}
                  sx={styles.inputMessage}
                />
                <Box sx={styles.pasteInputBox}>
                  {images.map((item, i) => (
                    <Box key={i}>
                      <Box
                        key={i}
                        sx={styles.pasteInputImage}
                        component="img"
                        src={item.preview}
                        alt="i"
                      />
                      <IconButton
                        onClick={(e) => handleDeleteImage(e, i)}
                        sx={styles.pasteInputClose}
                      >
                        <CloseIcon sx={styles.iconW18} />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box sx={styles.boxRight}>
              <Box sx={styles.flexEnd}>
                {isVoice && (
                  <Box sx={styles.flexEnd}>
                    <IconButton>
                      <CircleVoiceIcon sx={styles.fillColorWhite} />
                    </IconButton>
                    <Box sx={styles.time}>{time}</Box>
                    <IconButton onClick={handleCloseVoice} sx={styles.closeVoiceIcon}>
                      <CloseVoiceIcon />
                    </IconButton>
                  </Box>
                )}
                {!openEditMessage &&
                  textRef?.current?.value === "" &&
                  !isVoice &&
                  images.length === 0 && (
                    <IconButton sx={styles.fillColorIconRight} onClick={handleVoice}>
                      <KeyboardVoiceIcon sx={styles.fillColorWhite} />
                    </IconButton>
                  )}
                {!openEditMessage &&
                  (textRef?.current?.value !== "" || isVoice || images.length > 0) && (
                    <IconButton ref={sendRef} sx={styles.fillColorIconRight} onClick={handleSend}>
                      <SendIcon sx={styles.fillColorWhite} />
                    </IconButton>
                  )}
                {openEditMessage && (
                  <IconButton
                    disabled={isChangeText}
                    onClick={handleCloseEditMessage}
                    sx={() => styles.fillColorIconRight({ isChangeText })}
                  >
                    <CheckIcon sx={styles.fillColorWhite} />
                  </IconButton>
                )}
              </Box>
            </Box>
            <EmojiPopper
              openSetting={openSetting}
              anchor={containerRef.current}
              handleClose={handleClose}
            />
          </Box>
          <PollPopper
            openVote={openVote}
            textQuestion={textQuestion}
            inputQuestionRef={inputQuestionRef}
            boxRef={boxRef}
            listVote={listVote}
            elRefs={elRefs}
            isMaxOption={isMaxOption}
            isActive={isActive}
            setSelectMulti={setSelectMulti}
            handleCloseVote={handleCloseVote}
            handleTextQuestion={handleTextQuestion}
            setListVote={setListVote}
            handleChange={handleChange}
            handleAddVote={handleAddVote}
            handleCreatePoll={handleCreatePoll}
          />
        </Box>
      ) : (
        <Box sx={styles.blockBox}>
          <Typography variant="caption" sx={styles.blockTitle}>
            {t("chat.footer.notification_un_block")}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
